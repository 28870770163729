import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import { Link, useLocation } from "react-router-dom";
import PrhBrand from "./PrhBrand";
import UseWindowSize from "../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../constants/constants";
import { withRouter } from "react-router-dom";
import BreadCrumb from "./BreadCrumb";
import CommonFi from "../../i18n/Common/fi";
import CommonEn from "../../i18n/Common/en";
import CommonSv from "../../i18n/Common/sv";

const ExpandedNavbar = (props) => {
  const { dispatch, langCode } = useContext(I18nContext);
  const contentLangs = { fi: CommonFi, en: CommonEn, sv: CommonSv };
  const content = contentLangs[langCode];
  const location = useLocation();

  const size = UseWindowSize();
  const onLanguageSelect = (a) => {
    const e = a.target.lang;
    document.documentElement.lang = e;
    dispatch({ type: "setLanguage", payload: e });
    const pathAsArray = props.location.pathname.split("/");
    const langIndex = pathAsArray.indexOf(props.match.params.lang);
    if (langIndex !== -1) {
      pathAsArray[langIndex] = e;
    }
    const newPath = pathAsArray.join("/");
    props.history.push({
      pathname: newPath,
    });
  };

  let textSize = "1.0rem";
  if (size.width < bootStrapGrid.md) {
    textSize = "1.4rem";
  }

  return (
    <>
      <header className="prh-site-header" id="navbar">
        <div className="container prh-site-header__container">
          <div className="navbar navbar-dark navbar-expand-md">
            {<PrhBrand />}
            <div className="navbar-collapse collapse" id="secondary-navigation">
              <ul className="navbar-nav prh-site-header__main-navigation">
                {langCode !== "fi" && (
                  <li className="nav-item">
                    <button
                      lang="fi"
                      data-value="Suomeksi"
                      target="_self"
                      id="option-0"
                      className="nav-link"
                      data-cy="fi"
                      style={{ fontSize: textSize, cursor: "pointer" }}
                      onClick={onLanguageSelect}
                      eventkey="fi"
                    >
                      Suomeksi
                    </button>
                  </li>
                )}
                {langCode !== "sv" && (
                  <li className="nav-item">
                    <button
                      lang="sv"
                      data-value="På svenska"
                      target="_self"
                      id="option-1"
                      className="nav-link"
                      data-cy="sv"
                      style={{ fontSize: textSize, cursor: "pointer" }}
                      onClick={onLanguageSelect}
                      eventkey="sv"
                    >
                      På svenska
                    </button>
                  </li>
                )}
                {langCode !== "en" && (
                  <li className="nav-item">
                    <button
                      lang="en"
                      data-value="In English"
                      target="_self"
                      id="option-2"
                      className="nav-link"
                      data-cy="en"
                      style={{ fontSize: textSize, cursor: "pointer" }}
                      onClick={onLanguageSelect}
                      eventkey="en"
                    >
                      In English
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="container prh-site-header__container prh-site-header__separator">
          <div className="container prh-site-header__container prh-site-header__separator">
            <nav
              className="navbar navbar-dark navbar-expand-md"
              aria-label="Navigaatio"
            >
              <div className="navbar-collapse collapse" id="primary-navigation">
                <ul className="navbar-nav navbar-collapse__primary_menu">
                  <li
                    className={[
                      "nav-item",
                      location.pathname.length < 5 ? "active" : undefined,
                    ].join(" ")}
                  >
                    <Link
                      to="/"
                      className={[
                        "nav-link",
                        content.navigation.navbar
                          .map((e) => e.link)
                          .some((i) => location.pathname.indexOf(i) > -1)
                          ? ""
                          : "active",
                      ].join(" ")}
                      href="#"
                      target="_self"
                      aria-current={
                        location.pathname.length < 5 ? "page" : undefined
                      }
                    >
                      {content.navigation.home}
                    </Link>
                  </li>
                  {content.navigation.navbar
                    .filter((e) => !e.hidden)
                    .map((e) => (
                      <li key={e.name} className={["nav-item"].join(" ")}>
                        <Link
                          to={e.link}
                          className={[
                            "nav-link",
                            location.pathname.indexOf(e.link) > -1
                              ? "active"
                              : "",
                          ].join(" ")}
                          href="#"
                          target="_self"
                          aria-current={
                            location.pathname.length < 5
                              ? "page"
                              : location.pathname.indexOf(e.link) > -1
                              ? "current"
                              : undefined
                          }
                        >
                          {e.name}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <div className="prh-sub-header">
        <div className="container prh-sub-header__container">
          {props.breadCrumbs ? (
            <BreadCrumb breadCrumbs={props.breadCrumbs} />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );

};

export default withRouter(ExpandedNavbar);
