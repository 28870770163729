const PageFi = {
  header: "Mönsterrättstidning",
  description: "",
  title: "Mönsterrättstidning - Tidningar för industriellt rättsskydd | PRS",
  common: {
    week: "Vecka",
  },
  message_box: {
    info_title: "Inga resultat hittades med dina sökvillkor.",
    danger_title: "Sökningen misslyckades. Ange sökvillkor.",
  },
  search: {
    date_warning: "Kontrollera datum",
    lines: [
      `Tidningen innehåller ett sammandrag av följande kungörelser och delgivningar:
      <ul>
<li>nationell mönsterrätt</li>
<li>internationella mönsterrättsregistreringar som gäller i Finland.</li>
</ul>`,
      "Vi uppdaterar uppgifterna varje dag.",
      "Du kan söka publikationer för en hel vecka genom att välja utgivningsår och tidningsnummer. Du kan också välja perioden fritt.",
    ],
    gazette_number: {
      label: "Välj utgivningsår och tidningsnummer",
      year: "Välj år",
      number: "Välj nummer",
    },
    date_range: {
      label: "Eller sök uppgifter efter datum",
      begin: "Från och med",
      end: "Till och med",
      search: "Sök",
      description: "Skriv datumet enligt formatet dd.mm.åååå",
    },
    archive: "Arkiv (kungörelser som utfärdats före 16 augusti 2022)",
    defaultOption: "-Välj-",
    button_search: "Sök",
    button_clear: "Rensa",
  },
  // archive_section: {
  //   title: "Arkiv",
  //   description:
  //     "I arkivet hittar du kungörelser som utfärdats före 16 augusti 2022.",
  //   link_label: "Gå till tidningsnummer 02/2016 – 08/2022",
  // },
  index: {
    rangeTitle: "Kungörelser ",
    issueTitle: "Kungörelser ",
    types: {
      julk: "Kungjorda mönsterregistreringar",
      oik: "Rättelser",
      tied: "Delgivningar",
      rekmm: "Ändringar av registrerade mönster",
      rekuu: "Förnyelser av registreringar",
      uudjatpe: "På grund av underlåten förnyelse",
      halpyy: "På innehavarens begäran",
      suoalsu:
        "Begränsningar av skyddsomfång som gäller mönsterrättsregistreringar",
      siirto: "Överlåtelser av mönsterrätt",
      katlup: "Licenser",
      kvrekeisu:
        "Internationella registreringar som inte längre gäller i Finland",
      uudkvrek: "Förnyade internationella registreringar",
      poisrek: "Avförda registreringar",
    },
    st: "st.",
    link_alt: "Hoppa till ett avsnitt på den här sidan",
  },
  details: {
    common: {
      yes: "Ja",
      no: "Nej",
      image: {
        alt: "Öppna en större bild",
      },
      number: "nr: ",
      jump_links: {
        index_title: "Gå tillbaka till kungörelser",
        page_title: "Gå tillbaka till början av sidan",
      },
      alt_type: {
        julk: "Kungjord registrering",
        oik: "Rättelse",
        tied: "Delgivning",
        rekmm: "Ändring av registrerat mönster",
        rekuu: "Förnyad registrering",
        suoalsu: "Begränsning av skyddsomfång av mönsterregistrering",
        siirto: "Överlåtelse av mönsterrätt",
        katlup: "Licens",
        kvrekeisu:
          "Internationell registrering som inte längre gäller i Finland",
        uudkvrek: "Förnyad internationell registrering",
        poisrek: "Avförd registrering",
      },
      bw: "Svartvit",
      colour: "I färg",
      enlarged: "Detaljförstorning",
      close: "Stäng",
      imageModal: {
        label: "Förhandsgranskning av större bild av mönstret",
      },
    },
    types: {
      julk: {
        title: "Kungjorda mönsterregistreringar",
        check: "Se",
        designDB: "Informationstjänsten för mönsterrätt",
        lines: [
          "Patent- och registerstyrelsen har enligt mönsterrättslagen registrerat följande mönster och kungör följande internationella mönsterregistreringar.",

          "Från kungörelsedatumet börjar en invändningstid på två månader. Läs mer på<a href='https://www.prh.fi/monster_invandning' class='external' target='_blank'> prh.fi</a> om att lämna in en invändning.",
        ],
        inid: {
          registrationNumber: "(11) Registernummer",
          registrationDate: "(15) Registreringsdatum",
          expiryDate: "(18) Gäller efter förnyelsen",
          applicationNumber: "(21) Ansökningsnummer",
          applicationDate: "(22) Ansökningsdatum",
          sampleProvided: "(29) Modell ingiven",
          priorities: "(30) Prioritet",
          publicationDate: "(45) Kungörelsedatum",
          publicationDateWithoutInid: "Kungörelsedatum",
          classification: "(51) Klassificering",
          productTitle: "(54) Produktbenämning",
          images: "(57)  Bilderna ",
          designers: "(72) Mönsterskapare ",
          applicants: "(73) Innehavare",
          newHolder: "(73) Ny innehavare",
          representatives: "(74) Ombud",
          relatedApplication: "(66) Variantregistrering",
          licence: "(79) Licenstagare",
          oppositionPeriodStart: "Invändningstiden börjar",
          oppositionPeriodEnd: "Invändningstid går ut",
          transferDate: "Mönsterrätten övergått",
          designReferenceTitle: "Se",
          designReferenceLink:
            "Se mer information i informationstjänsten för mönsterrätt",
          designReferenceLinkWipo:
            "Se mer information i WIPOs informationstjänst",
        },
      },
      oik: {
        title: "Rättelser",
        lines: [""],
      },
      tied: {
        title: "Delgivningar",
        lines: [""],
      },
      rekmm: {
        title: "Ändringar i registrerade mönster",
        lines: [""],
      },
      rekuu: {
        title: "Förnyelser av registreringar ",
        lines: [""],
      },
      uudjatpe: {
        title: "På grund av underlåten förnyelse",
        lines: [""],
      },
      halpyy: {
        title: "På innehavarens begäran",
        lines: [""],
      },
      suoalsu: {
        title:
          "Begränsningar av skyddsomfång som gäller mönsterrättsregistreringar",
        lines: [""],
      },
      siirto: {
        title: "Överlåtelser av mönsterrätt",
        lines: [""],
      },
      katlup: {
        title: "Licenser",
        lines: [""],
      },
      kvrekeisu: {
        title:
          "Internationella registreringar som inte längre gäller i Finland",
        lines: [""],
      },
      uudkvrek: {
        title: "Förnyade internationella registreringar",
        lines: [""],
      },
      poisrek: {
        title: "Avförda registreringar",
        lines: [""],
      },
    },
  },
};
export default PageFi;
