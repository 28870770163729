const Page = {
  header: "Teollisoikeuslehdet",
  title: "Teollisoikeuslehdet | PRH",
  description:
    "Tiedot PRH:n käsittelemien teollisoikeuksien kuulutuksista ja julkaisuista. Tietoja päivitetään joka päivä.",
  content: {
    title: null,
    lines: [],
    sections: [
      {
        hidden: false,
        border: true,
        title: {
          url: "/patentgazette",
          text: "Patenttilehti",
        },
        lines: [
          `Näet koosteen seuraavista kuulutuksista ja tiedoksiannoista:
          <ul>
          <li>kansalliset patentit</li>
          <li>Suomessa voimaan saatetut eurooppapatentit.</li>
          <li>lisäsuojatodistukset (SPC).</li>

          </ul>`,
        ],
      },
      {
        hidden: false,
        border: true,
        title: {
          url: "/utilitymodelgazette",
          text: "Hyödyllisyysmallilehti",
        },
        lines: [
          `Näet koosteen hyödyllisyysmalleihin liittyvistä kuulutuksista ja tiedoksiannoista.`,
        ],
      },
      {
        hidden: false,
        border: true,
        title: {
          url: "/trademarkgazette",
          text: "Tavaramerkkilehti",
        },
        archive: {
          description: "Arkistosta löydät ennen 16.8.2022 tehdyt julkaisut. ",
          link_label: "Siirry arkistoon",
        },
        lines: [
          `Näet koosteen seuraavista julkaisuista ja tiedoksiannoista:
<ul>
<li>kansalliset tavaramerkit</li>
<li>Suomessa voimassa olevat kansainväliset tavaramerkkirekisteröinnit</li>
<li>laajalti tunnetut tavaramerkit.</li>
</ul>`,
        ],
      },
      {
        hidden: false,
        border: true,
        title: {
          url: "/designgazette",
          text: "Mallioikeuslehti",
        },
        archive: {
          description: "Arkistosta löydät ennen 16.8.2022 annetut kuulutukset. ",
          link_label: "Siirry arkistoon",
        },
        lines: [
          `Näet koosteen seuraavista kuulutuksista ja tiedoksiannoista:

      <ul>
<li>kansalliset mallioikeudet</li>
<li>Suomessa voimassa olevat kansainväliset mallioikeusrekisteröinnit.</li>
</ul>`,
        ],
      },
    ],
  },
};

export default Page;
