const Page = {
  header: "Tavaramerkkilehti",
  description: "",
  title: "Tavaramerkkilehti - Teollisoikeuslehdet | PRH",
  common: {
    week: "Viikko",
  },
  message_box: {
    info_title: "Käyttämilläsi hakuehdoilla ei löytynyt tuloksia.",
    danger_title: "Haku ei onnistunut. Anna hakuehdot.",
  },
  search: {
    date_warning: "Tarkista päivämäärä",
    lines: [
      `Näet koosteen seuraavista julkaisuista ja tiedoksiannoista:
<ul>
<li>kansalliset tavaramerkit</li>
<li>Suomessa voimassa olevat kansainväliset tavaramerkkirekisteröinnit</li>
<li>laajalti tunnetut tavaramerkit.</li>
</ul>`,
      "Tietoja päivitetään joka päivä.",
      "Voit hakea kokonaisen viikon julkaisuja valitsemalla ilmestymisvuoden ja lehden numeron. Voit myös valita aikavälin vapaasti.",
    ],
    gazette_number: {
      label: "Valitse ilmestymisvuosi ja lehden numero",
      year: "Valitse vuosi",
      number: "Valitse numero",
    },
    date_range: {
      label: "Tai hae tietoja päivämäärän perusteella",
      begin: "Alkaen",
      end: "Saakka",
      search: "Hae",
      description: "Päivämäärä muodossa pp.kk.vvvv",
    },
    archive: "Arkisto (ennen 16.8.2022 tehdyt julkaisut)",
    defaultOption: "-Valitse-",
    button_search: "Hae",
    button_clear: "Tyhjennä",
  },
  // archive_section: {
  //   title: "Arkisto",
  //   description: "Arkistosta löydät ennen 16.8.2022 tehdyt julkaisut.",
  //   link_label: "Siirry lehden numeroihin 22/2014 – 15/2022",
  // },
  index: {
    rangeTitle: "Julkaisut ",
    issueTitle: "Julkaisut ",
    types: {
      julk: "Julkaistut rekisteröinnit",
      oik: "Oikaisut",
      tied: "Tiedoksiannot",
      ltm: "Laajalti tunnettujen tavaramerkkien luetteloon hyväksytyt tavaramerkit",
    },
    st: "kpl",
    link_alt: "Hyppää osioon tällä sivulla",
  },
  details: {
    common: {
      yes: "Kyllä",
      no: "Ei",
      image: {
        alt: "Avaa kuva suuremmaksi",
      },
      number: "nro: ",
      jump_links: {
        index_title: "Palaa julkaisuihin",
        page_title: "Palaa sivun alkuun",
      },
      alt_type: {
        registration: "Julkaistu rekisteröinti",
        correction: "Oikaisu",
        notification: "Tiedoksianto",
        wellknowntrademark: "Laajalti tunnettu tavaramerkki",
      },
      inid: {
        registrationNumber: "(111) Rekisterinumero",
        registrationDate: "(151) Rekisteröintipäivä",
        applicationNumber: "(210) Hakemusnumero",
        applicationDate: "(220) Hakemispäivä",
        priorities: {
          applicationNumber: "(310) Etuoikeus: hakemusnumero",
          filingDate: "(320) Etuoikeus: hakemispäivä",
          countryCode: "(330) Etuoikeus: maa",
          partial: "(340) Osaetuoikeus",
        },
        markImage: "(540) Tavaramerkki",
        markSound: "(556) Äänimerkki",
        markMultimedia: "(554) Kolmiulotteinen merkki",
        markTypeColour: "(558) Värimerkki",
        description: "(571) Merkinselitys",
        disclaimers: "(526) Erottamislausuma",
        colourDescriptions: "(591) Väriselitys",
        applicants: "(730) Haltija",
        representatives: "(740) Asiamies",
        classifications: "(511) Tavara- ja/tai palveluluokka",
        publicationDate: "(450) Julkaisupäivä",
        publicationDateWithoutInid: "Julkaisupäivä",
        oppositionPeriodStartDate: "Väiteaika alkaa",
        oppositionPeriodEndDate: "Väiteaika päättyy",
        markKind: "(551) Yhteisö- tai tarkastusmerkki",
        markKindMark: {
          Collective: "Yhteisömerkki",
          Guarantee: "Tarkastusmerkki",
        },
      },
      close: "Sulje",
      imageModal: {
        label: "Suuremman tavaramerkkikuvan esikatselu",
      },
      videoModal: {
        label: "Näytä tavaramerkin video",
      },
      playSound: {
        label: "Soita tavaramerkin ääni",
      },
      trademarkImage: "Tavaramerkin kuva",
    },
    types: {
      julk: {
        check: "",
        trademarkDB: "Katso lisätietoja tavaramerkkitietopalvelusta",
        title: "Julkaistut rekisteröinnit",
        lines: [
          "Tavaramerkkilain mukaisesti Patentti- ja rekisterihallitus on rekisteröinyt seuraavat tavaramerkit ja julkaisee seuraavat kansainväliset rekisteröinnit.",

          "Väite rekisteröintiä vastaan on tehtävä PRH:lle kirjallisesti kahden kuukauden kuluessa rekisteröinnin julkaisupäivästä. Lue ohje väitteen tekemiseen <a href='https://www.prh.fi/tavaramerkki_vaite' class='external' target='_blank'>prh.fi-sivuilta</a>",
        ],
      },
      oik: {
        title: "Oikaisut",
        lines: [""],
      },
      tied: {
        title: "Tiedoksiannot",
        lines: [""],
      },
      ltm: {
        title:
          "Laajalti tunnettujen tavaramerkkien luetteloon hyväksytyt tavaramerkit",
        lines: [
          "Patentti- ja rekisterihallitus julkaisee seuraavat laajalti tunnettujen tavaramerkkien luetteloon hyväksytyt tavaramerkit.",

          "Luetteloon hyväksyttyä tavaramerkkiä vastaan ei voi tehdä väitettä. Kuka tahansa voi vaatia tavaramerkkiä poistettavaksi luettelosta. Lue lisää luettelosta poistamisesta <a href='https://www.prh.fi/laajalti_tunnetut_tavaramerkit' class='external' class='external' target='_blank'>prh.fi-sivuilta</a>",
        ],
        tableHeaders: {
          class: "Luokka",
          tableHead: "Tavaramerkkien tiedot",
          applicationNumber: "Merkin numero",
          registrationDate: "Hyväksymispäivä",
          expirationDate: "Voimassaolo päättyy",
          applicationDate: "Hakemispäivä",
          trademark: "Tavaramerkki",
          markDescription: "Merkinselitys",
          colourDescriptions: "Väriselitys",
          applicant: "Hakija",
          representative: "Asiamies",
          targetGroup: "Kohderyhmä",
          targetGroupNo: "Ei rajattu",
          goodsAndServices: "Tavarat/Palvelut",
          publicationDate: "Julkaisupäivä",
        },
      },
    },
  },
};

export default Page;
