const Page = {
  jumplink: "Snabblänk till huvudinnehåll",
  title: "Tidningar för industriellt rättsskydd",
  subtitle: "Patent- och registerstyrelsen",
  navigation: {
    home: "Ingångssida",
    navbar: [
      { name: "Patenttidning", link: "/patentgazette", hidden: false },
      {
        name: "Nyttighetsmodelltidning",
        link: "/utilitymodelgazette",
        hidden: false,
      },
      { name: "Varumärkestidning", link: "/trademarkgazette", hidden: false },
      { name: "Mönsterrättstidning", link: "/designgazette", hidden: false },
    ],
    mainpage: "Ingångssida",
    patentgazette: "Patenttidning",
    umgazette: "Nyttighetsmodelltidning",
    trademarkgazette: "Varumärkestidning",
    designgazette: "Mönsterrättstidning",
    archive: "Arkiv",
    hide: "Dölj navigation",
    show: "Navigation",
  },
  footer: {
    title: "Patent- och registerstyrelsen",
    address: {
      streetTitle: "Besöksadress",
      streetaddress: "Sörnäs strandväg 13 C, Helsingfors",
      postaddressTitle: "Postadress",
      postaddress: "00091 PRH",
    },
    customerService: {
      title: "Kundrådgivning",
      openHours: "mån-fre kl. 9.00 - 15.00",
      telephone: "tfn 029 509 5050",
    },

    trademark: {
      issn: "ISSN 1795-4932 (Nättidning)",
      author: "Chefredaktör: Jorma Lehtonen",
      privacyPolicyLink:
        "https://www.prh.fi/sv/presentation_och_uppgifter/behandling_av_personuppgifter/hur_behandlar_prs_personuppgifter/dataskyddsbeskrivning_for_varumarkesregistret.html",
      privacyPolicyLink2:
        "https://www.prh.fi/sv/presentation_och_uppgifter/behandling_av_personuppgifter/hur_behandlar_prs_personuppgifter/dataskyddsbeskrivning_for_forteckningen_over_val_kanda_varumarken.html",
      privacyPolicyAlt2: "Visa dataskyddsbeskrivning (Väl kända vm)",
      privacyPolicyTitle2: "Dataskyddsbeskrivning (Väl kända vm)",
    },
    um: {
      issn: "ISSN 1796-7775 (Nättidning)",
      author: "Chefredaktör: Jorma Lehtonen",
      privacyPolicyLink:
        "https://www.prh.fi/sv/presentation_och_uppgifter/behandling_av_personuppgifter/hur_behandlar_prs_personuppgifter/dataskyddsbeskrivning_for_nyttighetsmodellregistret.html",
    },
    patent: {
      issn: "ISSN 1796-7775 (Nättidning)",
      author: "Chefredaktör: Jorma Lehtonen",
      privacyPolicyLink:
        "https://www.prh.fi/sv/presentation_och_uppgifter/behandling_av_personuppgifter/hur_behandlar_prs_personuppgifter/dataskyddsbeskrivning_for_patentregistret.html",
    },
    design: {
      issn: "ISSN 1795-4940 (Nättidning)",
      author: "Chefredaktör: Jorma Lehtonen",
      privacyPolicyLink:
        "https://www.prh.fi/sv/presentation_och_uppgifter/behandling_av_personuppgifter/hur_behandlar_prs_personuppgifter/dataskyddsbeskrivning_for_monsterregistr.html",
    },
    infoTitle: "Information",
    privacyPolicyTitle: "Dataskyddsbeskrivning",
    privacyPolicyLink: "#",
    privacyPolicyAlt: "Visa dataskyddsbeskrivning",
    privacyPolicy: [
      {
        gazette: "patent",
        title: "Dataskyddsbeskrivning (Patent)",
        link: "https://www.prh.fi/sv/presentation_och_uppgifter/behandling_av_personuppgifter/hur_behandlar_prs_personuppgifter/dataskyddsbeskrivning_for_patentregistret.html",
        show: true,
      },
      {
        gazette: "um",
        title: "Dataskyddsbeskrivning (Nyttighetsmodeller)",
        link: "https://www.prh.fi/sv/presentation_och_uppgifter/behandling_av_personuppgifter/hur_behandlar_prs_personuppgifter/dataskyddsbeskrivning_for_nyttighetsmodellregistret.html",
        show: true,
      },
      {
        gazette: "trademark",
        title: "Dataskyddsbeskrivning (Varumärke)",
        link: "https://www.prh.fi/sv/presentation_och_uppgifter/behandling_av_personuppgifter/hur_behandlar_prs_personuppgifter/dataskyddsbeskrivning_for_varumarkesregistret.html",
        show: true,
      },
      {
        gazette: "ltm",
        title: "Dataskyddsbeskrivning (Väl kända vm)",
        link: "https://www.prh.fi/sv/presentation_och_uppgifter/behandling_av_personuppgifter/hur_behandlar_prs_personuppgifter/dataskyddsbeskrivning_for_forteckningen_over_val_kanda_varumarken.html",
        show: true,
      },
      {
        gazette: "design",
        title: "Dataskyddsbeskrivning (Mönsterrätt)",
        link: "https://www.prh.fi/sv/presentation_och_uppgifter/behandling_av_personuppgifter/hur_behandlar_prs_personuppgifter/dataskyddsbeskrivning_for_monsterregistr.html",
        show: true,
      },
    ],
    accessibilityLeafletTitle: "Tillgänglighetsutlåtande",
    accessibilityLeafletLink:
      "https://www.prh.fi/tillganglighetsutlatande/tidningar_for_industriellt_rattsskydd",
    accessibilityLeafletAlt: "Visa tillgänglighetsutlåtande",
  },
  datepicker: {
    placeholder: "dd.mm.åååå",
  },
  pagination: {
    title: "Sidindelning",
    toPage: "Gå till sida ",
    toFirstPage: "Gå till första sidan",
    toPreviousPage: "Gå till föregående sida",
    toNextPage: "Gå till nästa sida",
    toLastPage: "Gå till sista sidan",
    pages: "Sidor",
  },
  tooltip: "Anvisningstext",
  breadcrumb: "Länkstig",
  close: "Stäng",
  survey: {
    yearly_survey_question: "Svara på vår korta enkät och hjälp oss att förbättra våra tjänster.",
    yearly_survey_info: "Det tar ett par minuter att svara.",
    yearly_survey_open: "Gå till enkäten",
    yearly_survey_link: "https://link.webropolsurveys.com/S/2F29B674CB2003ED",
    yearly_survey_skip: "Stäng"
  }
};

export default Page;
