import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  // useContext,
} from "react";
// import { I18nContext } from "../i18n";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import Jumbotron from "../components/common/Jumbotron";
import "../scss/styles/app.scss";
import Layout from "../components/common/Layout";
import usePatentEndpoint from "../customHooks/usePatentEndpoint";
import ArrayOfYears from "../utils/ArrayOfYears";
import TOCSection from "../components/sections/Index";
import Details from "../components/sections/Details";
import moment from "moment";
// import SurveyModal from "../components/common/SurveyModal";

import SearchSection from "../components/sections/Search";
import ArchiveSection from "../components/sections/ArchiveSection";

const GazettePage = (props) => {
  const contentRef = useRef(null);

  // const { langCode } = useContext(I18nContext);
  const breadCrumbs = props.breadCrumbs;
  const type = props.type;
  const { search, postSearch } = usePatentEndpoint();
  // eslint-disable-next-line no-unused-vars
  const [yearOptions, setYearOptions] = useState(ArrayOfYears("2022"));
  const content = props.content;
  document.title = content.title;
  const [issueOptions, setIssueOptions] = useState(
    [...Array(52).keys()]
      .map((e) => {
        return {
          value: e + 1,
          title: content.common.week + " " + (e + 1).toString(),
        };
      })
      .reverse()
  );

  useEffect(() => {
    document.title = `${content.header} | PRH`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [year, setYear] = useState(null);
  const [issue, setIssue] = useState(null);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startValid, setStartValid] = useState(true);
  const [endValid, setEndValid] = useState(true);

  const [rangeSearch, setRangeSearch] = useState(false);
  const [warning, setWarning] = useState(false);
  const [searching, setSearching] = useState(false);
  useEffect(() => {
    if (year === moment().year().toString()) {
      setIssueOptions(
        [...Array(parseInt(moment().format("W"))).keys()]
          .map((e) => {
            return {
              value: e + 1,
              title: content.common.week + " " + (e + 1).toString(),
            };
          })
          .reverse()
      );
      if (issue > parseInt(moment().format("W"))) {
        setIssue(moment().format("W"));
      }
    } else {
      setIssueOptions(
        [...Array(52).keys()]
          .map((e) => {
            return {
              value: e + 1,
              title: content.common.week + " " + (e + 1).toString(),
            };
          })
          .reverse()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, issue, props.content]);

  const searchButton = useCallback(() => {
    if (startDate && endDate) {
      setRangeSearch(true);
      postSearch({ startDate, endDate, type: props.type });
      setSearching(true);
      setWarning(false);
    } else if (year && issue) {
      setWarning(false);
      setRangeSearch(false);
      postSearch({ year, issue, type: props.type });
      setSearching(true);
    } else {
      setWarning(true);
      setSearching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, year, issue]);

  const [gazettes, setGazettes] = useState([]);
  useEffect(() => {
    if (search?.data?.gazetteGroups?.length > 0) {
      setGazettes(search.data.gazetteGroups);
    } else {
      setGazettes([]);
    }
  }, [search, contentRef]);

  const handleReset = () => {
    resetYearAndIssue();
    resetDates();
    setWarning(false);
  };

  const resetYearAndIssue = () => {
    setYear(null);
    setIssue(null);
    setGazettes([]);
    setSearching(false);
  };

  const resetDates = () => {
    setStartDate("");
    setEndDate("");
    setGazettes([]);
    setSearching(false);
  };

  return (
    <>
      <Layout
        breadCrumbs={breadCrumbs}
        type={type}
        closeSurvey={props.closeSurvey}
        showSurvey={props.showSurvey}
      >
        {/* <SurveyModal topics={["surveys"]} language={langCode} sleep={30000} /> */}
        <main id="maincontent" tabIndex="-1">
          <Jumbotron content={content} />
          <Container>
            <SearchSection
              content={content}
              yearOptions={yearOptions}
              issueOptions={issueOptions}
              onYearChange={(e) => {
                if (e !== "0") resetDates();
                if (e === "0") setYear(null);
                else setYear(e);
                setWarning(false);
              }}
              onIssueChange={(e) => {
                if (e !== "0") resetDates();
                if (e === "0") setIssue(null);
                else setIssue(e);
                setWarning(false);
              }}
              onStartDateChange={(e, a) => {
                if (a != null) resetYearAndIssue();
                setStartDate(
                  new Date(a.currentTarget.value).toISOString().split("T")[0]
                );
                setStartValid(true);
              }}
              onEndDateChange={(e, a) => {
                if (a != null) resetYearAndIssue();
                setEndDate(
                  new Date(a.currentTarget.value).toISOString().split("T")[0]
                );
                setEndValid(true);
              }}
              startValid={startValid}
              endValid={endValid}
              startDate={startDate}
              endDate={endDate}
              onSubmit={searchButton}
              onReset={handleReset}
              issue={issue}
              year={year}
              warning={warning}
            />
            <ArchiveSection content={content} />
            <div>
              {searching &&
                gazettes &&
                ((year && issue) || (startDate && endDate)) && (
                  <TOCSection
                    id="tosection"
                    rangeSearch={rangeSearch}
                    content={content}
                    searchResults={gazettes}
                    startDate={startDate}
                    gazettes={gazettes}
                    endDate={endDate}
                    issue={issue}
                    year={year}
                    loading={search.loading}
                  />
                )}
              {gazettes.length > 0 &&
                gazettes.map((e, i) => (
                  <Details
                    key={i}
                    id={e.type}
                    content={content}
                    gazettes={e.gazettes}
                    type={e.gazetteType.toLowerCase()}
                  />
                ))}
            </div>
          </Container>
        </main>
      </Layout>
    </>
  );
};

export default withRouter(GazettePage);
