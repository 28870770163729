import React from "react";
import { formatDate } from "../../../../utils/helpers";

const DeclarationsPT = (props) => {
  const gazettes = props.gazettes;

  const inid = props.details.table.rows;
  return (
    <>
      <hr style={{ color: "grey" }} />
      <div className="d-flex flex-column">
        {gazettes && gazettes.referenceText && (
          <p style={{ whiteSpace: "pre-wrap" }}>{gazettes.referenceText}</p>
        )}

        {gazettes?.publicationDate && (
          <p>
            <strong>{inid.publicationDate} </strong>
            {formatDate(gazettes.publicationDate)}
          </p>
        )}
      </div>
    </>
  );
};
export default DeclarationsPT;
