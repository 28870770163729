import React, { useEffect, useState } from "react";
import "jquery";
import Form from "react-bootstrap/Form";

const Dropdown = (props) => {
  const selections = props.selections;
  const [current, setCurrent] = useState("0");

  useEffect(() => {
    if (props.onChange && current !== "0") {
      props.onChange(current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  useEffect(() => {
    if (props.value === null) setCurrent("0");
    else setCurrent(props.value);
  }, [props.value]);

  return (
    <Form.Group controlId={`SelectCustom_${props.title.replace(" ", "_")}`}>
      <Form.Label className="text-nowrap">{props.title}</Form.Label>
      <Form.Control
        as="select"
        className="select"
        value={current}
        aria-live="polite"
        aria-relevant="all"
        aria-controls={props.controls}
        onChange={(e) => setCurrent(e.target.value)}
      >
        <option value={"0"}>{props.defaultOption}</option>
        {selections.map((e,i) => (
          <option key={i} value={e.value ? e.value : e}>{e.title ? e.title : e}</option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

export default Dropdown;
