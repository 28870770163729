const Page = {
  header: "Hyödyllisyysmallilehti",
  description: "",
  title: "Hyödyllisyysmallilehti - Teollisoikeuslehdet | PRH",
  common: {
    week: "Viikko",
  },
  message_box: {
    info_title: "Käyttämilläsi hakuehdoilla ei löytynyt tuloksia.",
    danger_title: "Haku ei onnistunut. Anna hakuehdot.",
  },
  search: {
    date_warning: "Tarkista päivämäärä",
    lines: [
      "Näet koosteen hyödyllisyysmallin kuulutuksista ja tiedoksiannoista.",
      "Tietoja päivitetään joka päivä.",
      "Voit hakea kokonaisen viikon julkaisuja valitsemalla ilmestymisvuoden ja lehden numeron. Voit myös valita aikavälin vapaasti.",
      "Hyödyllisyysmallilehti numero 2/2023 ja sitä vanhemmat lehdet ovat saatavilla vain Patentti- ja rekisterihallituksessa. Näistä lehdistä saat tietoja ja kopioita pyytämällä asiakaspalvelustamme.",
    ],
    gazette_number: {
      label: "Valitse ilmestymisvuosi ja lehden numero",
      year: "Valitse vuosi",
      number: "Valitse numero",
    },
    date_range: {
      label: "Tai hae tietoja päivämäärän perusteella",
      begin: "Alkaen",
      end: "Saakka",
      search: "Hae",
      description: "Päivämäärä muodossa pp.kk.vvvv",
    },
    // archive: "Arkisto (Ennen XX.XX julkaisut lehdet)",
    archive_url: "https://patent.prh.fi/lehdet/default.asp",
    defaultOption: "-Valitse-",
    button_search: "Hae",
    button_clear: "Tyhjennä",
  },
  index: {
    rangeTitle: "Kuulutukset välillä ",
    issueTitle: "Kuulutukset ",
    types: {
      umnotification: "Tiedoksiannot",
      umannulpartial: "Osittain mitätöidyt hyödyllisyysmallit",
      um: "Rekisteröidyt hyödyllisyysmallit",
      umannul: "Mitätöidyt hyödyllisyysmallit",
      umrenewed: "Uudistetut hyödyllisyysmallirekisteröinnit",
      umreinstated: "Oikeuksien palautukset (hyödyllisyysmallilaki 26a §)",
      umtransferredbycourt:
        "Lainvoimaisella tuomiolla siirretyt hyödyllisyysmallit",
      umtransferred: "Siirretyt hyödyllisyysmallit",
      umeexpired: "Lakanneet hyödyllisyysmallit",
      umrevoked: "Mitätöidyt hyödyllisyysmallit",
      umcorrectedy8: "Y8 - hyödyllisyysmallijulkaisun korjausjulkaisu",
      umcorrectedpublications: "Korjatut julkaisut",
      umavailabletopublic: "Julkiseksi tulleet hyödyllisyysmallit",
    },
    st: "kpl",
    link_alt: "Hyppää osioon tällä sivulla",
  },
  details: {
    common: {
      yes: "Kyllä",
      no: "Ei",
      image: {
        alt: "Avaa kuva suuremmaksi",
      },
      number: "nro: ",
      jump_links: {
        index_title: "Palaa kuulutuksiin",
        page_title: "Palaa sivun alkuun",
      },
      close: "Sulje",
      imageModal: {
        label: "Suuremman hyödyllisyysmallikuvan esikatselu",
      },
    },
    //Taulukon käännökset
    table: {
      head: "Hakemuksen tiedot",
      rows: {
        classification: "Kansainvälinen patenttiluokka",
        documentNumber: "Asiakirjan numero",
        SPCdocumentNumber: "Hakemuksen numero",
        grantDate: "Rekisteröintipäivä",
        decisionDate: "Päätöksen päivämäärä",
        transferDate: "Siirron päivämäärä",
        noveltyResearch: "Uutuustutkimus suoritettu",
        removalDate: "Poiston päivämäärä",
        applicationNumber: "Hakemuksen numero",
        registrationNumber: "Rekisterinro",
        publicationDate: "Kuulutuspäivä",
        applicationDate: "Hakemuksen saapumispäivä",
        filingDate: "Hakemuksen tekemispäivä",
        applicants: "Haltija(t)",
        inventors: "Keksijä(t)",
        representatives: "Asiamies",
        title: "Keksinnön nimitys",
        SPCtitle: "Tuote",
        check: "Katso",
      },
      patinfo: "Katso lisätietoja patenttitietopalvelusta",
    },
    types: {
      umnotification: {
        title: "Tiedoksiannot",
        lines: [
          "Jos tiedoksianto koskee päätöstä, johon olette tyytymätön, voitte hakea siihen muutosta markkinaoikeudelta kirjallisella valituksella. Valitus on tehtävä 60 päivän kuluessa tiedoksiannon päivämäärästä. Päätös ja valitusosoitus ovat saatavissa Patentti- ja rekisterihallituksesta.",
        ],
      },
      umannulpartial: {
        title: "Osittain mitätöidyt hyödyllisyysmallit",
        lines: [""],
      },
      um: {
        title: "Rekisteröidyt hyödyllisyysmallit",
        lines: [""],
      },
      umannul: {
        title: "Mitätöidyt hyödyllisyysmallit",
        lines: [""],
      },
      umrenewed: {
        title: "Uudistetut hyödyllisyysmallirekisteröinnit",
        lines: [""],
      },
      umreinstated: {
        title: "Oikeuksien palautukset (hyödyllisyysmallilaki 26a §)",
        lines: [""],
      },
      umtransferredbycourt: {
        title: "Lainvoimaisella tuomiolla siirretyt hyödyllisyysmallit",
        lines: [""],
      },
      umtransferred: {
        title: "Siirretyt hyödyllisyysmallit",
        lines: [""],
      },
      umeexpired: {
        title: "Lakanneet hyödyllisyysmallit",
        lines: [""],
      },
      umrevoked: {
        title: "Mitätöidyt hyödyllisyysmallit",
        lines: [""],
      },
      umavailabletopublic: {
        title: "Julkiseksi tulleet hyödyllisyysmallit",
        lines: [""],
      },
      umcorrectedpublications: {
        title: "Korjatut julkaisut",
        lines: [""],
      },
    },
  },
};

export default Page;
