import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import { Link } from "react-router-dom";
import UseWindowSize from "../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../constants/constants";
import CommonFi from "../../i18n/Common/fi";
import CommonEn from "../../i18n/Common/en";
import CommonSv from "../../i18n/Common/sv";
import Logo from "../../scss/styles/images/logo.png";

const PrhBrand = (props) => {
  const { langCode } = useContext(I18nContext);
  const contentLangs = { fi: CommonFi, en: CommonEn, sv: CommonSv };
  const content = contentLangs[langCode];
  const size = UseWindowSize();

  return (
    <Link to="/">
      <div className="prh-site-header__title pt-2" data-cy="toFrontpage">
        <img
          alt={content.navigation.mainpage}
          className="prh-site-header__logo"
          src={Logo}
        />
        <div
          style={
            size.width < bootStrapGrid.sm
              ? { color: "white", fontSize: "1.2rem" }
              : { color: "white", fontSize: "1.8rem" }
          }
        >
          <span className="sub-title">{content.subtitle}</span>
          {content.title}
        </div>
      </div>
    </Link>
  );
};

export default PrhBrand;
