import React from "react";
import { Container } from "react-bootstrap";

const Jumbotron = (props) => {   
        
        
        return (
        <div className="jumbotron jumbotron-flow-under">
          <Container>
            <h1>{props.content.header}</h1>
            <p className="lead">{props.content.description}</p>
          </Container>
        </div>
        );
};

export default Jumbotron;