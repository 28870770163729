import useEndpoint from "./useEndpoint";
import { useEffect } from "react";
import { useState } from "react";
const baseUrl = process.env.REACT_APP_API_URL || "/api";
function dateSearchEndpoint() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useEndpoint((data) => ({
    url: baseUrl + `/gazette/${data.type}/${data.year}/${data.issue}`,
    method: "GET",
  }));
}
function archiveEndpoint() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useEndpoint((data) => ({
    url: baseUrl + `/gazette/${data.type}/archive`,
    method: "GET",
  }));
}
function dateRangeSearchEndpoint() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useEndpoint((data) => ({
    url:
      baseUrl +
      `/gazette/${data.type}/search?startDate=${data.startDate}&endDate=${data.endDate}`,
    method: "GET",
  }));
}

export default function usePatentEndpoint() {
  const [dateSearch, postDateSearch] = dateSearchEndpoint();
  const [dateRangeSearch, postDateRangeSearch] = dateRangeSearchEndpoint();
  const [search, setSearch] = useState();
  const [archiveSearch, postArchiveSearch] = archiveEndpoint();

  useEffect(() => {
    setSearch(archiveSearch);
  }, [archiveSearch]);

  useEffect(() => {
    setSearch(dateSearch);
  }, [dateSearch]);
  useEffect(() => {
    setSearch(dateRangeSearch);
  }, [dateRangeSearch]);

  const postArchive = async ({ type }) => {
    await postArchiveSearch({ type });
    return;
  };
  const postSearch = async ({ startDate, endDate, year, issue, type }) => {
    if (startDate && endDate) {
      await postDateRangeSearch({ startDate, endDate, type });
      return;
    }
    if (year && issue) {
      await postDateSearch({ year, issue, type });
      return;
    }
  };

  return { search, postSearch, postArchive };
}
