import React from "react";
import "../../../scss/styles/app.scss";
import { formatDate } from "../../../utils/helpers";
import Table from "../../common/Table";
import DeclarationsPT from "./publications/DeclarationsPT";

const showFields = (type) => ["um", "umreinstated"].includes(type);

const baseUrl =
  // process.env.REACT_APP_PATENT_URL ||
  "https://patenttitietopalvelu.prh.fi/";

const UMDetails = (props) => {
  const details = props.content.details;
  const table = details.table;

  console.log("UMDetails", props);
  return (
    <>
      <p>{details.types[props.type]?.lines}</p>
      {props.type !== "umnotification" &&
        props.gazettes.map((e, i) => {
          return (
            <Table
              id={`table-${i}`}
              key={`table-${i}`}
              head={[table.head, ""]}
              headCols={[1]}
              rows={[
                [
                  table.rows.classification,
                  e.ipcClassifications
                    ? e.ipcClassifications.map((a) => a.classNumber)
                    : "",
                ],
                e.patentNumber && [table.rows.documentNumber, [e.patentNumber]],
                e.grantDate && [
                  table.rows.grantDate,
                  [formatDate(e.grantDate)],
                ],
                [table.rows.publicationDate, [formatDate(e.publicationDate)]],
                showFields(props.type) && [
                  table.rows.applicationDate,
                  [formatDate(e.applicationDate)],
                ],
                showFields(props.type) && [
                  table.rows.filingDate,
                  [formatDate(e.filingDate)],
                ],
                e.applicationNumber && [
                  table.rows.applicationNumber,
                  [e.applicationNumber],
                ],
                [
                  table.rows.applicants,
                  e.owners
                    ? e.owners.map(
                        (a) =>
                          `${
                            a.companyName
                              ? a.companyName
                              : a.fullName
                              ? a.fullName
                              : ""
                          } ${a.address.country ? a.address.country : ""}`
                      )
                    : "",
                ],
                showFields(props.type) && [
                  table.rows.representatives,
                  e.representatives
                    ? e.representatives.map(
                        (a, i) =>
                          `${
                            a.companyName
                              ? a.companyName
                              : a.fullName
                              ? a.fullName
                              : ""
                          } ${a.address.country ? a.address.country : ""}`
                      )
                    : "",
                ],
                e.patentTitle && [
                  table.rows.title,
                  e.patentTitle
                    ? e.patentTitle.map((a, i) =>
                        a.text ? `${a.languageCode}: ${a.text}` : ""
                      )
                    : "",
                  [e.patentTitle?.[0].text],
                ],
                showFields(props.type) && [
                  table.rows.noveltyResearch,
                  [
                    e.examinationRequest
                      ? details.common.yes
                      : details.common.no,
                  ],
                ],
                e.applicationNumber && [
                  <a
                    href={`${baseUrl}${document.documentElement.lang}/patent/${e.applicationNumber}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {table.patinfo}
                  </a>,

                  [],
                ],
              ]}
            />
          );
        })}
      {props.type === "umnotification" &&
        props.gazettes.map((e) => (
          <DeclarationsPT details={details} gazettes={e} />
        ))}
    </>
  );
};

export default UMDetails;
