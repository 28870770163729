import React, { useState, useContext } from "react";
import { I18nContext } from "../../i18n";
import NavMenu from "./NavMenu";
import { Link } from "react-router-dom";
import CommonFi from "../../i18n/Common/fi";
import CommonEn from "../../i18n/Common/en";
import CommonSv from "../../i18n/Common/sv";

const MobileNavbar = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const { langCode } = useContext(I18nContext);
  const contentLangs = { fi: CommonFi, en: CommonEn, sv: CommonSv };
  const content = contentLangs[langCode];

  return (
    <header className="prh-site-header" id="navbar">
      <div className="container prh-site-header__container">
        <nav className="navbar navbar-dark navbar-expand-md">

          <Link to="/">
            <div className="prh-site-header__title">
              <div className="prh-site-header__logo"></div>
              <span>
                <span className="sub-title">{content.subtitle}</span>
                {content.title}
              </span>
            </div>
          </Link>

          <button
            aria-label={content.navigation.show}
            aria-controls="nav_collapse"
            aria-expanded={menuVisible}
            data-cy="menu"
            type="button"
            className={[
              "navbar-toggler",
              menuVisible
                ? ""
                : "collapsed",
            ].join(" ")}
            onClick={() => setMenuVisible(!menuVisible)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {menuVisible ? <NavMenu id="nav_collapse" /> : null}
        </nav>
        
      </div>
    </header>
  );
};

export default MobileNavbar;
