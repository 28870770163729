const Page = {
  header: "Nyttighetsmodelltidning",
  description: "",
  title:
    "Nyttighetsmodelltidning - Tidningar för industriellt rättsskydd | PRH",
  common: {
    week: "Vecka",
  },
  message_box: {
    info_title: "Inga resultat hittades med dina sökvillkor.",
    danger_title: "Sökningen misslyckades. Ange sökvillkor.",
  },
  search: {
    date_warning: "Kontrollera datum",
    lines: [
      "Tidningen innehåller ett sammandrag av kungörelser och delgivningar som gäller nyttighetsmodeller.",
      "Vi uppdaterar uppgifterna varje dag.",
      "Du kan söka publikationer för en hel vecka genom att välja utgivningsår och tidningsnummer. Du kan också välja perioden fritt.",
      "Nyttighetsmodelltidning till nummer 2/2023 tillhandahålls endast av Patent- och registerstyrelsen. Kontakta vår kundtjänst för att få mer information och kopior av tidningarna.",
    ],

    gazette_number: {
      label: "Välj utgivningsår och tidningsnummer",
      year: "Välj år",
      number: "Välj nummer",
    },
    date_range: {
      label: "Eller sök uppgifter efter datum",
      begin: "Från och med",
      end: "Till och med",
      search: "Sök",
      description: "Skriv datumet enligt formatet dd.mm.åååå",
    },
    // archive: "Arkiv (tidningar publicerade före 1.2.2023 )",
    archive_url: "https://patent.prh.fi/lehdet/default.asp",
    defaultOption: "-Välja-",
    button_search: "Sök",
    button_clear: "Rensa",
  },
  index: {
    rangeTitle: "Kungörelser ",
    issueTitle: "Kungörelser ",
    types: {
      umnotification: "Delgivningar",
      umannulpartial: "Delvis ogiltigförklarade nyttighetsmodeller",
      um: "Registrerade nyttighetsmodeller",
      umannul: "Ogiltigförklarade nyttighetsmodeller",
      umrenewed: "Förnyade nyttighetsmodellregistreringar",
      umreinstated:
        "Återställanden av rättigheter (lagen om nyttighetsmodellrätt 26a §)",
      umtransferredbycourt:
        "Nyttighetsmodeller som överlåtits genom en lagakraftvunnen dom",
      umtransferred: "Överlåtelse av nyttighetsmodell",
      umeexpired: "Upphörda nyttighetsmodeller",
      umrevoked: "Ogiltigförklarade nyttighetsmodeller",
      umavailabletopublic: "Nyttighetsmodeller som har blivit offentliga",
      umcorrectedpublications: "Korrigerade publikationer",
    },
    st: "st.",
    link_alt: "Hoppa till ett avsnitt på den här sidan",
  },
  details: {
    common: {
      yes: "Ja",
      no: "Nej",
      image: {
        alt: "Öppna en större bild",
      },
      number: "nr: ",
      jump_links: {
        index_title: "Gå tillbaka till kungörelser",
        page_title: "Gå tillbaka till början av sidan",
      },
      close: "Stäng",
      imageModal: {
        label: "Suuremman hyödyllisyysmallikuvan esikatselu",
      },
    },
    //Taulukon käännökset
    table: {
      head: "Uppgifter om ansökan",
      rows: {
        classification: "Internationell patentklass",
        documentNumber: "Handlingsnummer (registeringsnummer)",
        SPCdocumentNumber: "Ansökningsnummer",
        grantDate: "Registeringsdag",
        decisionDate: "ddmmååå",
        transferDate: "Överlåtelse datum",
        noveltyResearch: "Granskning av nyttighetsmodellen gjort",
        removalDate: "Strykning datum",
        applicationNumber: "Ansökningsnummer",
        registrationNumber: "Registeringsnummer",
        publicationDate: "Kungörelsedatum",
        applicationDate: "Ankomstdag",
        filingDate: "Ansökans ingivningsdag",
        applicants: "Innehavare",
        inventors: "Uppfinnare",
        representatives: "Ombud",
        title: "Benämning",
        SPCtitle: "Produkt",
        check: "Se",
      },
      patinfo: "Se mer information i Patentinformationstjänst", //tietopalvelu linkin teksti
    },
    types: {
      umnotification: {
        title: "Delgivningar",
        lines: [
          "Om delgivningen gäller ett beslut som du är missnöjd med, kan du söka ändring i det hos marknadsdomstolen genom skriftliga besvär. Besvär ska anföras inom 60 dagar från delgivningens datum. Beslutet och besvärsanvisningen tillhandahålls av Patent- och registerstyrelsen.",
        ],
      },
      umannulpartial: {
        title: "Delvis ogiltigförklarade nyttighetsmodeller",
        lines: [""],
      },
      um: {
        title: "Registrerade nyttighetsmodeller",
        lines: [""],
      },
      umannul: {
        title: "Ogiltigförklarade nyttighetsmodeller",
        lines: [""],
      },
      umrenewed: {
        title: "Förnyade nyttighetsmodellregistreringar",
        lines: [""],
      },
      umreinstated: {
        title:
          "Återställanden av rättigheter (lagen om nyttighetsmodellrätt 26a §)",
        lines: [""],
      },
      umtransferredbycourt: {
        title: "Nyttighetsmodeller som överlåtits genom en lagakraftvunnen dom",
        lines: [""],
      },
      umtransferred: {
        title: "Överlåtna nyttighetsmodeller",
        lines: [""],
      },
      umeexpired: {
        title: "Upphörda nyttighetsmodeller",
        lines: [""],
      },
      umrevoked: {
        title: "Ogiltigförklarade nyttighetsmodeller",
        lines: [""],
      },

      umavailabletopublic: {
        title: "Nyttighetsmodeller som har blivit offentliga",
        lines: [""],
      },
      umcorrectedpublications: {
        title: "Korrigerade publikationer",
        lines: [""],
      },
    },
  },
};

export default Page;
