import React, { useState, useContext } from "react";
import { Modal, Row } from "react-bootstrap";
import { formatDate } from "../../../../utils/helpers";
import { I18nContext } from "../../../../i18n/index";
const baseUrl = process.env.REACT_APP_API_URL || "/api";

const PublishedRegistrations = ({ gazettes, details }) => {
  const showAll = gazettes.applicationDate ? true : false;
  const inid = details.types.julk.inid;
  const common = details.common;
  const [showModal, setShowModal] = useState(false);

  const { langCode } = useContext(I18nContext);
  const langPosition = { fi: 0, sv: 1, en: 0 };
  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  const imageModal = (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="lg"
      aria-label={details.common.imageModal.label}
    >
      <Modal.Header
        closeButton
        closeLabel={details.common.close}
      ></Modal.Header>
      <Modal.Body>
        <img
          data-cy="image"
          className="img-fluid pb-4"
          src={gazettes.markImage}
          alt={
            gazettes.markWord
              ? gazettes.markWord
              : details.common.trademarkImage
          }
        />
      </Modal.Body>
    </Modal>
  );

  const designers = (designs) => {
    let designersString = "";
    const designersArray = [];
    if (designs.length === 1)
      designs[0].designers.forEach((d, i) => {
        designersString =
          designersString +
          d.fullName +
          (designs[0].designers.length - 1 > i ? ", " : "");
      });
    else {
      designs.forEach((design) => {
        design.designers.forEach((item) => {
          let designer = {};
          let ind = designersArray.findIndex(
            (obj) => obj.name === item.fullName
          );
          if (ind >= 0) {
            designersArray[ind].designs.push(design.ordinal.toString());
          } else {
            designer.name = item.fullName;
            designer.designs = [design.ordinal.toString()];
            designersArray.push(designer);
          }
        });
      });
      if (designersArray.length > 1) {
        designersArray.forEach((d, i) => {
          let continious = true;
          let designString = "";
          d.designs.forEach((item, index) => {
            if (
              d.designs.length > 1 &&
              d.designs.length - 1 > index &&
              parseInt(item) + 1 !== parseInt(d.designs[index + 1])
            )
              continious = false;

            designString =
              designString +
              "M" +
              item +
              (d.designs.length - 1 > index ? ", " : "");
          });
          if (continious)
            designersString =
              designersString +
              "M" +
              d.designs[0] +
              "." +
              (d.designs.length > 1
                ? "-" + d.designs[d.designs.length - 1] + "."
                : "") +
              " " +
              d.name +
              (designersArray.length - 1 > i ? ", " : "");
          else {
            designersString =
              designersString +
              designString +
              " " +
              d.name +
              (designersArray.length - 1 > i ? ", " : "");
          }
        });
      } else if (designersArray.length === 1) {
        designersString = designersArray[0].name;
      }
    }

    return designersString && designersString.length > 0 ? (
      <p style={{ marginBottom: 0 }}>
        <strong>{inid.designers}</strong>
        {designersString}
      </p>
    ) : (
      ""
    );
  };

  const classifications = (designs) => {
    let classificationsString = "";
    const classificationrsArray = [];
    if (designs.length === 1)
      designs[0].classifications.forEach((d, i) => {
        classificationsString +=
          d.class +
          "-" +
          d.subclass +
          (designs[0].classifications.length - 1 > i ? ", " : "");
      });
    else {
      designs.forEach((design) => {
        design.classifications.forEach((item) => {
          let locarno = {};
          let ind = classificationrsArray.findIndex(
            (obj) => obj.class === item.class + "-" + item.subclass
          );
          if (ind >= 0) {
            classificationrsArray[ind].designs.push(design.ordinal.toString());
          } else {
            locarno.class = item.class + "-" + item.subclass;
            locarno.designs = [design.ordinal.toString()];
            classificationrsArray.push(locarno);
          }
        });
      });
      if (classificationrsArray.length > 1) {
        classificationrsArray.forEach((d, i) => {
          let continious = true;
          let designString = "";
          d.designs.forEach((item, index) => {
            if (
              d.designs.length > 1 &&
              d.designs.length - 1 > index &&
              parseInt(item) + 1 !== parseInt(d.designs[index + 1])
            )
              continious = false;

            designString =
              designString +
              "M" +
              item +
              (d.designs.length - 1 > index ? ", " : "");
          });
          if (continious)
            classificationsString +=
              "M" +
              d.designs[0] +
              "." +
              (d.designs.length > 1
                ? "-" + d.designs[d.designs.length - 1] + "."
                : "") +
              " " +
              d.class +
              (classificationrsArray.length - 1 > i ? ", " : "");
          else {
            classificationsString +=
              designString +
              " " +
              d.class +
              (classificationrsArray.length - 1 > i ? ", " : "");
          }
        });
      } else if (classificationrsArray.length === 1) {
        classificationsString = classificationrsArray[0].class;
      }
    }

    return classificationsString;
  };

  const productTitle = (designs) => {
    let titleString = "";
    designs.forEach((design, index) => {
      let descriptionString = "";
      design.descriptions
        .filter((d) => d.languageCode === langCode || langCode === "en")
        .forEach((desc, ind) => {
          descriptionString +=
            desc.text +
            (design.descriptions.filter(
              (d) => d.languageCode === langCode || langCode === "en"
            ).length -
              1 >
            ind
              ? ", "
              : "");
        });
      titleString =
        titleString +
        "M" +
        (index + 1) +
        " " +
        descriptionString +
        (gazettes.designs.length - 1 > index ? ", " : "");
    });
    return titleString;
  };

  const priorities = (designs) => {
    let prioritiesString = "";
    designs.forEach((design, index) => {
      if (design.priorities?.length > 0) {
        let prioString = "";
        design.priorities.forEach((prio, ind) => {
          prioString +=
            prio.countryCode +
            ", " +
            formatDate(prio.filingDate) +
            ", " +
            common.number +
            prio.applicationNumber +
            (design.priorities.length - 1 > ind ? ", " : "");
        });
        prioritiesString +=
          "M" +
          (index + 1) +
          ". " +
          prioString +
          (gazettes.designs.length - 1 > index ? ", " : "");
      }
    });
    prioritiesString = prioritiesString.trim();
    prioritiesString = prioritiesString.replace(/,$/, "");

    return prioritiesString.length > 0 ? (
      <p style={{ marginBottom: 0 }}>
        <strong>{inid.priorities}</strong> {prioritiesString}
      </p>
    ) : null;
  };

  const designImages = (designs) => {
    return designs.map((design) => (
      <Row className="ml-0">{designViews(design.views, design.ordinal)}</Row>
    ));
  };

  const designViews = (views, id) => {
    return views.map((view) => (
      <figure
        className="p-1"
        onClick={() => handleOpen(baseUrl + view.url)}
        style={{ border: "none", width: "30%" }}
      >
        <img
          data-cy="image"
          className="img-fluid"
          src={baseUrl + view.url}
          alt={common.image.alt}
        />
        <figcaption>{`M${id}${views.length > 1 ? "." + view.id : ""}${
          view.viewTypeCategory === "Enlarged" ? ` ${common.enlarged}` : ""
        }`}</figcaption>
      </figure>
    ));
  };

  return (
    <>
      {imageModal}
      {gazettes && (
        <>
          <hr style={{ color: "grey" }} alt={details.common.alt_type.oik} />

          <div className="d-flex flex-column">
            {gazettes.events &&
              gazettes.events.map((e) => (
                <p style={{ whiteSpace: "pre-wrap" }}>{e.commentText}</p>
              ))}
            {gazettes.publicationDate && (
              <p style={{ marginBottom: 0 }}>
                <strong>{inid.publicationDate}</strong>{" "}
                {formatDate(gazettes.publicationDate)}
              </p>
            )}
            {showAll && (
              <>
                {gazettes?.designs[0]?.oppositionPeriodStart && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.oppositionPeriodStart}</strong>{" "}
                    {formatDate(gazettes?.designs[0]?.oppositionPeriodStart)}
                  </p>
                )}
                {gazettes?.designs[0]?.oppositionPeriodEnd && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.oppositionPeriodEnd}</strong>{" "}
                    {formatDate(gazettes?.designs[0]?.oppositionPeriodEnd)}
                  </p>
                )}
                {gazettes.registrationNumber && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.registrationNumber}</strong>{" "}
                    {gazettes.registrationNumber}
                  </p>
                )}
                {gazettes.registrationDate && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.registrationDate}</strong>{" "}
                    {formatDate(gazettes.registrationDate)}
                  </p>
                )}
                {gazettes.applicationNumber && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.applicationNumber}</strong>{" "}
                    {gazettes.applicationNumber}
                  </p>
                )}
                {gazettes.applicationDate && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.applicationDate}</strong>{" "}
                    {formatDate(gazettes.applicationDate)}
                  </p>
                )}
                {gazettes.applicants && gazettes.applicants.length > 0 && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.applicants}</strong>{" "}
                    {gazettes.applicants
                      .map((e) => {
                        return `${e.fullName ? e.fullName : e.companyName}${
                          e.address.cityName
                            ? Array.isArray(e.address.cityName)
                              ? ", " +
                                e.address.cityName[langPosition[langCode]].text
                              : e.address.cityName && ", " + e.address.cityName
                            : ""
                        }${e.address.country && ", " + e.address.country}
                      `;
                      })
                      .join(", ")}
                  </p>
                )}
                {gazettes.representatives &&
                  gazettes.representatives.length > 0 && (
                    <p style={{ marginBottom: 0 }}>
                      <strong>{inid.representatives}</strong>{" "}
                      {gazettes.representatives.map((e) => {
                        return `${e.fullName ? e.fullName : e.companyName}${
                          e.address.cityName
                            ? Array.isArray(e.address.cityName)
                              ? ", " +
                                e.address.cityName[langPosition[langCode]].text
                              : e.address.cityName && ", " + e.address.cityName
                            : ""
                        }${e.address.country && ", " + e.address.country}
                        `;
                      })}
                    </p>
                  )}
                {gazettes.designs &&
                  gazettes.designs.length > 0 &&
                  designers(gazettes.designs)}
                {gazettes.designs && gazettes.designs.length > 0 && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.productTitle}</strong>{" "}
                    {productTitle(gazettes.designs)}
                  </p>
                )}
                {gazettes.designs && gazettes.designs.length > 0 && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.classification}</strong>{" "}
                    {classifications(gazettes.designs)}
                  </p>
                )}
                {gazettes.designs &&
                  gazettes.designs.length > 0 &&
                  priorities(gazettes.designs)}
                {
                  /* Muunnosrekisteröinti */
                  gazettes.designs &&
                    gazettes.designs.length > 0 &&
                    gazettes.designs[0].relatedApplication &&
                    gazettes.designs[0].relatedApplication.length > 0 && (
                      <p style={{ marginBottom: 0 }}>
                        <strong>{inid.relatedApplication}</strong>{" "}
                        {gazettes.designs[0].relatedApplication[0].ref}
                      </p>
                    )
                }
                {
                  /* Kuvat */
                  gazettes.designs &&
                    gazettes.designs.length > 0 &&
                    gazettes.designs[0].views &&
                    gazettes.designs[0].views.length > 0 && (
                      <p style={{ marginBottom: 0 }}>
                        <strong>{inid.images}</strong>
                        {" " +
                          gazettes.designs
                            .map(
                              (e) =>
                                `M${e.ordinal}. ${
                                  e.views[0].colourIndicator === "true"
                                    ? common.colour
                                    : common.bw
                                }`
                            )
                            .join(", ")}
                        {designImages(gazettes.designs)}
                      </p>
                    )
                }
                {
                  /* Mallikappale annettu */
                  gazettes.designs &&
                    gazettes.designs.length > 0 &&
                    gazettes.designs[0].sampleProvided ===
                      "Mallikappale annettu" && (
                      <p style={{ marginBottom: 0 }}>
                        <strong>{inid.sampleProvided}</strong>
                      </p>
                    )
                }
              </>
            )}

            {(gazettes.designs[0]?.designReference || (gazettes.applicationNumber && gazettes.registrationNumber)) && (
              <p style={{ marginBottom: 0 }}>
                <a
                  href={gazettes.designs[0]?.designReference
                    ? gazettes.designs[0]?.designReference
                    : `${process.env.REACT_APP_DESIGN_URL}${document.documentElement.lang}/design/${gazettes.applicationNumber}/${gazettes.registrationNumber}`}
                  className="external"
                  target="_blank"
                  rel="noreferrer"
                >
                  {gazettes.designs[0]?.designReference
                    ? inid.designReferenceLinkWipo
                    : inid.designReferenceLink}
                </a>
              </p>
            )}
            <p style={{ marginBottom: 20 }}></p>
          </div>
        </>
      )}
    </>
  );
};
export default PublishedRegistrations;
