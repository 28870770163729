module.exports = {
  trademark: {
    header: "Tavaramerkkilehti",
    title: "Arkisto - Tavaramerkkilehti - Teollisoikeuslehdet | PRH",
    section: {
      title: "Arkisto",
      lines: [
        "Tavaramerkkilehti ilmestyi 15.8.2022 asti kaksi kertaa kuukaudessa. Lehden numerot 22/2014 – 15/2022 löydät alta PDF-muodossa.",
        "Tavaramerkkilehti numero 21/2014 ja sitä vanhemmat lehdet (PDF) ovat saatavilla vain Patentti- ja rekisterihallituksessa. Näistä lehdistä saat tietoja ja kopioita pyytämällä asiakaspalvelustamme.",
        "Ennen vuotta 2005 Tavaramerkkilehteä (ISSN 0039-9922) julkaistiin ainoastaan paperiversioina. Lehdet ovat nähtävissä Patentti- ja rekisterihallituksessa ja kansalliskirjastossa.",
      ],
    },
  },
  design: {
    header: "Mallioikeuslehti",
    title: "Arkisto - Mallioikeuslehti - Teollisoikeuslehdet | PRH",
    section: {
      title: "Arkisto",
      lines: [
        "Mallioikeuslehti ilmestyi 15.8.2022 asti kerran kuukaudessa. Lehden numerot 02/2016 – 08/2022 löydät alta PDF-muodossa.",  
        "Mallioikeuslehti numero 01/2016 ja sitä vanhemmat lehdet (PDF-muodossa) ovat saatavilla vain Patentti- ja rekisterihallituksessa. Näistä lehdistä saat tietoja ja kopioita pyytämällä asiakaspalvelustamme.",
        "Ennen vuotta 2005 Mallioikeuslehteä (ISSN 0355-4481) julkaistiin ainoastaan paperiversioina. Lehdet ovat nähtävissä Patentti- ja rekisterihallituksessa ja kansalliskirjastossa.",
      ],
    },
  },
  common: {
    jump_links:{
      index_title: "Palaa arkistoon",
      page_title: "Palaa sivun alkuun",
    }
  },
  
};
