import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { I18nContext } from "../i18n";
import { Container } from "react-bootstrap";
import "../scss/styles/app.scss";
import MainPageFi from "../i18n/MainPage/fi";
import MainPageEn from "../i18n/MainPage/en";
import MainPageSv from "../i18n/MainPage/sv";
import Layout from "../components/common/Layout";

import { Link } from "react-router-dom";

const MainPage = (props) => {
  const { dispatch } = useContext(I18nContext);
  const breadCrumbs = ["mainpage"];

  const contentLangs = { fi: MainPageFi, en: MainPageEn, sv: MainPageSv };
  const content = contentLangs[props.match.params.lang];

  document.title = content.title;
  useEffect(() => {
    if (
      props.match.params.lang !== "fi" &&
      document.documentElement.lang !== props.match.params.lang
    ) {
      document.documentElement.lang = props.match.params.lang;
      dispatch({ type: "setLanguage", payload: props.match.params.lang });
    }
    props.setActiveLink("Home");
  }, [dispatch, props]);

  return (
    <Layout 
      breadCrumbs={breadCrumbs}
      closeSurvey={props.closeSurvey}
      showSurvey={props.showSurvey}
      >
      <main id="maincontent" tabIndex="-1">
        <div className="jumbotron jumbotron-flow-under">
          <Container>
            <h1>{content.header}</h1>
            <p className="lead">{content.description}</p>
          </Container>
        </div>
        <Container>
          {content.content.sections
            .filter((e) => !e.hidden)
            .map((e,i) =>
              e.border ? (
                <section key={i} className="prh-section-borders">
                  {e.title && e.title.url && (
                    <h2>
                      <Link to={e.title.url} href="#" target="_self">
                        {e.title.text}
                      </Link>
                    </h2>
                  )}
                  {e.title && !e.title.url && <h2>{e.title.text}</h2>}
                  {e.lines.map((i) => (
                    <p key={i} dangerouslySetInnerHTML={{ __html: i }} />
                  ))}
                  {e.archive && (
                    <p>
                      {e.archive.description}
                      <Link
                        to={props.location.pathname + e.title.url + "/archive/"}
                      >
                        {e.archive.link_label}
                      </Link>
                    </p>
                  )}
                </section>
              ) : (
                <div key={i} className="prh-section pt-2 pb-2 mt-2 mb-2">
                  {e.title && e.title.url && (
                    <h2>
                      <Link to={e.title.url} href="#" target="_self">
                        {e.title.text}
                      </Link>
                    </h2>
                  )}
                  {e.title && !e.title.url && <h3>{e.title.text}</h3>}
                  {e.lines.map((p, i) => (
                    <p key={i} dangerouslySetInnerHTML={{ __html: p }} />
                  ))}
                </div>
              )
            )}
        </Container>
      </main>
    </Layout>
  );
};

export default withRouter(MainPage);
