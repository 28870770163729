import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import useWindowSize from "../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../constants/constants";
const DatePick = (props) => {
  const [startDate, setStartDate] = useState(props.value);

  const size = useWindowSize();

  useEffect(() => {
    setStartDate(props.value);
  }, [props.value]);

  return (
    <div className="alfame-datepicker-wrapper">
      <input
        type="date"
        id={props.id}
        aria-describedby={props.ariaDescribedBy}
        // dateFormat={["dd.MM.yyyy", "ddMMyyyy"]}
        // selected={startDate}
        value={startDate}
        min="2000-01-01"
        max="2100-01-01"
        onChange={(date) => props.handleChange(props.id, date)}
        className={
          size.width > bootStrapGrid.sm
            ? "form-control date-min-width " + (props.valid ? "" : "is-invalid")
            : "form-control padding-s " + (props.valid ? "" : "is-invalid")
        }
        // placeholderText={props.placeholderText}
        name={props.data_cy}
      />
      {props.valid ? null : (
        <div
          className="text-danger"
          style={{ fontSize: "80%" }}
          role="alert"
          data-cy={props.name + "Error"}
        >
          {props.errorText}
        </div>
      )}
    </div>
  );
};

export default DatePick;
