import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "../../scss/styles/app.scss";

import Dropdown from "../common/Dropdown";
import DatePick from "../common/DatePicker";
import MessageBox from "../common/MessageBox";

const Search = (props) => {
  const txt = props.content.search;

  return (
    <section className="prh-section-borders">
      {txt.lines.map((e, i) => (
        <p key={i} dangerouslySetInnerHTML={{ __html: e }} />
      ))}
      <fieldset>
        <legend className="mt-4">{txt.gazette_number.label}</legend>
        <Row xs={1} sm={3} md={4} aria-atomic="true">
          <Col xs={12} md={3}>
            <Dropdown
              title={txt.gazette_number.year}
              defaultOption={txt.defaultOption}
              onChange={(e) => props.onYearChange(e)}
              selections={props.yearOptions}
              id="year"
              value={props.year}
            />
          </Col>
          <Col sm={1} md={1} className="d-none d-sm-block" />
          <Col xs={12} md={3}>
            <Dropdown
              className="min-w-50"
              title={txt.gazette_number.number}
              defaultOption={txt.defaultOption}
              onChange={(e) => props.onIssueChange(e)}
              selections={props.issueOptions}
              controls="year"
              id="gazette_number"
              value={props.issue}
            />
          </Col>
        </Row>
      </fieldset>
      <fieldset>
        <legend className="pt-4">{txt.date_range.label}</legend>

        <Row xs={1} sm={3} md={4}>
          <Col xs={12} md={3}>
            <label htmlFor="startDate" className="ml-0">
              {txt.date_range.begin}
            </label>
            <DatePick
              ariaDescribedBy="startDate_description"
              value={
                props.startDate === ""
                  ? ""
                  : new Date(props.startDate).toISOString().split("T")[0]
              }
              id="startDate"
              handleChange={(e, a) => props.onStartDateChange(e, a)}
              valid={props.startValid}
              errorText={txt.date_warning}
            />
            <small id="startDate_description">
              {txt.date_range.description}
            </small>
          </Col>
          <Col sm={1} md={1} className="d-none d-sm-block">
            <hr className="mt-5"></hr>
          </Col>
          <Col xs={12} md={3}>
            <label htmlFor="endDate" className="ml-0">
              {txt.date_range.end}
            </label>
            <DatePick
              ariaDescribedBy="endDate_description"
              id="endDate"
              value={
                props.endDate === ""
                  ? ""
                  : new Date(props.endDate).toISOString().split("T")[0]
                // : new Date(props.endDate).toISOString().split("T")[0]
              }
              handleChange={(e, a) => props.onEndDateChange(e, a)}
              valid={props.endValid}
              errorText={txt.date_warning}
            />
            <small id="endDate_description">{txt.date_range.description}</small>
          </Col>
        </Row>
      </fieldset>
      {props.warning && (
        <MessageBox
          className="alert-danger"
          aria-live="polite"
          title={props.content.message_box.danger_title}
          danger={true}
        />
      )}
      <Row className="mt-5 justify-content-between">
        <Col xs={12} md={6}>
          <button
            onClick={props.onSubmit}
            style={{ width: "45%" }}
            className="pl-5 pr-5 btn btn-primary"
          >
            {txt.button_search}
          </button>

          <button
            onClick={props.onReset}
            style={{ width: "45%" }}
            className="btn btn-tertiary"
          >
            {txt.button_clear}
          </button>
        </Col>
        {txt.archive && (
          <Col md={6} className="mt-3 text-right">
            <Link
              className="link-icon link-internal"
              to={props.location.pathname + "/archive"}
            >
              {txt.archive}
            </Link>
          </Col>
        )}
      </Row>
    </section>
  );
};

export default withRouter(Search);
