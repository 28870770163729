import React from "react";
import { withRouter } from "react-router-dom";
import "../../scss/styles/app.scss";
import DesignDetails from "./gazetteDetails/DesignDetails";
import DetailsSection from "./gazetteDetails/DetailsSection";
import PatentDetails from "./gazetteDetails/PatentDetails";
import UMDetails from "./gazetteDetails/UMDetails";
import TrademarkDetails from "./gazetteDetails/TrademarkDetails";

const Details = (props) => {
  const details = props.content.details;
  return (
    <DetailsSection
      type={props.type}
      details={details}
      count={props.gazettes.length}
    >
      {props.id === "patent" && (
        <PatentDetails
          gazettes={props.gazettes}
          type={props.type}
          content={props.content}
        />
      )}
      {props.id === "um" && (
        <UMDetails
          gazettes={props.gazettes}
          type={props.type}
          content={props.content}
        />
      )}
      {props.id === "trademark" && (
        <TrademarkDetails
          gazetteType={props.id}
          gazettes={props.gazettes}
          type={props.type}
          content={props.content}
        />
      )}
      {props.id === "design" && (
        <DesignDetails
          gazettes={props.gazettes}
          type={props.type}
          content={props.content}
        />
      )}
    </DetailsSection>
  );
};

export default withRouter(Details);
