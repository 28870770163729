import React from "react";
import { withRouter } from "react-router-dom";
import "../../../../scss/styles/app.scss";
import { formatDate } from "../../../../utils/helpers";

import Table from "../../../common/Table";
const baseUrl =
  process.env.REACT_APP_PATENT_URL || "https://patenttitietopalvelu.prh.fi/";

//blacklist

const hideClassNumber = (type) =>
  ![
    "julkpatwith",
    "julkpatdis",
    "vtptkumot",
    "patclaimscorrected",
    "patlimited",
    "patannul",
    "patexpired",
    "pattransferred",
    "patspcfiled",
    "patspcwithdrawn",
    "patspcrefused",
    "patspcdismissed",
    "patspccontinuationwithdrawn",
    "patspccontinuationgranted",
    "patspccontinuationfiled",
    "patspccontinuationrefused",
    "patspcgrantedcertificate",
    "patspcvaliditycorrected",
    "patspcannul",
    "patspclapsed",
  ].includes(type);
const hideApplicationNumber = (type) => ![].includes(type);

const hideApplicationDate = (type) =>
  ![
    "julkpatwith",
    "julkpatdis",
    "vtptkumot",
    "patoppositionamended",
    "patclaimscorrected",
    "patlimited",
    "patannul",
    "patexpired",
    "pattransferred",
  ].includes(type);

const hideFilingDate = (type) =>
  ![
    "julkpatwith",
    "julkpatdis",
    "vtptkumot",
    "patoppositionamended",
    "patclaimscorrected",
    "patlimited",
    "patannul",
    "patexpired",
    "pattransferred",
  ].includes(type);

const hideApplicants = (type) => ![].includes(type);

const hideInventors = (type) =>
  ![
    "julkpatwith",
    "julkpatdis",
    "vtptkumot",
    "patoppositionamended",
    "patclaimscorrected",
    "patlimited",
    "patannul",
    "patexpired",
    "pattransferred",
    "patspcfiled",
    "patspcwithdrawn",
    "patspcrefused",
    "patspcdismissed",
    "patspccontinuationwithdrawn",
    "patspccontinuationgranted",
    "patspccontinuationfiled",
    "patspccontinuationrefused",
    "patspcgrantedcertificate",
    "patspcvaliditycorrected",
    "patspcannul",
    "patspclapsed",
  ].includes(type);

const hideRepresentatives = (type) =>
  ![
    "julkpatwith",
    "julkpatdis",
    "vtptkumot",
    "patoppositionamended",
    "patclaimscorrected",
    "patlimited",
    "patannul",
    "patexpired",
    "pattransferred",
  ].includes(type);

const hidePatentTitle = (type) => ![].includes(type);

const hidePatInfo = (type) =>
  ![
    /*"julkpatwith",
    "julkpatdis",
    "vtptkumot",
    "patoppositionamended",
    "patclaimscorrected",
    "patlimited",
    "patannul",
    "patexpired",
    "pattransferred",*/
  ].includes(type);

const Patent = (props) => {
  const details = props.details;
  const table = details.table;
  return (
    <Table
      id={`table-${props.i}`}
      key={`table-${props.i}`}
      head={[table.head, ""]}
      headCols={[1]}
      rows={[
        [
          table.rows.publicationDate,
          [formatDate(props.gazettes.publicationDate)],
        ],
        hideClassNumber(props.type) && [
          table.rows.classification,
          props.gazettes.ipcClassifications
            ? props.gazettes.ipcClassifications.map((a) => a.classNumber)
            : "",
        ],
        hideApplicationNumber(props.type) && [
          table.rows.documentNumber,
          [props.gazettes.applicationNumber],
        ],
        ...props.gazettes.EPClassification.filter(
          (a) => a.category === "PCT"
        ).map((a) => [
          table.rows.epclassification.date,
          [formatDate(a.filingDate)],
        ]),
        ...props.gazettes.EPClassification.filter(
          (a) => a.category === "PCT"
        ).map((a) => [
          table.rows.epclassification.number,
          [a.applicationNumber],
        ]),
        hideApplicationDate(props.type) && [
          table.rows.applicationDate,
          [formatDate(props.gazettes.applicationDate)],
        ],
        hideFilingDate(props.type) && [
          table.rows.filingDate,
          [formatDate(props.gazettes.filingDate)],
        ],
        hideApplicants(props.type) && [
          table.rows.applicants,
          props.gazettes.owners.map((a) => a.fullName || a.companyName),
        ],
        hideInventors(props.type) && [
          table.rows.inventors,
          props.gazettes.inventors
            ? props.gazettes.inventors.map(
                (a, i) =>
                  `${i + 1}. ${a.fullName} ${
                    a.address.country ? a.address.country : ""
                  }`
              )
            : "",
        ],
        hideRepresentatives(props.type) && [
          table.rows.representatives,
          props.gazettes.representatives
            ? props.gazettes.representatives.map(
                (a, i) =>
                  `${a.companyName} ${
                    a.address.country ? a.address.country : ""
                  }`
              )
            : "",
        ],
        hidePatentTitle(props.type) && [
          table.rows.title,
          [props.gazettes.patentTitle?.[0].text],
        ],
        hidePatInfo(props.type) && [
          <a
            href={`${baseUrl}${document.documentElement.lang}/patent/${props.gazettes.applicationNumber}`}
            target="_blank"
            rel="noreferrer"
          >
            {table.patinfo}
          </a>,
          [],
        ],
      ]}
    />
  );
};

export default withRouter(Patent);
