module.exports = {
  trademark: {
    header: "Varumärkestidning",
    title: "Arkiv - Varumärkestidning - Tidningar för industriellt rättsskydd | PRS",
    section: {
      title: "Arkiv",
      lines: [
        "Varumärkestidning kom ut två gånger i månaden till den 15 augusti 2022. Tidningsnummer 22/2014 – 15/2022 hittar du nedan i PDF-format.",
        "Varumärkestidningar till nummer 21/2014 tillhandahålls endast av Patent- och registerstyrelsen i PDF-format. Kontakta vår kundtjänst för att få mer information och kopior av tidningarna.",
        "Innan 2005 kom Varumärkestidning (ISSN 0039-9922) ut endast på papper. Tidningarna är tillgängliga på Patent- och registerstyrelsen och Nationalbiblioteket.",
      ],
    },
  },
  design: {
    header: "Mönsterrättstidning",
    title: "Arkiv - Mönsterrättstidning - Tidningar för industriellt rättsskydd | PRS",
    section: {
      title: "Arkiv",
      lines: [
        "Mönsterrättstidning kom ut en gång i månaden till den augusti månad 2022. Tidningsnummer 02/2016 – 08/2022 hittar du nedan i PDF-format.",  
        "Mönsterrättstidningar till nummer 01/2016 tillhandahålls endast av Patent- och registerstyrelsen i PDF-format. Kontakta vår kundtjänst för att få mer information och kopior av tidningarna.",
        "Innan 2005 kom Mönsterrättstidning (ISSN 0355-4481) ut endast på papper. Tidningarna är tillgängliga på Patent- och registerstyrelsen och Nationalbiblioteket.",
      ],
    },
  },
  common: {
    jump_links:{
      index_title: "Gå tillbaka till arkivet",
      page_title: "Gå tillbaka till början av sidan",
    }
  },
  
};
