import React from "react";
import { withRouter } from "react-router-dom";
import "../../../../scss/styles/app.scss";
import { formatDate } from "../../../../utils/helpers";

import Table from "../../../common/Table";
const baseUrl =
  process.env.REACT_APP_PATENT_URL || "https://patenttitietopalvelu.prh.fi/";

const hideRegisterNumber = (type) =>
  ![
    "patspcfiled",
    "patspcgrantedcertificate",
    "patspccontinuationfiled",
    "patspccontinuationgranted",
    "patspccontinuationrefused",
    "patspccontinuationwithdrawn",
    "patspcrefused",
    "patspclapsed",
  ].includes(type);

const Patent = (props) => {
  const details = props.details;
  const table = details.table;
  return (
    <Table
      id={`table-${props.i}`}
      key={`table-${props.i}`}
      head={[table.head, ""]}
      headCols={[1]}
      rows={[
        [
          table.rows.publicationDate,
          [formatDate(props.gazettes.publicationDate)],
        ],
        hideRegisterNumber(props.type) && [
          table.rows.registrationNumber,
          [props.gazettes.patentNumber],
        ],
        [table.rows.filingDate, [formatDate(props.gazettes.filingDate)]],
        [table.rows.SPCdocumentNumber, [props.gazettes.applicationNumber]],
        [
          table.rows.applicants,
          props.gazettes.owners
            ? props.gazettes.owners.map(
                (a) =>
                  `${
                    a.companyName ? a.companyName : a.fullName ? a.fullName : ""
                  } ${a.address.country ? a.address.country : ""}`
              )
            : "",
        ],
        [
          table.rows.representatives,
          props.gazettes.representatives
            ? props.gazettes.representatives.map(
                (a, i) =>
                  `${a.companyName} ${
                    a.address.country ? a.address.country : ""
                  }`
              )
            : "",
        ],
        [table.rows.SPCtitle, [props.gazettes.productTitle?.text || ""]],
        [
          <a
            href={`${baseUrl}${document.documentElement.lang}/patent/${props.gazettes.applicationNumber}`}
            target="_blank"
            rel="noreferrer"
          >
            {table.patinfo}
          </a>,
          [],
        ],
      ]}
    />
  );
};

export default withRouter(Patent);
