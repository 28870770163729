import React from "react";
import "../../../scss/styles/app.scss";
import SPC from "./publications/SPC";
import Patent from "./publications/Patent";
import EP from "./publications/EP";
import DeclarationsPT from "./publications/DeclarationsPT";

const PatentDetails = (props) => {
  const details = props.content.details;

  return (
    <>
      <p>{details.types[props.type]?.lines}</p>
      {props.gazettes.map((e, i) => {
        switch (props.type.toLowerCase()) {
          case "patspcfiled":
          case "patspcwithdrawn":
          case "patspcrefused":
          case "patspcdismissed":
          case "patspccontinuationwithdrawn":
          case "patspccontinuationgranted":
          case "patspccontinuationfiled":
          case "patspccontinuationrefused":
          case "patspcgrantedcertificate":
          case "patspcvaliditycorrected":
          case "patspcannul":
          case "patspclapsed":
            return <SPC type={props.type} details={details} gazettes={e} i={i} />;
          case "rajoepkaan5":
          case "eprauen":
          case "epkumot":
          case "eptransc1":
          case "eptransc2":
          case "eptransc4":
          case "eptransc3":
          case "eptransc7":
          case "rajoepkaan":
            return <EP type={props.type} details={details} gazettes={e} i={i} />;
          case "tiedan":
            return <DeclarationsPT details={details} gazettes={e} />;
          default:
            return <Patent type={props.type} details={details} gazettes={e} i={i}/>;
        }
        
      })}
    </>
  );
};

export default PatentDetails;
