const Page = {
  header: "Patent Gazettes ",
  description: "",
  title: "Patent Gazettes  - Industrial Property Gazettes | PRH",
  common: {
    week: "Week",
  },
  message_box: {
    info_title: "The search criteria you entered gave no results.",
    danger_title: "The search failed. Please enter your search criteria.",
  },
  search: {
    date_warning: "Check the date",

    lines: [
      `The Patent Gazette includes a summary of the following public notices and notifications:
<ul>
<li>national patents</li>
<li>European patents validated in Finland</li>
<li>supplementary protection certificates (SPC)</li>
</ul>`,
      "We update the details every day.",
      "To search documents from a specific week, select the year of publication and the number of the gazette. You can also select the period freely.",
      "The Patent Gazettes up to number 2/2023 are only available at the Finnish Patent and Registration Office in. Contact our customer service for more information and for copies of these gazettes.",
    ],
    gazette_number: {
      label: "Select the year of publication and the number of the gazette",
      year: "Select the year",
      number: "Select the number",
    },
    date_range: {
      label: "Or search details by date",
      begin: "From",
      end: "To",
      search: "Search",
      description: "Enter date in format dd.mm.yyyy",
    },
    // archive: "Archive (gazettes up to 1 February 2023)",
    archive_url: "https://patent.prh.fi/lehdet/default.asp",
    defaultOption: "-Select-",
    button_search: "Search",
    button_clear: "Clear",
  },
  index: {
    rangeTitle: "Public notice ",
    issueTitle: "Public notice ",
    types: {
      epkaan:
        "European patent applications for which a translation of patent claims, as referred to in section 70 n of the Finnish Patents Act, has been filed",
      epkaanko:
        "Correction of a translation of a European patent, as referred to in section 70 q of the Finnish Patents Act",
      julkpat: "Patent applications available to the public",
      jalvoep: "Reinstated European patents (section 71a))",
      spcjatk:
        "The duration of the supplementary protection certificate has been extended",
      spc: "Filed applications for supplementary protection certificates (SPC)",
      myonspc: "Granted supplementary protection certificates (SPC)",
      pat: "Granted patents",
      kaanep:
        "Translation of a European patent, as referred to in section 70 h of the Finnish Patents Act",
      kaanmmep:
        "Translation of a European patent maintained with amended wording, as referred to in section 70 h of the Finnish Patents Act ",
      rajoepkaan:
        "Translation of a limited European patent, as referred to in section 70 t of the Finnish Patents Act",
      tiedan: "Notifications",
      jatsihyl:
        "Patent applications that have become public, which have been dismissed or have been rejected",
      rajoit: "Limited patents",
      lakan: "Expired patents",
      siirto: "Transferred patents",
      rauen: "Lapsed patents",
      vaitpaat: "Decisions made as a result of the objection",
      vtptkumot: "Revoked patents",
      oikpal: "Reinstated patents (section 71a)",
      expert: "Selected to the list of experts",
      julkpatdis: "Dismissed applications made available to the public",
      julkpatref: "Rejected applications made available to the public",
      julkpatwith: "Withdrawn applications made available to the public",
      patclaimscorrected: "Patentin (vaatimusten) käännös korjattu",
      patannul: "Patents declared invalid by the final decision of a court",
      patexpired: "Expired patents",
      patlimited: "Limited patents",
      pattransferred: "Transferred patents",
      patoppositionamended:
        "Patents that has been kept in force in an amended form",
      patspcwithdrawn:
        "Withdrawn applications for supplementary protection certificates (SPC)",
      patspcfiled:
        "Applications for supplementary protection certificates (SPC)",
      patspcrefused:
        "Rejected applications for supplementary protection certificates (SPC)",
      patspcdismissed:
        "Dismissed applications for supplementary protection certificates (SPC)",
      patspccontinuationwithdrawn:
        "Withdrawn extensions of the terms of supplementary protection certificates (SPC)",
      patspccontinuationgranted:
        "Granted extensions of the terms of supplementary protection certificates (SPC)",
      patspccontinuationfiled:
        "Applications for extensions to the terms of supplementary protection certificates (SPC)",
      patspccontinuationrefused:
        "Rejected applications for extensions to the terms of supplementary protection certificates (SPC)",
      patspcgrantedcertificate:
        "Granted supplementary protection certificates (SPC)",
      patspcvaliditycorrected:
        "Rectifications of the terms of supplementary protection certificates (SPC)",
      patspcannul: "Invalidated supplementary protection certificates (SPC)",
      patspclapsed: "Lapsed supplementary protection certificate",
      rajoepkaan5: "Corrected translations of European patent",
      eprauen: "Expired European patents",
      epkumot: "Revoked European patents",
      eptransc1: "Translation of European patent application claims",
      eptransc2:
        "Corrections of claims of European patent applications transilation",
      eptransc4:
        "Translation of a European patent that has been kept in force in an amended form",
      eptransc3: "Validated European patents",
      eptransc7: "Translation of European patent limited in Finland",
      oppositionrefused: "Opposition refused - patent kept in force",

      ptcorrectedpublications: "Corrected publications",

      ptfilimitedt7: "European patent limited in Finland",
    },
    st: "st",
    link_alt: "Jump to a section on this page",
  },
  details: {
    common: {
      yes: "Yes",
      no: "No",
      image: {
        alt: "Avaa kuva suuremmaksi",
      },
      number: "no.: ",
      jump_links: {
        index_title: "Go back to public notices",
        page_title: "Go back to the top of the page",
      },
      close: "Close",
      imageModal: {
        label: "Suuremman patenttikuvan esikatselu",
      },
    },
    table: {
      head: "Application data",
      rows: {
        classification: "International patent class",
        documentNumber: "Document number",
        SPCdocumentNumber: "Application number",
        applicationNumber: "Application number",
        registrationNumber: "Patent number",
        documentPublicationDate:
          "Date when the application was made available to the public (18 months)",
        publicationDate: "Public notification date",
        applicationDate: "Date of receipt",
        filingDate: "Filing date",
        grantDate: "Registration date",
        applicants: "Applicant(s)",
        inventors: "Inventor(s)",
        representatives: "Agent",
        title: "Title of invention",
        SPCtitle: "Product",
        check: "check",
        epclassification: {
          date: "Publication details of EP application or PCT application",
          number: "Application details of EP application or PCT application",
        },
      },
      patinfo: "Read more in the Patent Information Service",
    },
    types: {
      epkaan: {
        title:
          "European patent applications for which a translation of patent claims, as referred to in section 70 n of the Finnish Patents Act, has been filed",
        lines: [""],
      },
      ptfilimitedt7: {
        title: "European patent limited in Finland",
        lines: [""],
      },
      epkaanko: {
        title:
          "Correction of a translation of a European patent, as referred to in section 70 q of the Finnish Patents Act",
        lines: [""],
      },
      julkpat: {
        title: "Patent applications available to the public",
        lines: [""],
      },
      julkpatref: {
        title: "Rejected pplications made available to the public",
        lines: [""],
      },
      jalvoep: {
        title: "Reinstated European patents (section 71a)",
        lines: [""],
      },
      spcjatk: {
        title:
          "Granted extensions of the terms of supplementary protection certificates (SPC)",
        lines: [""],
      },
      spc: {
        title:
          "Filed applications for supplementary protection certificates (SPC)",
        lines: [""],
      },
      myonspc: {
        title: " Granted supplementary protection certificates (SPC)",
        lines: [""],
      },
      pat: {
        title: "Granted patents",
        lines: [""],
      },
      kaanep: {
        title:
          " Translation of a European patent, as referred to in section 70 h of the Finnish Patents Act",
        lines: [""],
      },
      kaanmmep: {
        title:
          "Translation of a European patent maintained with amended wording, as referred to in section 70 h of the Finnish Patents Act ",
        lines: [""],
      },
      rajoepkaan: {
        title:
          "Translation of a limited European patent, as referred to in section 70 t of the Finnish Patents Act",
        lines: [""],
      },
      tiedan: {
        title: "Notifications",
        lines: [
          "If the notification concerns a decision that you are dissatisfied with, you may appeal it at the Finnish Market Court. Lodge your appeal in writing within 60 days of receiving the notification. The decision and the appeal instructions are available at the Finnish Patent and Registration Office",
        ],
      },
      jatsihyl: {
        title: "Dismissed patent applications available to the public",
        lines: [""],
      },
      rajoit: {
        title: "Limited patents",
        lines: [""],
      },
      lakan: {
        title: "Lakanneet patentit",
        lines: [""],
      },
      siirto: {
        title: "Transferred patents",
        lines: [""],
      },
      rauen: {
        title: "Lapsed patents",
        lines: [""],
      },
      vaitpaat: {
        title: "Väitteen johdosta tehdyt päätökset",
        lines: [""],
      },
      vtptkumot: {
        title: "Patentti on kumottu tai väite hylätty (väitepäätös)",

        lines: [""],
      },
      oikpal: {
        title: "Reinstated patents (section 71a)",
        lines: [""],
      },

      expert: {
        title: "Asiantuntijaluetteloon valitut",
        lines: [""],
      },

      julkpatdis: {
        title: "Dismissed applications made available to the public",
        lines: [""],
      },

      julkpathref: {
        title: "Rejected applications made available to the public",
        lines: [""],
      },

      julkpatwith: {
        title: "Withdrawn applications made available to the public",
        lines: [""],
      },
      patclaimscorrected: {
        title: "Corrected translations of patent claims",
        lines: [""],
      },
      patannul: {
        title: "Patents declared invalid by the final decision of a court",
        lines: [""],
      },
      patexpired: {
        title: "Expired patents",
        lines: [""],
      },
      patlimited: {
        title: "Limited patents",
        lines: [""],
      },
      pattransferred: {
        title: "Transferred patents",
        lines: [""],
      },
      patoppositionamended: {
        title: "Patents that has been kept in force in an amended form",
        lines: [""],
      },
      patspcwithdrawn: {
        title:
          "Withdrawn applications for supplementary protection certificates ",
        lines: [""],
      },
      patspcfiled: {
        title: "Applications for supplementary protection certificates (SPC)",
        lines: [""],
      },
      patspcrefused: {
        title:
          "Rejected applications for supplementary protection certificates (SPC)",
        lines: [""],
      },
      patspcdismissed: {
        title:
          "Dismissed applications for supplementary protection certificates (SPC)",
        lines: [""],
      },
      patspccontinuationwithdrawn: {
        title:
          "Withdrawn extensions of the terms of supplementary protection certificates",
        lines: [""],
      },
      patspccontinuationgranted: {
        title:
          "Granted extensions of the terms of supplementary protection certificates",
        lines: [""],
      },
      patspccontinuationfiled: {
        title:
          "Applications to extend of the terms of supplementary protection certificates",
        lines: [""],
      },
      patspccontinuationrefused: {
        title:
          "Rejected applications for extensions to the terms of supplementary protection certificates (SPC)",
        lines: [""],
      },
      patspcgrantedcertificate: {
        title: "Granted supplementary protection certificates (SPC)",
        lines: [""],
      },
      patspcvaliditycorrected: {
        title:
          "Rectifications of the terms of supplementary protection certificates (SPC)",
        lines: [""],
      },
      patspcannul: {
        title: "Invalidated supplementary protection certificates (SPC)",
        lines: [""],
      },
      patspclapsed: {
        title: "Lapsed supplementary protection certificates (SPC)",
        lines: [""],
      },
      rajoepkaan5: {
        title: "Corrected translations of EP patent",
        lines: [""],
      },
      eprauen: {
        title: "Expired European patents",
        lines: [""],
      },
      epkumot: {
        title: "Revoked European patents",
        lines: [""],
      },
      eptransc1: {
        title: "Translations of patent claims of European patent applications",
        lines: [""],
      },
      eptransc2: {
        title: "Corrected translations of EP patent claims",
        lines: [""],
      },
      eptransc4: {
        title:
          "Translation of a European patent that has been kept in force in an amended form",
        lines: [""],
      },
      eptransc3: {
        title: "Validated European patents",
        lines: [""],
      },
      eptransc7: {
        title: "Translation of European patent limited in Finland",
        lines: [""],
      },
      oppositionrefused: {
        title: "Opposition refused - patent kept in force",
        lines: [""],
      },
      ptcorrectedpublications: {
        title: "Corrected publications",
        lines: [""],
      },
    },
  },
};

export default Page;
