const Page = {
  jumplink: "Hyppylinkki pääsisältöön",
  title: "Teollisoikeuslehdet",
  subtitle: "Patentti- ja rekisterihallitus",
  navigation: {
    home: "Etusivu",
    navbar: [
      { name: "Patenttilehti", link: "/patentgazette", hidden: false },
      {
        name: "Hyödyllisyysmallilehti",
        link: "/utilitymodelgazette",
        hidden: false,
      },
      { name: "Tavaramerkkilehti", link: "/trademarkgazette", hidden: false },
      { name: "Mallioikeuslehti", link: "/designgazette", hidden: false },
    ],
    mainpage: "Etusivu",
    patentgazette: "Patenttilehti",
    umgazette: "Hyödyllisyysmallilehti",
    trademarkgazette: "Tavaramerkkilehti",
    designgazette: "Mallioikeuslehti",
    archive: "Arkisto",
    hide: "Piilota navigaatio",
    show: "Navigaatio",
  },
  footer: {
    title: "Patentti- ja rekisterihallitus",
    address: {
      streetTitle: "Käyntiosoite",
      streetaddress: "Sörnäisten rantatie 13 C, Helsinki",
      postaddressTitle: "Postiosoite",
      postaddress: " 00091 PRH",
    },
    customerService: {
      title: "Asiakaspalvelu",
      openHours: "Ma-pe klo 9.00 - 15.00",
      telephone: "Puh. 029 509 5050",
    },
    trademark: {
      issn: "ISSN 1795-4932 (Verkkolehti)",
      author: "Päätoimittaja: Jorma Lehtonen",
      privacyPolicyLink:
        "https://www.prh.fi/fi/tietoa_prhsta/tietosuoja/henkilotietojen_kasittely/tavaramerkkirekisterin_tietosuojaseloste.html",
      privacyPolicyLink2:
        "https://www.prh.fi/fi/tietoa_prhsta/tietosuoja/henkilotietojen_kasittely/laajalti_tunnetut_tavaramerkit_tietosuojaseloste.html",
      privacyPolicyAlt2: "Näytä tietosuojaseloste (LTM)",
      privacyPolicyTitle2: "Tietosuojaseloste (LTM)",
    },
    um: {
      issn: "ISSN 1796-7775 (Verkkolehti)",
      author: "Päätoimittaja: Jorma Lehtonen",
      privacyPolicyLink:
        "https://www.prh.fi/fi/tietoa_prhsta/tietosuoja/henkilotietojen_kasittely/hyodyllisyysmallirekisterin_tietosuojaseloste.html",
    },
    patent: {
      issn: "ISSN 1796-7775 (Verkkolehti)",
      author: "Päätoimittaja: Jorma Lehtonen",
      privacyPolicyLink:
        "https://www.prh.fi/fi/tietoa_prhsta/tietosuoja/henkilotietojen_kasittely/patenttirekisterin_tietosuojaseloste.html",
    },
    design: {
      issn: "ISSN 1795-4940 (Verkkolehti)",
      author: "Päätoimittaja: Jorma Lehtonen",
      privacyPolicyLink:
        "https://www.prh.fi/fi/tietoa_prhsta/tietosuoja/henkilotietojen_kasittely/mallirekisterin_tietosuojaseloste.html",
    },
    infoTitle: "Lisätietoa",
    privacyPolicyTitle: "Tietosuojaseloste",
    privacyPolicyLink: "#",
    privacyPolicyAlt: "Näytä tietosuojaseloste",
    privacyPolicy: [
      {
        gazette: "patent",
        title: "Tietosuojaseloste (Patentti)",
        link: "https://www.prh.fi/fi/tietoa_prhsta/tietosuoja/henkilotietojen_kasittely/patenttirekisterin_tietosuojaseloste.html",
        show: true,
      },
      {
        gazette: "um",
        title: "Tietosuojaseloste (Hyödyllisyysmalli)",
        link: "https://www.prh.fi/fi/tietoa_prhsta/tietosuoja/henkilotietojen_kasittely/hyodyllisyysmallirekisterin_tietosuojaseloste.html",
        show: true,
      },
      {
        gazette: "trademark",
        title: "Tietosuojaseloste (Tavaramerkki)",
        link: "https://www.prh.fi/fi/tietoa_prhsta/tietosuoja/henkilotietojen_kasittely/tavaramerkkirekisterin_tietosuojaseloste.html",
        show: true,
      },
      {
        gazette: "ltm",
        title: "Tietosuojaseloste (LTM)",
        link: "https://www.prh.fi/fi/tietoa_prhsta/tietosuoja/henkilotietojen_kasittely/laajalti_tunnetut_tavaramerkit_tietosuojaseloste.html",
        show: true,
      },
      {
        gazette: "design",
        title: "Tietosuojaseloste (Mallioikeus)",
        link: "https://www.prh.fi/fi/tietoa_prhsta/tietosuoja/henkilotietojen_kasittely/mallirekisterin_tietosuojaseloste.html",
        show: true,
      },
    ],
    accessibilityLeafletTitle: "Saavutettavuusseloste",
    accessibilityLeafletLink:
      "https://www.prh.fi/saavutettavuusseloste/teollisoikeuslehdet",
    accessibilityLeafletAlt: "Näytä saavutettavuusseloste",
  },
  datepicker: {
    placeholder: "pp.kk.vvvv",
  },
  pagination: {
    title: "Sivutus",
    toPage: "Sivulle numero ",
    toFirstPage: "Ensimmäiselle sivulle",
    toPreviousPage: "Edelliselle sivulle",
    toNextPage: "Seuraavalle sivulle",
    toLastPage: "Viimeiselle sivulle",
    pages: "Sivut",
  },
  tooltip: "Ohjeteksti",
  breadcrumb: "Murupolku",
  close: "Sulje",
  survey: {
    yearly_survey_question: "Vastaa lyhyeen kyselyymme, jotta voimme kehittää palvelujamme.",
    yearly_survey_info: "Vastaaminen kestää muutaman minuutin.",
    yearly_survey_open: "Avaa kysely",
    yearly_survey_link: "https://link.webropolsurveys.com/S/2F29B674CB2003ED",
    yearly_survey_skip: "Ohita"
  }
};
export default Page;
