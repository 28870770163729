import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import LanguageSelect from "./LanguageSelect";
import { Link, useLocation } from "react-router-dom";

import CommonFi from "../../i18n/Common/fi";
import CommonEn from "../../i18n/Common/en";
import CommonSv from "../../i18n/Common/sv";

const NavMenu = (props) => {
  const { langCode } = useContext(I18nContext);
  const contentLangs = { fi: CommonFi, en: CommonEn, sv: CommonSv };
  const content = contentLangs[langCode];

  const location = useLocation();
  return (
    <>

      <LanguageSelect/>
      <div className="container prh-site-header__container prh-site-header__separator">
        <nav className="navbar navbar-dark navbar-expand-lg" aria-label="Päävalikko">
          <div className="navbar-collapse collapse show" id="primary-navigation">
            <ul className="navbar-nav navbar-collapse__primary_menu">
              <li className="nav-item">
                <Link 
                to="/" 
                className={[
                  "nav-link",
                  content.navigation.navbar
                    .map((e) => e.link)
                    .some((i) => location.pathname.indexOf(i) > -1)
                    ? ""
                    : "active",
                ].join(" ")}>
                  {content.navigation.home}
                </Link>
              </li>
            
              {content.navigation.navbar
                .filter((e) => !e.hidden)
                .map((e) => (
                  <li className="nav-item">
                    <Link 
                    to={e.link} 
                    className={[
                      "nav-link",
                      location.pathname.indexOf(e.link) > -1
                        ? "active"
                        : "",
                    ].join(" ")}
                    data-cy={e.name}>
                      {e.name}
                    </Link>
                  </li>
                  
                ))}
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default NavMenu;
