import React, { Fragment, useEffect, useState, useContext } from "react";
import MainPage from "./views/MainPage";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import useLocalStorage from "./customHooks/useLocalStorage";
import UtilitymodelGazette from "./views/UtilitymodelGazette";
import TrademarkGazette from "./views/TrademarkGazette";
import PatentGazette from "./views/PatentGazette";
import DesignGazette from "./views/DesignGazette";
import "bootstrap";
import Archive from "./views/Archive";
import ScrollToTop from "./components/common/ScrollToTop";
import { createBrowserHistory } from "history";
import SurveyModal from "./components/common/SurveyModal";
import { I18nContext } from "./i18n";
require("dotenv").config();

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const App = () => {
  const [activeLink, setActiveLink] = useLocalStorage("activeLink", "Home");
  const [showSurvey, setShowSurvey] = useState(true);
  const { langCode } = useContext(I18nContext);

  const closeSurvey = () => {
    setShowSurvey(false);
  };

  useEffect(() => {
    console.log(langCode);
  });

  const supportedLanguages = ["fi", "sv", "en"];
  return (
    <div className="App">
      {/* <SurveyModal
        topics={["surveys"]}
        language={langCode}
        start={showSurvey}
        setShowSurvey={setShowSurvey}
      /> */}
      <Router history={history} basename={`${process.env.PUBLIC_URL}`}>
        <Fragment>
          <ScrollToTop />
          <Switch>
            <Route
              path="/:lang/patentgazette/"
              render={(props) =>
                supportedLanguages.includes(props.match.params.lang) ? (
                  <PatentGazette
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                  />
                ) : (
                  <Redirect
                    to={`/${props.match.params.lang}/${document.documentElement.lang}`}
                  />
                )
              }
            />
            <Route
              path="/:lang/utilitymodelgazette/"
              render={(props) =>
                supportedLanguages.includes(props.match.params.lang) ? (
                  <UtilitymodelGazette
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                  />
                ) : (
                  <Redirect
                    to={`/${props.match.params.lang}/${document.documentElement.lang}`}
                  />
                )
              }
            />
            <Route
              path="/:lang/trademarkgazette/archive/"
              render={(props) =>
                supportedLanguages.includes(props.match.params.lang) ? (
                  <Archive
                    type="trademark"
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    closeSurvey={closeSurvey}
                    showSurvey={showSurvey}
                  />
                ) : (
                  <Redirect
                    to={`/${props.match.params.lang}/${document.documentElement.lang}`}
                  />
                )
              }
            />
            <Route
              path="/:lang/designgazette/archive/"
              render={(props) =>
                supportedLanguages.includes(props.match.params.lang) ? (
                  <Archive
                    type="design"
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    closeSurvey={closeSurvey}
                    showSurvey={showSurvey}
                  />
                ) : (
                  <Redirect
                    to={`/${props.match.params.lang}/${document.documentElement.lang}`}
                  />
                )
              }
            />
            <Route
              path="/:lang/trademarkgazette/"
              render={(props) =>
                supportedLanguages.includes(props.match.params.lang) ? (
                  <TrademarkGazette
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    closeSurvey={closeSurvey}
                    showSurvey={showSurvey}
                  />
                ) : (
                  <Redirect
                    to={`/${props.match.params.lang}/${document.documentElement.lang}`}
                  />
                )
              }
            />
            <Route
              path="/:lang/designgazette/"
              render={(props) =>
                supportedLanguages.includes(props.match.params.lang) ? (
                  <DesignGazette
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    closeSurvey={closeSurvey}
                    showSurvey={showSurvey}
                  />
                ) : (
                  <Redirect
                    to={`/${props.match.params.lang}/${document.documentElement.lang}`}
                  />
                )
              }
            />
            <Route
              path="/:lang/"
              render={(props) =>
                supportedLanguages.includes(props.match.params.lang) ? (
                  <MainPage
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    closeSurvey={closeSurvey}
                    showSurvey={showSurvey}
                  />
                ) : (
                  <Redirect
                    to={`/${document.documentElement.lang}/${props.match.params.lang}`}
                  />
                )
              }
            />
            <Route
              path="/"
              render={() => (
                <Redirect to={`/${document.documentElement.lang}`} />
              )}
            />
          </Switch>
        </Fragment>
      </Router>
    </div>
  );
};

export default App;
