import React from "react";
import { withRouter } from "react-router-dom";
import CardLinks from "../../common/CardLinks";
import "../../../scss/styles/app.scss";

const DetailsSection = (props) => {
  const details = props.details;
  return (
    <section id={props.type} className="prh-section-borders">
      <h3>
        {details.types[props.type]?.title} ({props.count})
      </h3>

      {props.children}
      <CardLinks type={props.type} content={props.details} />
    </section>
  );
};

export default withRouter(DetailsSection);
