import React from "react";
import { withRouter } from "react-router-dom";
import "../../../scss/styles/app.scss";
import RegistrationsDS from "./publications/RegistrationsDS";
import Corrections from "./publications/CorrectionsDS";

const DesignDetails = (props) => {
  const details = props.content.details;
  return (
    <>
      {details.types[props.type]?.lines.map((line) => (
        <p dangerouslySetInnerHTML={{ __html: line }} />
      ))}
      {props.gazettes.map((e, i) => {
        switch (props.type.toUpperCase()) {
          case "JULK":
            return <RegistrationsDS  type={props.type} details={details} gazettes={e} />;
          case "OIK":
            return <Corrections details={details} gazettes={e} />;
          case "TIED":
            return <RegistrationsDS  type={props.type} details={details} gazettes={e} />;
          default:
            return <RegistrationsDS type={props.type} details={details} gazettes={e} />;
        }
      })}
    </>
  );
};

export default withRouter(DesignDetails);
