import React from "react";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";

const CardLinks = (props) => {
  return (
    <Row>
      <Col>
        <a
          href={process.env.PUBLIC_URL + props.location.pathname + "#tosection"}
        >
          {props.content.common.jump_links.index_title}
        </a>
      </Col>
      <Col>
        <a href={process.env.PUBLIC_URL + props.location.pathname + "#navbar"}>
          {props.content.common.jump_links.page_title}
        </a>
      </Col>
    </Row>
  );
};
export default withRouter(CardLinks);
