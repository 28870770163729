import React from "react";
import Icons from "../../scss/prh-icon-sprite.svg";

const MessageBox = props => {
  const title = props.title;
  const content = props.content;
  const danger = props.danger || false;
  return (
    <div
      role="alert"
      aria-live="polite"
      aria-atomic="true"
      className={"alert alert-dismissible mb-0 mt-5 " + props.className}
    >
      {danger ? 
        (<svg alt="" className="prh-icon prh-icon--orange">
          <use xlinkHref={`${Icons}#alert-circle`}></use>
        </svg>) :
        (<svg alt="" className="prh-icon prh-icon--blue">
        <use xlinkHref={`${Icons}#help-circle`}></use>
        </svg>)}
      <strong className="lead">
        {title}
      </strong>
      {content && (<p className="small">
        {content}
        </p>
      )}
    </div>
  );
};

export default MessageBox;
