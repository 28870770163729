import React from "react";
import { withRouter, Link } from "react-router-dom";
import "../../scss/styles/app.scss";

const ArchiveSection = (props) => {
  return props.content.archive_section ? (
    <section id={props.id} className="prh-section-borders">
      <h2>{props.content.archive_section.title}</h2>
      <p>{props.content.archive_section.description}</p>
      {props.content.archive_section.link ? (
        <a
          rel="noreferrer"
          className="external"
          href={props.content.archive_section.link}
          target="_blank"
        >
          {props.content.archive_section.link_label}
        </a>
      ) : (
        <Link
          className="link-icon link-internal"
          to={props.location.pathname + "/archive"}
        >
          {props.content.archive_section.link_label}
        </Link>
      )}
    </section>
  ) : null;
};

export default withRouter(ArchiveSection);
