import React from "react";
import { formatDate } from "../../../../utils/helpers";

const Declarations = (props) => {
  const gazettes = props.gazettes;

  const inid = props.details.common.inid;
  return (
    <>
      <hr style={{ color: "grey" }} alt={props.details.common.alt_type.notification}/>
      <div className="d-flex flex-column">
        {gazettes && gazettes.commentText && (
          <p style={{ whiteSpace: "pre-wrap" }}>{gazettes.commentText.text}</p>
        )}

        {gazettes?.publicationDate && (
          <p>
            <strong>{inid.publicationDateWithoutInid} </strong>
            {formatDate(gazettes.publicationDate)}
          </p>
        )}
      </div>
    </>
  );
};
export default Declarations;
