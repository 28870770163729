const Page = {
  header: "Patenttilehti",
  description: "",
  title: "Patenttilehti - Teollisoikeuslehdet | PRH",
  common: {
    week: "Viikko",
  },
  message_box: {
    info_title: "Käyttämilläsi hakuehdoilla ei löytynyt tuloksia.",
    danger_title: "Haku ei onnistunut. Anna hakuehdot.",
  },
  search: {
    date_warning: "Tarkista päivämäärä",
    lines: [
      `Näet koosteen seuraavista julkaisuista ja tiedoksiannoista:
<ul>
<li>kansalliset patentit</li>
<li>Suomessa voimaansaatetut eurooppapatentit</li>
<li>lisäsuojatodistukset (SPC)</li>
</ul>`,
      "Tietoja päivitetään joka päivä.",
      "Voit hakea kokonaisen viikon julkaisuja valitsemalla ilmestymisvuoden ja lehden numeron. Voit myös valita aikavälin vapaasti.",
      "Patenttilehti numero 2/2023 ja sitä vanhemmat lehdet ovat saatavilla vain Patentti- ja rekisterihallituksessa. Näistä lehdistä saat tietoja ja kopioita pyytämällä asiakaspalvelustamme.",
    ],
    gazette_number: {
      label: "Valitse ilmestymisvuosi ja lehden numero",
      year: "Valitse vuosi",
      number: "Valitse numero",
    },
    date_range: {
      label: "Tai hae tietoja päivämäärän perusteella",
      begin: "Alkaen",
      end: "Saakka",
      search: "Hae",
      description: "Päivämäärä muodossa pp.kk.vvvv",
    },
    // archive: "Arkisto (Ennen XX.XX julkaisut lehdet)",
    archive_url: "https://patent.prh.fi/lehdet/default.asp",
    defaultOption: "-Valitse-",
    button_search: "Hae",
    button_clear: "Tyhjennä",
  },

  index: {
    rangeTitle: "Kuulutukset välillä ",
    issueTitle: "Kuulutukset ",
    types: {
      epkaan:
        "Eurooppapatenttihakemuksia, joihin on annettu patenttilain 70 n §:n mukainen patenttivaatimusten käännös",
      epkaanko:
        "Eurooppapatenttihakemuksia, joihin on annettu patenttilain 70 q §:n mukainen patenttivaatimusten käännöksen korjaus",
      julkpat: "Julkiseksi tulleet patenttihakemukset",
      jalvoep: "Jälleen voimaansaatetut eurooppapatentit (71a§)",
      spcjatk: "Lisäsuojatodistuksen voimassaoloaikaa on jatkettu",
      spc: "Lisäsuojatodistushakemuksia",
      myonspc: "Myönnettyjä lisäsuojatodistuksia",
      pat: "Myönnetyt patentit",
      kaanep: "Patenttilain 70 h §:n mukainen käännös eurooppapatentista",
      kaanmmep:
        "Patenttilain 70 h §:n mukainen käännös muutetussa muodossa voimassa pidetystä eurooppapatentista",
      rajoepkaan:
        "Patenttilain 70 t §:n mukainen rajoitetun eurooppapatentin käännös",
      tiedan: "Tiedoksiannot",
      jatsihyl:
        "Julkiseksi tulleita patenttihakemuksia, jotka jääneet sillensä tai hylätty",
      rajoit: "Rajoitetut patentit",
      lakan: "Lakanneet patentit",
      siirto: "Siirto",
      rauen: "Rauenneet patentit",
      vaitpaat: "Väitteen johdosta tehdyt päätökset",
      vtptkumot: "Kumotut patentit",
      oikpal: "Oikeuksien palautus (PatL 71 a §)",
      expert: "Asiantuntijaluetteloon valitut",
      julkpatdis: "Julkinen hakemus jäänyt sillensä",
      julkpatref: "Julkiseksi tullut hakemus hylätty",
      julkpatwith: "Julkiseksi tullut hakemus peruutettu",
      patclaimscorrected: "Patentin (vaatimusten) käännös korjattu",
      patannul: "Patentti julistettu mitättömäksi lainvoimaisella tuomiolla",
      patexpired: "Patentti lakannut tai lakkautettu",
      patlimited: "Patentti rajoitettu",
      pattransferred: "Patentti siirretty",
      patoppositionamended:
        "Patentti voimassa muutetussa muodossa (väitepäätös)",
      patspcwithdrawn: "Lisäsuojahakemuksen peruuttaminen",
      patspcfiled: "Lisäsuojahakemus",
      patspcrefused: "Lisäsuojahakemus hylätty",
      patspcdismissed: "Lisäsuojahakemus jäänyt sillensä",
      patspccontinuationwithdrawn:
        "Lisäsuojan voimassaoloajan jatkaminen peruutettu",
      patspccontinuationgranted:
        "Lisäsuojan voimassaoloajan jatkaminen myönnetty",
      patspccontinuationfiled:
        "Lisäsuojan voimassaoloajan jatkamista koskeva hakemus",
      patspccontinuationrefused:
        "Lisäsuojan voimassaoloajan jatkamista koskeva hakemus hylätty",
      patspcgrantedcertificate: "Lisäsuojatodistus myönnetty",
      patspcvaliditycorrected: "Lisäsuojatodistuksen voimassaoloajan oikaisu",
      patspcannul: "Lisäsuojatodistus mitätöity",
      patspclapsed: "Lisäsuojatodistus rauennut",
      rajoepkaan5: "Eurooppapatentin käännöksen korjaus",
      eprauen: "Eurooppapatentin lakkaaminen tai lakkauttaminen",
      epkumot: "Eurooppapatentti kumottu",
      eptransc1: "Eurooppapatenttihakemuksen vaatimusten käännös",
      eptransc2: "Eurooppapatenttihakemuksen vaatimusten käännöksen korjaus",
      eptransc4:
        "Muutetussa muodossa hyväksytyn Eurooppapatentin käännös (väitepäätös)",
      eptransc3: "Eurooppapatentti saatettu voimaan",
      eptransc7: "Suomessa rajoitetun Eurooppapatentin käännös",
      oppositionrefused: "Väite hylätty - patentti pidetty voimassa",
      ptcorrectedpublications: "Korjatut julkaisut",
      ptfilimitedt7: "Suomessa rajoitettu eurooppapatentti",
    },
    st: "kpl",
    link_alt: "Hyppää osioon tällä sivulla",
  },
  details: {
    common: {
      yes: "Kyllä",
      no: "Ei",
      image: {
        alt: "Avaa kuva suuremmaksi",
      },
      number: "nro: ",
      jump_links: {
        index_title: "Palaa kuulutuksiin",
        page_title: "Palaa sivun alkuun",
      },
      close: "Sulje",
      imageModal: {
        label: "Suuremman patenttikuvan esikatselu",
      },
    },
    table: {
      head: "Hakemuksen tiedot",
      rows: {
        classification: "Kansainvälinen patenttiluokka",
        documentNumber: "Asiakirjan numero",
        SPCdocumentNumber: "Hakemuksen numero",
        applicationNumber: "Hakemuksen numero",
        registrationNumber: "Rekisterinro",
        documentPublicationDate: "Hakemuksen julkiseksitulopäivä (18 kk)",
        publicationDate: "Kuulutuspäivä",
        applicationDate: "Hakemuksen saapumispäivä",
        filingDate: "Hakemuksen tekemispäivä",
        grantDate: "Rekisteröintipäivä",
        applicants: "Hakija(t)",
        inventors: "Keksijä(t)",
        representatives: "Asiamies",
        title: "Keksinnön nimitys",
        SPCtitle: "Tuote",
        check: "Katso",
        epclassification: {
          date: "PCT-hakemuksen julkaisu päivämäärä",
          number: "PCT-hakemuksen hakemusnumero",
        },
      },
      patinfo: "Katso lisätietoja patenttitietopalvelusta",
    },
    types: {
      epkaan: {
        title:
          "Eurooppapatenttihakemukset, joihin on annettu patenttilain 70 n §:n mukainen patenttivaatimusten käännös",
        lines: [""],
      },
      epkaanko: {
        title:
          "Eurooppapatenttihakemukset, joihin on annettu patenttilain 70 q §:n mukainen patenttivaatimusten käännöksen korjaus",
        lines: [""],
      },
      julkpat: {
        title: "Julkiseksi tulleita patenttihakemuksia",
        lines: [""],
      },
      julkpatref: {
        title: "Julkiseksi tullut hakemus hylätty",
        lines: [""],
      },
      jalvoep: {
        title: "Jälleen voimaansaatetut eurooppapatentit (patenttilaki 71a§)",
        lines: [""],
      },
      spcjatk: {
        title: "Lisäsuojatodistuksen voimassaoloaikaa on jatkettu",
        lines: [""],
      },
      spc: {
        title: "Lisäsuojatodistushakemukset",
        lines: [""],
      },
      myonspc: {
        title: "Myönnetyt lisäsuojatodistuksia",
        lines: [""],
      },
      pat: {
        title: "Myönnetyt patentit",
        lines: [""],
      },
      kaanep: {
        title: "Eurooppaatenttien käännökset (patenttilaki 70 h §)",
        lines: [""],
      },
      kaanmmep: {
        title:
          "Eurooppapatenttien käännökset eurooppapatenteista, jotka pidetty voimassa muutetussa muodossa (patenttilaki 70 h §)",
        lines: [""],
      },
      rajoepkaan: {
        title:
          "Rajoitettujen eurooppapatenttien käännökset (patenttilaki 70 t §)",
        lines: [""],
      },
      tiedan: {
        title: "Tiedoksiannot",
        lines: [
          "Jos tiedoksianto koskee päätöstä, johon olette tyytymätön, voitte hakea siihen muutosta markkinaoikeudelta kirjallisella valituksella. Valitus on tehtävä 60 päivän kuluessa tiedoksiannon päivämäärästä. Päätös ja valitusosoitus ovat saatavissa Patentti- ja rekisterihallituksesta.",
        ],
      },
      jatsihyl: {
        title:
          "Julkiseksi tulleet patenttihakemuksia, jotka jääneet sillensä tai hylätty",
        lines: [""],
      },
      rajoit: {
        title: "Rajoitetut patentit",
        lines: [""],
      },
      lakan: {
        title: "Lakanneet patentit",
        lines: [""],
      },
      siirto: {
        title: "Siirrot",
        lines: [""],
      },
      rauen: {
        title: "Rauenneet patentit",
        lines: [""],
      },
      vaitpaat: {
        title: "Väitteen johdosta tehdyt päätökset",
        lines: [""],
      },
      vtptkumot: {
        title: "Patentti on kumottu tai väite hylätty (väitepäätös)",

        lines: [""],
      },
      oikpal: {
        title: "Jälleen voimaan saatetut patentit (patenttilaki 71 a §)",
        lines: [""],
      },

      expert: {
        title: "Asiantuntijaluetteloon valitut",
        lines: [""],
      },
      ptfilimitedt7: {
        title: "Suomessa rajoitettu eurooppapatentti",
        lines: [""],
      },

      julkpatdis: {
        title: "Julkinen hakemus jäänyt sillensä",
        lines: [""],
      },

      julkpathref: {
        title: "Julkiseksi tullut hakemus hylätty",
        lines: [""],
      },

      julkpatwith: {
        title: "Julkiseksi tullut hakemus peruutettu",
        lines: [""],
      },
      patclaimscorrected: {
        title: "Patentin (vaatimusten) käännös korjattu",
        lines: [""],
      },
      patannul: {
        title: "Patentti julistettu mitättömäksi lainvoimaisella tuomiolla",
        lines: [""],
      },
      patexpired: {
        title: "Lakanneet tai lakkautetut patentit",
        lines: [""],
      },
      patlimited: {
        title: "Rajoitetut patentit",
        lines: [""],
      },
      pattransferred: {
        title: "Siirrot",
        lines: [""],
      },
      patoppositionamended: {
        title: "Patentti voimassa muutetussa muodossa (väitepäätös)",
        lines: [""],
      },
      patspcwithdrawn: {
        title: "Lisäsuojahakemuksen peruuttaminen",
        lines: [""],
      },
      patspcfiled: {
        title: "Lisäsuojahakemus",
        lines: [""],
      },
      patspcrefused: {
        title: "Lisäsuojahakemus hylätty",
        lines: [""],
      },
      patspcdismissed: {
        title: "Lisäsuojahakemus jäänyt sillensä",
        lines: [""],
      },
      patspccontinuationwithdrawn: {
        title: "Lisäsuojan voimassaoloajan jatkaminen peruutettu",
        lines: [""],
      },
      patspccontinuationgranted: {
        title: "Lisäsuojan voimassaoloajan jatkaminen myönnetty",
        lines: [""],
      },
      patspccontinuationfiled: {
        title: "Lisäsuojan voimassaoloajan jatkamista koskeva hakemus",
        lines: [""],
      },
      patspccontinuationrefused: {
        title: "Lisäsuojan voimassaoloajan jatkamista koskeva hakemus hylätty",
        lines: [""],
      },
      patspcgrantedcertificate: {
        title: "Lisäsuojatodistus myönnetty",
        lines: [""],
      },
      patspcvaliditycorrected: {
        title: "Lisäsuojatodistuksen voimassaoloajan oikaisu",
        lines: [""],
      },
      patspcannul: {
        title: "Lisäsuojatodistus mitätöity",
        lines: [""],
      },
      patspclapsed: {
        title: "Rauenneet lisäsuojatodistukset",
        lines: [""],
      },
      rajoepkaan5: {
        title: "Eurooppapatentin käännöksen korjaus",
        lines: [""],
      },
      eprauen: {
        title: "Eurooppapatentin lakkannut tai lakkautettu",
        lines: [""],
      },
      epkumot: {
        title: "Eurooppapatentti kumottu",
        lines: [""],
      },
      eptransc1: {
        title: "Eurooppapatenttihakemuksen vaatimusten käännös",
        lines: [""],
      },
      eptransc2: {
        title: "Eurooppapatenttihakemuksen vaatimusten käännöksen korjaus",
        lines: [""],
      },
      eptransc4: {
        title:
          "Muutetussa muodossa hyväksytyn Eurooppapatentin käännös (väitepäätös)",
        lines: [""],
      },
      eptransc3: {
        title: "Eurooppapatentti saatettu voimaan",
        lines: [""],
      },
      eptransc7: {
        title: "Suomessa rajoitetun Eurooppapatentin käännös",
        lines: [""],
      },
      oppositionrefused: {
        title: "Väite hylätty - patentti pidetty voimassa",
        lines: [""],
      },
      ptcorrectedpublications: {
        title: "Korjatut julkaisut",
        lines: [""],
      },
    },
  },
};

export default Page;
