import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { I18nContext } from "../../i18n";
import CommonFi from "../../i18n/Common/fi";
import CommonEn from "../../i18n/Common/en";
import CommonSv from "../../i18n/Common/sv";

const LanguageSelect = (props) => {
  const { dispatch, langCode } = useContext(I18nContext);
  const contentLangs = { fi: CommonFi, en: CommonEn, sv: CommonSv };
  const content = contentLangs[langCode];

  const onLanguageSelect = (a) => {
    const e = a.target.lang;
    document.documentElement.lang = e;
    dispatch({ type: "setLanguage", payload: e });
    const pathAsArray = props.location.pathname.split("/");
    const langIndex = pathAsArray.indexOf(props.match.params.lang);
    if (langIndex !== -1) {
      pathAsArray[langIndex] = e;
    }
    const newPath = pathAsArray.join("/");
    props.history.push({
      pathname: newPath,
    });
  };

  return (
    <nav className="navbar navbar-dark navbar-expand-lg" aria-label="Päävalikko">
      <div className="navbar-collapse collapse show" id="secondary-navigation">
        <div className="navbar-nav prh-site-header__main-navigation">
          <div className="prh-collapse-header">
            <span>
              <span className="sub-title">{content.subtitle}</span>
              {content.title}
            </span>
          </div>
          {langCode !== "fi" && (
              <button
                lang="fi"
                data-value="Suomeksi"
                target="_self"
                id="option-0"
                className="btn nav-link"
                data-cy="fi"
                onClick={onLanguageSelect}
                eventKey="fi"
              >
                Suomeksi
              </button>
          )}
          {langCode !== "sv" && (
              <button
                lang="sv"
                data-value="På svenska"
                target="_self"
                id="option-1"
                className="btn nav-link"
                data-cy="sv"

                onClick={onLanguageSelect}
                eventKey="sv"
              >
                På svenska
              </button>
          )}
          {langCode !== "en" && (
              <button
                lang="en"
                data-value="In English"
                target="_self"
                id="option-2"
                className="btn nav-link"
                data-cy="en"
                onClick={onLanguageSelect}
                eventKey="en"
              >
                In English
              </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default withRouter(LanguageSelect);
