const Page = {
  header: "Industrial Property Gazettes",
  title: "Industrial Property Gazettes | PRH",
  description:
    "Details on the public notices and documents of the industrial property rights that the PRH has processed. We update the details every day.",
  content: {
    title: null,
    lines: [],
    sections: [
      {
        hidden: false,
        border: true,
        title: {
          url: "/patentgazette",
          text: "Patent Gazette",
        },
        lines: [
          `The Patent Gazette includes a summary of the following public notices and notifications:
          <ul>
          <li>National patents</li>
          <li>European patents validated in Finland.</li>
          <li>supplementary protection certificates (SPC)</li>

          </ul>`,
        ],
      },
      {
        hidden: false,
        border: true,
        title: {
          url: "/utilitymodelgazette",
          text: "Utility Model Gazette",
        },
        lines: [
          `The Utility Model Gazette includes a summary of the public notices and notifications related to utility models.`,
        ],
      },
      {
        hidden: false,
        border: true,
        title: {
          url: "/trademarkgazette",
          text: "Trademark Gazette",
        },
        archive: {
          description:
            "In the archive, you will find the documents given before 16 August 2022. ",
          link_label: "Go to the archive",
        },
        lines: [
          `The Trademark Gazette includes a summary of the following documents and notifications:
<ul>
<li>National trademarks</li>
<li>International trademark registrations valid in Finland</li>
<li>Trademarks with a reputation.</li>
</ul>`,
        ],
      },
      {
        hidden: false,
        border: true,
        title: {
          url: "/designgazette",
          text: "Design Gazette",
        },
        archive: {
          description:
            "In the archive, you will find the public notices given before August 16th 2022. ",
          link_label: "Go to the archive",
        },
        lines: [
          `The Design Gazette includes a summary of the following public notices and notifications:

      <ul>
<li>National design rights</li>
<li>International design registrations valid in Finland.</li>
</ul>`,
        ],
      },
    ],
  },
};

export default Page;
