const Page = {
  header: "Varumärkestidning",
  description: "",
  title: "Varumärkestidning - Tidningar för industriellt rättsskydd | PRS",
  common: {
    week: "Vecka",
  },
  message_box: {
    info_title: "Inga resultat hittades med dina sökvillkor.",
    danger_title: "Sökningen misslyckades. Ange sökvillkor.",
  },
  search: {
    date_warning: "Kontrollera datum",
    lines: [
      `Tidningen innehåller ett sammandrag av följande publikationer och delgivningar:
<ul>
<li>nationella varumärken</li>
<li>internationella varumärkesregistreringar som gäller i Finland</li>
<li>väl kända varumärken. </li>
</ul>`,
      "Vi uppdaterar uppgifterna varje dag.",
      "Du kan söka publikationer för en hel vecka genom att välja utgivningsår och tidningsnummer. Du kan också välja perioden fritt.",
    ],
    gazette_number: {
      label: "Välj utgivningsår och tidningsnummer",
      year: "Välj år",
      number: "Välj nummer",
    },
    date_range: {
      label: "Eller sök uppgifter efter datum",
      begin: "Från och med",
      end: "Till och med",
      search: "Sök",
      description: "Skriv datumet enligt formatet dd.mm.åååå",
    },
    archive: "Arkiv (publikationer som gjorts före 16 augusti 2022)",
    defaultOption: "-Välj-",
    button_search: "Sök",
    button_clear: "Rensa",
  },
  // archive_section: {
  //   title: "Arkiv",
  //   description:
  //     "I arkivet hittar du publikationer som gjorts före 16 augusti 2022.",
  //   link_label: "Gå till tidningsnummer 22/2014 – 15/2022",
  // },
  index: {
    rangeTitle: "Publikationer ",
    issueTitle: "Publikationer ",
    types: {
      julk: "Offentliggjorda registreringar",
      oik: "Rättelser",
      tied: "Delgivningar",
      ltm: "Varumärken som har tagits upp i förteckningen över väl kända varumärken",
    },
    st: "st.",
    link_alt: "Hoppa till ett avsnitt på den här sidan",
  },
  details: {
    common: {
      yes: "Ja",
      no: "Nej",
      image: {
        alt: "Öppna en större bild",
      },
      number: "nr: ",
      jump_links: {
        index_title: "Gå tillbaka till publikationer",
        page_title: "Gå tillbaka till början av sidan",
      },
      alt_type: {
        registration: "Offentliggjord registrering",
        correction: "Rättelse",
        notification: "Delgivning",
        wellknowntrademark: "Väl känt varumärke",
      },
      inid: {
        registrationNumber: "(111) Registernummer",
        registrationDate: "(151) Registreringsdatum",
        applicationNumber: "(210) Ansökningsnummer",
        applicationDate: "(220) Ansökningsdatum",
        priorities: {
          applicationNumber: "(310) Prioritet: ansökningsnummer",
          filingDate: "(320) Prioritet: ansökningsdatum",
          countryCode: "(330) Prioritet: land",
          partial: "(340) Delprioritet",
        },
        markImage: "(540) Varumärke",
        markSound: "(556) Ljudmärke",
        markMultimedia: "(554) Tredimensionellt märke",
        markTypeColour: "(558) Färgmärke",
        description: "(571) Märkesbeskrivning",
        disclaimers: "(526) Disclaimer",
        colourDescriptions: "(591) Färgbeskrivning",
        applicants: "(730) Innehavare",
        representatives: "(740) Ombud",
        classifications: "(511) Varu- och/eller tjänsteklass",
        publicationDate: "(450) Datum för offentliggörande",
        publicationDateWithoutInid: "Datum för offentliggörande",
        oppositionPeriodStartDate: "Invändningstiden börjar",
        oppositionPeriodEndDate: "Invändningstiden går ut",
        markKind: "(551) Kollektiv- eller kontrollmärke",
        markKindMark: {
          Collective: "Kollektivmärke",
          Guarantee: "Kontrollmärke",
        },
      },
      close: "Stäng",
      imageModal: {
        label: "Förhandsgranskning av större bild av varumärket",
      },
      videoModal: {
        label: "Visa video av varumärket",
      },
      playSound: {
        label: "Spela ljud av varumärket",
      },
      trademarkImage: "Bild av varumärket",
    },

    types: {
      julk: {
        check: "",
        trademarkDB: "Se mer information i informationstjänsten för varumärken",
        title: "Offentliggjorda registreringar",
        lines: [
          "Patent- och registerstyrelsen (PRS) har enligt varumärkeslagen registrerat följande varumärken och offentliggör följande internationella registreringar.",

          "En invändning mot en registrering måste göras skriftligen till PRS inom två månader från offentliggörandet av registreringen. Läs mer på <a href='https://www.prh.fi/sv/varumarken/varumarkestvister/invandning_mot_varumarke.html'class='external' target='_blank'> prh.fi</a> om att lämna in en invändning ",
        ],
      },
      oik: {
        title: "Rättelser",
        lines: [""],
      },
      tied: {
        title: "Delgivningar",
        lines: [""],
      },
      ltm: {
        title:
          "Varumärken som har tagits upp i förteckningen över väl kända varumärken",
        lines: [
          "Patent- och registerstyrelsen offentliggör följande varumärken som har tagits upp i förteckningen över väl kända varumärken.",

          "Du kan inte lämna in en invändning mot ett varumärke som har tagits upp i förteckningen. Vem som helst kan begära att ett varumärke ska strykas från förteckningen. Läs mer på <a href='https://www.prh.fi/sv/varumarken/val_kanda_varumarken.html' class='external' target='_blank'> prh.fi </a>om strykning av ett märke",
        ],
        tableHeaders: {
          class: "Klass",
          tableHead: "Uppgifter om varumärken",
          applicationNumber: "Märkets nummer",
          registrationDate: "Datum för godkännande",
          expirationDate: "Giltighet upphör",
          applicationDate: "Ansökningsdatum",
          trademark: "Varumärke",
          markDescription: "Märkesbeskrivning",
          colourDescriptions: "Färgbeskrivning",
          applicant: "Sökande",
          representative: "Ombud",
          targetGroup: "Målgrupp",
          targetGroupNo: "Ej begränsad",
          goodsAndServices: "Varor/tjänster",
          publicationDate: "Datum för offentliggörande",
        },
      },
    },
  },
};

export default Page;
