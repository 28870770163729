module.exports = {
  trademark: {
    header: "Trademark Gazette",
    title: "Archive - Trademark Gazette - Industrial Property Gazettes | PRH",
    section: {
      title: "Archive",
      lines: [
        "Until 15 August 2022, the Trademark Gazette was published twice a month. You will find issues 22/2014 – 15/2022 of the Gazette in PDF format below.",
        "The Trademark Gazettes up to number 21/2014 are only available at the Finnish Patent and Registration Office in PDF format. Contact our customer service for more information and for copies of these gazettes.",
        "Before 2005, the Trademark Gazette (ISSN 0039-9922) was only issued on paper. The Gazettes are available at the Finnish Patent and Registration Office and at the National Library of Finland.",
      ],
    },
  },
  design: {
    header: "Design Gazette",
    title: "Archive - Design Gazette - Industrial Property Gazettes | PRH",
    section: {
      title: "Archive",
      lines: [
        "Until 15 August 2022, the Design Gazette was issued once a month until. You will find issues 02/2016 – 08/2022 of the Gazette in PDF format below.",  
        "The Design Gazettes up to issue 01/2016 are only available at the Finnish Patent and Registration Office in PDF format. Contact our customer service for more information and for copies of these gazettes.",
        "Before 2005, the Design Gazette (ISSN 0355-4481) was only issued on paper. The Gazettes are available at the Finnish Patent and Registration Office and at the National Library of Finland.",
      ],
    },
  },
  common: {
    jump_links:{
      index_title: "Go back to the archive",
      page_title: "Go back to the top of the page",
    }
  },
  
};
