const PageFi = {
  header: "Design Gazette",
  description: "",
  title: "Design Gazette - Industrial Property Gazettes | PRH",
  common: {
    week: "Week",
  },
  message_box: {
    info_title: "The search criteria you entered gave no results.",
    danger_title: "The search failed. Please enter your search criteria.",
  },
  search: {
    date_warning: "Check the date",
    lines: [
      `The Design Gazette includes a summary of the following public notices and notifications:
      <ul>
<li>National design rights</li>
<li>International design registrations valid in Finland</li>
</ul>`,
      "We update the details every day.",
      "To search documents from a specific week, select the year of publication and the number of the gazette. You can also select the period freely.",
    ],
    gazette_number: {
      label: "Select the year of publication and the number of the gazette",
      year: "Select year",
      number: "Select number",
    },
    date_range: {
      label: "Or search details by date",
      begin: "From",
      end: "To",
      search: "Search",
      description: "Enter date in format dd.mm.yyyy",
    },
    archive: "Archive (public notices given before 16 August 2022)",
    defaultOption: "-Select-",
    button_search: "Search",
    button_clear: "Clear",
  },
  // archive_section: {
  //   title: "Archive",
  //   description:
  //     "In the archive, you will find the public notices given before 16 August 2022.",
  //   link_label: "Go to issues 02/2016 – 08/2022",
  // },
  index: {
    rangeTitle: "Public notices ",
    issueTitle: "Public notices ",
    types: {
      julk: "Published design registrations",
      oik: "Corrections",
      tied: "Notifications",
      rekmm: "Amendments of registered designs",
      rekuu: "Renewals of registration",
      uudjatpe: "On the grounds of not renewing the registration",
      halpyy: "At the request of the owner",
      suoalsu:
        "Limitations to the scopes of protection related to design registrations",
      siirto: "Transfers of design rights",
      katlup: "Licences",
      kvrekeisu: "International registrations no longer valid in Finland",
      uudkvrek: "Renewed international registrations",
      poisrek: "Registrations removed",
    },
    st: "st",
    link_alt: "Jump to a section on this page",
  },
  details: {
    common: {
      yes: "Yes",
      no: "No",
      image: {
        alt: "Click on image to enlarge",
      },
      number: "no.: ",
      jump_links: {
        index_title: "Go back to public notices",
        page_title: "Go back to the top of the page",
      },
      alt_type: {
        julk: "Published registration",
        oik: "Correction",
        tied: "Notification",
        rekmm: "Amendment of registered design",
        rekuu: "Renewed registration",
        suoalsu:
          "Limitation of the scope of protection of a design registration",
        siirto: "Transfer of design right",
        katlup: "Licence",
        kvrekeisu: "International registration no longer valid in Finland",
        uudkvrek: "Renewed international registration",
        poisrek: "Removed registration",
      },
      bw: "Black-and-white",
      colour: "Coloured",
      enlarged: "Enlargement of detail",
      close: "Close",
      imageModal: {
        label: "Preview of larger design image",
      },
    },

    types: {
      julk: {
        title: "Published design registrations",
        check: "View",
        designDB: "Design Information Service",
        lines: [
          "In accordance with the Finnish Registered Designs Act, the Finnish Patent and Registration Office has registered the following designs and will publish the following international design registrations.",

          "The opposition period of two months begins on the publication date of the registration. Read more on <a href='https://www.prh.fi/designs_opposition'class='external' target='_blank'>prh.fi</a> about filing an opposition",
        ],
        inid: {
          registrationNumber: "(11) Registration number",
          registrationDate: "(15) Registration date",
          expiryDate: "(18) After renewal valid until",
          applicationNumber: "(21) Application number",
          applicationDate: "(22) Application date",
          sampleProvided: "(29) Specimen submitted",
          priorities: "(30) Priority ",
          publicationDate: "(45) Publication date",
          publicationDateWithoutInid: "Publication date",
          classification: "(51) Classification",
          productTitle: "(54) Indication of the product",
          images: "(57) Images",
          designers: "(72) Designer ",
          applicants: "(73) Holder",
          newHolder: "(73) New owner",
          representatives: "(74) Representative ",
          relatedApplication: "(66) Variant registration",
          licence: "(79) Licensee",
          oppositionPeriodStart: "Opposition period begins",
          oppositionPeriodEnd: "Opposition period ends",
          transferDate: "Design right transferred",
          designReferenceTitle: "View",
          designReferenceLink: "Read more in the Design Information Service",
          designReferenceLinkWipo: "Read more in the WIPO information service",
        },
      },
      oik: {
        title: "Corrections",
        lines: [""],
      },
      tied: {
        title: "Notifications",
        lines: [""],
      },
      rekmm: {
        title: "Amendments of registered designs",
        lines: [""],
      },
      rekuu: {
        title: "Renewals of registration",
        lines: [""],
      },
      uudjatpe: {
        title: "On the grounds of not renewing the registration",
        lines: [""],
      },
      halpyy: {
        title: "At the request of the owner",
        lines: [""],
      },
      suoalsu: {
        title:
          "Limitations to the scopes of protection related to design registrations",
        lines: [""],
      },
      siirto: {
        title: "Transfers of design rights",
        lines: [""],
      },
      katlup: {
        title: "Licences",
        lines: [""],
      },
      kvrekeisu: {
        title: "International registrations no longer valid in Finland",
        lines: [""],
      },
      uudkvrek: {
        title: "Renewed international registrations",
        lines: [""],
      },
      poisrek: {
        title: "Registrations removed",
        lines: [""],
      },
    },
  },
};
export default PageFi;
