import React, { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import Icons from "../../../../scss/prh-icon-sprite.svg";
import { baseUrl, formatDate } from "../../../../utils/helpers";
import { I18nContext } from "../../../../i18n/index";

const Corrections = ({ gazettes, details, gazetteType }) => {
  const inid = details.common.inid;
  const showAll = gazettes.applicationDate ? true : false;
  const [showVideo, setShowVideo] = useState(false);
  const langPosition = { fi: 0, sv: 1, en: 0 };

  const { langCode } = useContext(I18nContext);
  const handleClose = () => {
    setShowModal(false);
  };

  const toggleShowVideo = () => {
    setShowVideo(!showVideo);
  };

  const [showModal, setShowModal] = useState(false);
  const [imgUrl, setImgUrl] = useState("");

  const handleOpen = (url) => {
    setImgUrl(url);
    setShowModal(true);
  };

  const imageModal = (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="lg"
      aria-label={details.common.imageModal.label}
    >
      <Modal.Header
        closeButton
        closeLabel={details.common.close}
      ></Modal.Header>
      <Modal.Body>
        <img
          data-cy="image"
          className="img-fluid pb-4"
          src={imgUrl}
          alt={details.common.imageModal.label}
        />
      </Modal.Body>
    </Modal>
  );
  const trademarkImage = (url) => {
    return (
      <span
        className="p-0"
        onClick={() => handleOpen(baseUrl + url)}
      >
        <img
          style={{ border: "none", width: "30%" }}
          data-cy="image"
          className="img-fluid"
          src={baseUrl + gazettes.markImage}
          alt={gazettes.markWord}
        />
      </span>
    );
  };

  const videoElement = (
    <p className="ml-5 pt-2">
      <video
        controls
        src={gazettes.markMultimedia}
        style={{ maxWidth: "100%" }}
      ></video>
    </p>
  );
  const videoButton = (
    <button
      data-cy="videoButton"
      onClick={toggleShowVideo}
      className="btn-icon pl-2 pr-2"
      style={{ border: "none", backgroundColor: "white" }}
    >
      <svg
        alt={details.common.videoModal.label}
        className="prh-icon prh-video-audio-icon"
      >
        <use xlinkHref={`${Icons}#video`}></use>
      </svg>
    </button>
  );

  const audioElement = (
    <p className="ml-5 pt-2">
      <audio controls src={gazettes.markSound}></audio>
    </p>
  );

  return (
    <>
      {imageModal}
      {gazettes && (
        <>
          <hr alt={details.common.alt_type.correction} />
          <div className="d-flex flex-column">
            {gazettes && gazettes.commentText && (
              <p style={{ whiteSpace: "pre-wrap" }}>
                {gazettes.commentText.text}
              </p>
            )}
            {gazettes.publicationDate && (
              <p style={{ marginBottom: 0 }}>
                <strong>{inid.publicationDate}</strong>{" "}
                {formatDate(gazettes.publicationDate)}
              </p>
            )}
            {gazettes.oppositionPeriodStartDate && (
              <p style={{ marginBottom: 0 }}>
                <strong>{inid.oppositionPeriodStartDate}</strong>{" "}
                {formatDate(gazettes.oppositionPeriodStartDate)}
              </p>
            )}
            {gazettes.oppositionPeriodEndDate && (
              <p style={{ marginBottom: 0 }}>
                <strong>{inid.oppositionPeriodEndDate}</strong>{" "}
                {formatDate(gazettes.oppositionPeriodEndDate)}
              </p>
            )}
            {showAll && (
              <>
                {gazettes.registrationNumber && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.registrationNumber}</strong>{" "}
                    {gazettes.registrationNumber}
                  </p>
                )}
                {gazettes.registrationDate && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.registrationDate}</strong>{" "}
                    {formatDate(gazettes.registrationDate)}
                  </p>
                )}
                {gazettes.applicationNumber && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.applicationNumber}</strong>{" "}
                    {gazettes.applicationNumber}
                  </p>
                )}
                {gazettes.applicationDate && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.applicationDate}</strong>{" "}
                    {formatDate(gazettes.applicationDate)}
                  </p>
                )}
                {gazettes.priorities &&
                  gazettes.priorities.map((a) => (
                    <>
                      <p style={{ marginBottom: 0 }}>
                        <strong>{inid.priorities.applicationNumber}</strong>{" "}
                        {a.applicationNumber}
                      </p>
                      <p style={{ marginBottom: 0 }}>
                        <strong>{inid.priorities.filingDate}</strong>{" "}
                        {formatDate(a.filingDate)}
                      </p>
                      <p style={{ marginBottom: 0 }}>
                        <strong>{inid.priorities.countryCode}</strong>{" "}
                        {a.countryCode}
                      </p>
                      {a.partial && (
                        <p style={{ marginBottom: 0 }}>
                          <strong>{inid.priorities.partial}</strong>{" "}
                        </p>
                      )}
                    </>
                  ))}
                {(gazettes.markImage ||
                  gazettes.markSound ||
                  gazettes.markMultimedia) && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.markImage}</strong>{" "}
                    {gazettes.markImage
                      ? trademarkImage(gazettes.markImage)
                      : ""}
                    {gazettes.markSound ? audioElement : ""}
                    {gazettes.markMultimedia ? videoButton : ""}
                    {showVideo && videoElement}
                  </p>
                )}
                {gazettes.markType === "Sound" && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.markSound}</strong>
                  </p>
                )}

                {gazettes.descriptions.length > 0 && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.description}</strong>{" "}
                    {gazettes.descriptions.filter(
                      (e) => e.language === langCode
                    ).length === 0
                      ? gazettes.descriptions.map((e) => e.text).join(", ")
                      : gazettes.descriptions.filter(
                          (e) => e.language === langCode
                        )[0].text}
                  </p>
                )}
                {gazettes.disclaimers.length > 0 && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.disclaimers}</strong>{" "}
                    {gazettes.disclaimers.filter((e) => e.language === langCode)
                      .length === 0
                      ? gazettes.disclaimers.map((e) => e.text).join(", ")
                      : gazettes.disclaimers.filter(
                          (e) => e.language === langCode
                        )[0].text}
                  </p>
                )}
                {gazettes.markType !== "Combined" && gazettes.markWord && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.markImage}</strong> {gazettes.markWord}
                  </p>
                )}
                {(gazettes.markType === "Three dimensional" ||
                  (gazettes.markType === "Combined" &&
                    gazetteType !== "trademark")) && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.markMultimedia}</strong>
                  </p>
                )}
                {gazettes.markType === "Colour" && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.markTypeColour}</strong>
                  </p>
                )}
                {gazettes.colourDescriptions.length > 0 && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.colourDescriptions}</strong>{" "}
                    {gazettes.colourDescriptions.filter(
                      (e) => e.language === langCode
                    ).length === 0
                      ? gazettes.colourDescriptions
                          .map((e) => e.text)
                          .join(", ")
                      : gazettes.colourDescriptions
                          .filter((e) => e.language === langCode)
                          .map((e) => e.text)
                          .join(", ")}
                  </p>
                )}

                {gazettes.applicants && gazettes.applicants.length > 0 && (
                  <p style={{ marginBottom: 0 }}>
                    <strong>{inid.applicants}</strong>{" "}
                    {gazettes.applicants
                      .map((e) => {
                        return `${e.fullName ? e.fullName : e.companyName}${
                          e.address.cityName
                            ? Array.isArray(e.address.cityName)
                              ? ", " +
                                e.address.cityName[langPosition[langCode]].text
                              : e.address.cityName && ", " + e.address.cityName
                            : ""
                        }${e.address.country && ", " + e.address.country}`;
                      })
                      .join(", ")}
                  </p>
                )}
                {gazettes.representatives &&
                  gazettes.representatives.length > 0 && (
                    <p style={{ marginBottom: 0 }}>
                      <strong>{inid.representatives}</strong>{" "}
                      {gazettes.representatives.map((e) => {
                        return `${e.fullName ? e.fullName : e.companyName}${
                          e.address.cityName
                            ? Array.isArray(e.address.cityName)
                              ? ", " +
                                e.address.cityName[langPosition[langCode]].text
                              : e.address.cityName && ", " + e.address.cityName
                            : ""
                        }${e.address.country && ", " + e.address.country}
                        `;
                      })}
                    </p>
                  )}
                {gazettes.classifications &&
                  gazettes.classifications.length > 0 && (
                    <>
                      <p style={{ marginBottom: 0 }}>
                        <strong>{inid.classifications}</strong>{" "}
                        {[
                          ...new Set(
                            gazettes.classifications.map((e) => e.classNumber)
                          ),
                        ].join(", ")}
                      </p>
                      <p style={{ marginBottom: 0 }}>
                        NCL({gazettes.classifications[0].version})
                      </p>
                    </>
                  )}
                {gazettes.applicationNumber && gazettes.registrationNumber && (
                  <p>
                    <a
                      target="_blank"
                      className="external"
                      href={`${process.env.REACT_APP_TRADEMARK_URL}${document.documentElement.lang}/trademark/${gazettes.applicationNumber}/${gazettes.registrationNumber}`}
                      rel="noreferrer"
                    >
                      {details.types.julk.trademarkDB}
                    </a>
                  </p>
                )}
              </>
            )}
            <p style={{ marginBottom: 20 }}></p>
          </div>
        </>
      )}
    </>
  );
};
export default Corrections;
