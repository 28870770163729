const PageFi = {
  header: "Mallioikeuslehti",
  description: "",
  title: "Mallioikeuslehti - Teollisoikeuslehdet | PRH",
  common: {
    week: "Viikko",
  },
  message_box: {
    info_title: "Käyttämilläsi hakuehdoilla ei löytynyt tuloksia.",
    danger_title: "Haku ei onnistunut. Anna hakuehdot.",
  },
  search: {
    date_warning: "Tarkista päivämäärä",
    lines: [
      `Näet koosteen seuraavista kuulutuksista ja tiedoksiannoista:
      <ul>
<li>kansalliset mallioikeudet</li>
<li>Suomessa voimassa olevat kansainväliset mallioikeusrekisteröinnit.</li>
</ul>`,
      "Tietoja päivitetään joka päivä.",
      "Voit hakea kokonaisen viikon julkaisuja valitsemalla ilmestymisvuoden ja lehden numeron. Voit myös valita aikavälin vapaasti.",
    ],
    gazette_number: {
      label: "Valitse ilmestymisvuosi ja lehden numero",
      year: "Valitse vuosi",
      number: "Valitse numero",
    },
    date_range: {
      label: "Tai hae tietoja päivämäärän perusteella",
      begin: "Alkaen",
      end: "Saakka",
      search: "Hae",
      description: "Päivämäärä muodossa pp.kk.vvvv",
    },
    archive: "Arkisto (ennen 16.8.2022 annetut kuulutukset)",
    defaultOption: "-Valitse-",
    button_search: "Hae",
    button_clear: "Tyhjennä",
  },
  // archive_section: {
  //   title: "Arkisto",
  //   description: "Arkistosta löydät ennen 16.8.2022 annetut kuulutukset.",
  //   link_label: "Arkisto (ennen 08/2022 julkaistut lehdet)",
  // },
  index: {
    rangeTitle: "Kuulutukset välillä ",
    issueTitle: "Kuulutukset ",
    types: {
      julk: "Kuulutetut mallirekisteröinnit",
      oik: "Oikaisut",
      tied: "Tiedoksiannot",
      rekmm: "Rekisteröidyn mallin muutos",
      rekuu: "Rekisteröinnin uudistamiset",
      uudjatpe: "Uudistamatta jättämisen perusteella",
      halpyy: "Haltijan pyynnöstä",
      suoalsu: "Mallirekisteröintejä koskevat suoja-alojen supistukset",
      siirto: "Mallioikeuden siirrot",
      katlup: "Käyttölupa",
      kvrekeisu:
        "Kansainväliset rekisteröinnit, jotka eivät enää voimassa Suomessa",
      uudkvrek: "Uudistetut kansainväliset rekisteröinnit",
      poisrek: "Poistetut rekisteröinnit",
    },
    st: "kpl",
    link_alt: "Hyppää osioon tällä sivulla",
  },
  details: {
    common: {
      yes: "Kyllä",
      no: "Ei",
      image: {
        alt: "Avaa kuva suuremmaksi",
      },
      number: "nro: ",
      jump_links: {
        index_title: "Palaa kuulutuksiin",
        page_title: "Palaa sivun alkuun",
      },
      alt_type: {
        julk: "Kuulutettu rekisteröinti",
        oik: "Oikaisu",
        tied: "Tiedoksianto",
        rekmm: "Rekisteröidyn mallin muutos",
        rekuu: "Uudistettu rekisteröinti",
        suoalsu: "Mallirekisteröinnin suoja-alan supistus",
        siirto: "Mallioikeuden siirto",
        katlup: "Käyttölupa",
        kvrekeisu:
          "Kansainvälinen rekisteröinti, joka ei enää voimassa Suomessa",
        uudkvrek: "Uudistettu kansainvälinen rekisteröinti",
        poisrek: "Poistettu rekisteröinti",
      },
      bw: "Mustavalkoinen",
      colour: "Värillinen",
      enlarged: "Yksityiskohdan suurennos",
      close: "Sulje",
      imageModal: {
        label: "Suuremman mallioikeuskuvan esikatselu",
      },
    },
    types: {
      julk: {
        title: "Kuulutetut mallirekisteröinnit",
        check: "Katso",
        designDB: "Mallioikeustietopalvelu",
        lines: [
          "Mallioikeuslain mukaisesti Patentti- ja rekisterihallitus on rekisteröinyt seuraavat mallit ja kuuluttaa seuraavat kansainväliset mallirekisteröinnit.",

          "Rekisteröinnin kuulutuspäivästä alkaa kahden kuukauden väiteaika. Lue ohjeet väitteen tekemiseen <a href='https://www.prh.fi/mallioikeus_vaite' class='external' target='_blank'>prh.fi-sivuilta</a>",
        ],
        inid: {
          registrationNumber: "(11) Rekisterinumero",
          registrationDate: "(15) Rekisteröintipäivä",
          expiryDate: "(18) Uudistamisen jälkeen voimassa",
          applicationNumber: "(21) Hakemusnumero",
          applicationDate: "(22) Hakemispäivä",
          sampleProvided: "(29) Mallikappale annettu",
          priorities: "(30) Etuoikeus",
          publicationDate: "(45) Kuulutuspäivä",
          publicationDateWithoutInid: "Kuulutuspäivä",
          classification: "(51) Luokitus",
          productTitle: "(54) Tuotenimike",
          images: "(57) Kuvat",
          designers: "(72) Mallin luoja ",
          applicants: "(73) Haltija",
          newHolder: "(73) Uusi haltija",
          representatives: "(74) Asiamies",
          relatedApplication: "(66) Muunnosrekisteröinti",
          licence: "(79) Käyttöluvan saaja",
          oppositionPeriodStart: "Väiteaika alkaa",
          oppositionPeriodEnd: "Väiteaika päättyy",
          transferDate: "Mallioikeus siirtynyt",
          designReferenceTitle: "Katso",
          designReferenceLink: "Katso lisätietoja mallioikeustietopalvelusta",
          designReferenceLinkWipo: "Katso lisätietoja WIPOn tietopalvelusta",
        },
      },
      oik: {
        title: "Oikaisut",
        lines: [""],
      },
      tied: {
        title: "Tiedoksiannot",
        lines: [""],
      },
      rekmm: {
        title: "Rekisteröidyn mallin muutos",
        lines: [""],
      },
      rekuu: {
        title: "Rekisteröinnin uudistamiset",
        lines: [""],
      },
      uudjatpe: {
        title: "Uudistamatta jättämisen perusteella",
        lines: [""],
      },
      halpyy: {
        title: "Haltijan pyynnöstä",
        lines: [""],
      },
      suoalsu: {
        title: "Mallirekisteröintejä koskevat suoja-alojen supistukset",
        lines: [""],
      },
      siirto: {
        title: "Mallioikeuden siirrot",
        lines: [""],
      },
      katlup: {
        title: "Käyttölupa",
        lines: [""],
      },
      kvrekeisu: {
        title:
          "Kansainväliset rekisteröinnit, jotka eivät enää voimassa Suomessa",
        lines: [""],
      },
      uudkvrek: {
        title: "Uudistetut kansainväliset rekisteröinnit",
        lines: [""],
      },
      poisrek: {
        title: "Poistetut rekisteröinnit",
        lines: [""],
      },
    },
  },
};
export default PageFi;
