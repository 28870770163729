const Page = {
  header: "Utility Model Gazette",
  description: "",
  title: "Utility Model Gazette - Industrial Property Gazettes | PRH",
  common: {
    week: "Week",
  },
  message_box: {
    info_title: "The search criteria you entered gave no results.",
    danger_title: "The search failed. Please enter your search criteria.",
  },
  search: {
    date_warning: "Check the date",
    lines: [
      "The Utility Model Gazette includes a summary of the public notices and notifications of national utility models.",
      "We update the details every day.",
      "To search documents from a specific week, select the year of publication and the number of the gazette. You can also select the period freely.",
      "The Utility Model Gazette up to number 2/2023 are only available at the Finnish Patent and Registration Office in. Contact our customer service for more information and for copies of these gazettes.",
    ],
    gazette_number: {
      label: "Select the year of publication and the number of the gazette",
      year: "Select the year",
      number: "Select the number",
    },
    date_range: {
      label: "Or search details by date",
      begin: "From",
      end: "To",
      search: "Search",
      description: "Enter date in format dd.mm.yyyy",
    },
    // archive: "Archive (gazettes up to 1 February 2023)",
    archive_url: "https://patent.prh.fi/lehdet/default.asp",
    defaultOption: "-Select-",
    button_search: "Search",
    button_clear: "Clear",
  },
  index: {
    rangeTitle: "Public notices",
    issueTitle: "Public notices ",
    types: {
      umnotification: "Notifications",
      umannulpartial: "Partially invalidated utility models",
      um: "Registered utility models",
      umannul: "Invalidated utility models",
      umrenewed: "Renewed utility model registrations",
      umreinstated:
        "Restorations of rights (Act on Utility Model Rights, section 26a)",
      umtransferredbycourt:
        "Utility models transferred by the final decision of a court",
      umtransferred: "Transferred utility models",
      umeexpired: "Expired utility models",

      umrevoked: "Revoked utility models",
      umavailabletopublic: "Utility models available to the public",
      umcorrectedpublications: "Corrected publications",
    },
    st: "st",
    link_alt: "Jump to a section on this page",
  },
  details: {
    common: {
      yes: "Yes",
      no: "No",
      image: {
        alt: "Avaa kuva suuremmaksi",
      },
      number: "no.: ",
      jump_links: {
        index_title: "Go back to public notices",
        page_title: "Go back to the top of the page",
      },
      close: "Close",
      imageModal: {
        label: "Suuremman hyödyllisyysmallikuvan esikatselu",
      },
    },
    //Taulukon käännökset
    table: {
      head: "Application data",
      rows: {
        classification: "International patent class",
        documentNumber: "Document number (registration number)",
        SPCdocumentNumber: "Application number",
        grantDate: "Registration date",
        decisionDate: "Decision date",
        transferDate: "Transfer date",
        noveltyResearch: "Utility model search completed",
        removalDate: "Removal date",
        applicationNumber: "Application number",
        registrationNumber: "Registration number",
        publicationDate: "Public notification date",
        applicationDate: "Application date",
        filingDate: "Filing date of the application",
        applicants: "Holder(s)",
        inventors: "Inventor(s)",
        representatives: "Agent",
        title: "Title of invention",
        SPCtitle: "Product",
        check: "View",
      },
      patinfo: "Patent Information Service", //tietopalvelu linkin teksti
    },
    types: {
      umnotification: {
        title: "Notifications",
        lines: [
          "If the notification concerns a decision that you are dissatisfied with, you may appeal it at the Finnish Market Court. Lodge your appeal in writing within 60 days of receiving the notification. The decision and the appeal instructions are available at the Finnish Patent and Registration Office",
        ],
      },
      umannulpartial: {
        title: "Partially invalidated utility models",
        lines: [""],
      },
      um: {
        title: "Registered utility models",
        lines: [""],
      },
      umannul: {
        title: "Invalidated utility models",
        lines: [""],
      },
      umrenewed: {
        title: "Renewed utility model registrations",
        lines: [""],
      },
      umreinstated: {
        title:
          "Restorations of rights (Act on Utility Model Rights, section 26a)",
        lines: [""],
      },
      umtransferredbycourt: {
        title: "Utility models transferred by the final decision of a court",
        lines: [""],
      },
      umtransferred: {
        title: "Transferred utility models",
        lines: [""],
      },
      umeexpired: {
        title: "Expired utility models",
        lines: [""],
      },

      umrevoked: {
        title: "Revoked utility models",
        lines: [""],
      },
      umavailabletopublic: {
        title: "Utility models available to the public",
        lines: [""],
      },
      umcorrectedpublications: {
        title: "Corrected publications",
        lines: [""],
      },
    },
  },
};

export default Page;
