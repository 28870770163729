import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { I18nContext } from "../i18n";
import { Container } from "react-bootstrap";
import "../scss/styles/app.scss";
import UseWindowSize from "../customHooks/useWindowSize";
import ArchiveFi from "../i18n/Archive/fi";
import ArchiveEn from "../i18n/Archive/en";
import ArchiveSv from "../i18n/Archive/sv";
import Layout from "../components/common/Layout";
import usePatentEndpoint from "../customHooks/usePatentEndpoint";
import { bootStrapGrid } from "../constants/constants";
import CardLinks from "../components/common/CardLinks";

const Archive = (props) => {
  const { dispatch } = useContext(I18nContext);
  const size = UseWindowSize();
  const breadCrumbs = ["mainpage", props.type + "gazette", "archive"];

  const { type } = props;
  const { search, postArchive } = usePatentEndpoint();
  const [archives, setArchives] = useState({});

  useEffect(() => {
    postArchive({ type });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (search?.data?.gazetteGroups?.length > 0 && search.data.gazetteGroups[0].gazettes != null) {
      const tmp = search.data.gazetteGroups[0].gazettes.reduce(
        (p, c, i) => ({
          ...p,
          [c.year]: p[c.year]?.length > 0 ? [...p[c.year], c] : [c],
        }),
        {}
      );
      setArchives(tmp);
    }
  }, [search]);

  useEffect(() => {}, [archives]);

  const contentLangs = { fi: ArchiveFi, en: ArchiveEn, sv: ArchiveSv };
  const content = contentLangs[props.match.params.lang];

  document.title = content[type].title;

  useEffect(() => {
    if (
      props.match.params.lang !== "fi" &&
      document.documentElement.lang !== props.match.params.lang
    ) {
      document.documentElement.lang = props.match.params.lang;
      dispatch({ type: "setLanguage", payload: props.match.params.lang });
    }
    props.setActiveLink("Home");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dispatch, props]);

  return (
    <Layout 
      breadCrumbs={breadCrumbs} 
      type={type}
      closeSurvey={props.closeSurvey}
      showSurvey={props.showSurvey}
      >
      <main id="maincontent" tabIndex="-1">
        <div className="jumbotron jumbotron-flow-under">
          <Container>
            <h1>{content[type].header}</h1>
          </Container>
        </div>
        <Container>
          <section id="tosection" className="prh-section-borders">
            <h2>{content[type].section.title}</h2>
            {content[type].section.lines.map((line, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: line }} />
            ))}
            <div className="d-flex flex-column">
              {archives &&
                Object.keys(archives)
                  .reverse()
                  .map((e,i) => (
                    <a
                      key={i}
                      href={
                        process.env.PUBLIC_URL +
                        props.location.pathname +
                        `#${e}`
                      }
                    >
                      {e}
                    </a>
                  ))}
            </div>
          </section>
          {archives &&
            Object.keys(archives)
              .reverse()
              .map((year,i) => (
                <section key={i} id={year} className="prh-section-borders">
                  <h3>{year}</h3>
                  <div
                    style={{
                      maxHeight:
                        size.width > bootStrapGrid.sm ? "200px" : "100%",
                    }}
                    className={`d-flex flex-column flex-wrap mb-3`}
                  >
                    {archives[year].reverse().map((e,i) => (
                      <div
                        key={i}
                        style={{
                          width: `${
                            size.width > bootStrapGrid.sm ? "33%" : "100%"
                          }`,
                        }}
                      >
                        <a
                          target="_blank"
                          href={e.url}
                          rel="noreferrer"
                        >{`${e.issue}-${e.year}`}</a>
                      </div>
                    ))}
                  </div>
                  <CardLinks content={content} />
                </section>
              ))}
        </Container>
      </main>
    </Layout>
  );
};

export default withRouter(Archive);
