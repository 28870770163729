import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import Footer from "./Footer";
import MobileNavbar from "../header/MobileNavbar";
import ExpandedNavbar from "../header/ExpandedNavbar";
import { bootStrapGrid } from "../../constants/constants";
import CommonFi from "../../i18n/Common/fi";
import CommonEn from "../../i18n/Common/en";
import CommonSv from "../../i18n/Common/sv";
// import SurveyModal from "../yearlySurvey/SurveyModal";

import { withRouter } from "react-router-dom";

import UseWindowSize from "../../customHooks/useWindowSize";

import { I18nContext } from "../../i18n";

const Layout = (props) => {
  const { langCode } = useContext(I18nContext);
  const contentLangs = { fi: CommonFi, en: CommonEn, sv: CommonSv };
  const content = contentLangs[langCode];
  const size = UseWindowSize();

  return (
    <div>
      <div id="skip">
        <a
          href={
            process.env.PUBLIC_URL + props.location.pathname + "#maincontent"
          }
        >
          {content.jumplink}
        </a>
      </div>
      <Container
        fluid="true"
        className=" page-wrapper m-0 p-0 "
        style={{
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          {size.width < bootStrapGrid.sm ? (
            <MobileNavbar activeLink={props.activeLink} />
          ) : (
            <ExpandedNavbar
              activeLink={props.activeLink}
              breadCrumbs={props.breadCrumbs}
            />
          )}

          {props.children}
        </div>
        <Footer type={props.type} />
      </Container>
    </div>
  );
};

export default withRouter(Layout);
