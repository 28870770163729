import React from "react";
import { Table as Tablerb } from "react-bootstrap";

const Table = (props) => {

  return (
    <div
      className={(props.class ? props.class : "") + " pt-5 table-responsive"}
    >
      <Tablerb className="table-stacked-md" id={props.id} role="table">
        <thead>
          <tr>
            {props.head.map((e, i) => (
              <th colSpan={props.headCols[i] || 1} style={{ width: "50%" }}>
                {e}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.rows.map(
            (e) =>
              e && (
                <tr role="row">
                  <td className="align-middle" role="cell">
                    <div>{e[0]}</div>
                  </td>
                  <td role="cell">
                    {e.length > 1 &&
                      e[1] &&
                      e[1].map((i) =>
                        Array.isArray(i) ? (
                          i.map((a) => <div>{a}</div>)
                        ) : (
                          <div>{i}</div>
                        )
                      )}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </Tablerb>
    </div>
  );
};

export default Table;
