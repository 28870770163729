import { useState } from "react";
import axios from "axios";
export default function useEndpoint(fn) {
  const [res, setRes] = useState({
    data: [],
    loading: false,
    error: false,
  });

  return [
    res,
    (...args) => {
      return new Promise((resolve) => {
        setRes({
          data: [],
          loading: true,
          error: false,
        });
        axios(fn(...args))
          .then((res) => {
            if (res.data.length !== undefined && res.data.length === 0) {
              const er = {
                errorTitle: "no_searchresults_title",
                errorContent: "no_searchresults_content",
                status: res ? res.status : "",
                path: "/search",
              };
              setRes({
                data: [],
                loading: false,
                error: er,
              });
              resolve(0); // Palautetaan 0 kun ei hakutuloksia
            } else {
              setRes({
                data: res.data,
                loading: false,
                error: false,
              });

              resolve(1); // Palautetaan 1 kun hakutuloksia löytyy
            }
          })
          .catch((err) => {
            setRes({
              data: [],
              loading: false,
              error: err,
            });
            resolve(2); // Palautetaan 2 kun virhe
          });
      });
    },
  ];
}
