const ArrayOfYears = (start) => {
  const current = new Date().getFullYear();
  const array = [];
  for (let i = current; i >= start; i--) {
    array.push(i);
  }
  return array;
};

export default ArrayOfYears;
