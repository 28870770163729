const Page = {
  header: "Tidningar för industriellt rättsskydd",
  title: "Tidningar för industriellt rättsskydd | PRS",
  description:
    "Uppgifter om kungörelser och publikationer om industriellt rättsskydd som Patent- och registerstyrelsen (PRS) handlagt. Vi uppdaterar uppgifterna varje dag.",
  content: {
    title: null,
    lines: [],
    sections: [
      {
        hidden: false,
        border: true,
        title: {
          url: "/patentgazette",
          text: "Patenttidning",
        },
        lines: [
          `Tidningen innehåller ett sammandrag av följande kungörelser och delgivningar:
          <ul>
          <li>nationella patent</li>
          <li>europeiska patent validerade i Finland</li>
          <li>tilläggsskydd (SPC).</li>


          </ul>`,
        ],
      },
      {
        hidden: false,
        border: true,
        title: {
          url: "/utilitymodelgazette",
          text: "Nyttighetsmodelltidning",
        },
        lines: [
          `Tidningen innehåller ett sammandrag av kungörelser och delgivningar som gäller nyttighetsmodeller.`,
        ],
      },
      {
        hidden: false,
        border: true,
        title: {
          url: "/trademarkgazette",
          text: "Varumärkestidning",
        },
        archive: {
          description:
            "I arkivet hittar du publikationer som gjorts före 16 augusti 2022. ",
          link_label: "Gå till arkivet",
        },
        lines: [
          `Tidningen innehåller ett sammandrag av följande publikationer och delgivningar:
<ul>
<li>nationella varumärken</li>
<li>internationella varumärkesregistreringar som gäller i Finland </li>
<li>väl kända varumärken. </li>
</ul>`,
        ],
      },
      {
        hidden: false,
        border: true,
        title: {
          url: "/designgazette",
          text: "Mönsterrättstidning",
        },
        archive: {
          description:
            "I arkivet hittar du kungörelser som utfärdats före 16 augusti 2022. ",
          link_label: "Gå till arkivet",
        },
        lines: [
          `Tidningen innehåller ett sammandrag av följande kungörelser och delgivningar:

      <ul>
<li>nationell mönsterrätt</li>
<li>internationella mönsterrättsregistreringar som gäller i Finland.</li>
</ul>`,
        ],
      },
    ],
  },
};

export default Page;
