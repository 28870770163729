import React, { useContext } from "react";
import Icons from "../../scss/prh-icon-sprite.svg";
import { Link, withRouter } from "react-router-dom";
import { I18nContext } from "../../i18n";

import CommonFi from "../../i18n/Common/fi";
import CommonEn from "../../i18n/Common/en";
import CommonSv from "../../i18n/Common/sv";

const BreadCrumb = (props) => {
  const { langCode } = useContext(I18nContext);
  const contentLangs = { fi: CommonFi, en: CommonEn, sv: CommonSv };
  const content = contentLangs[langCode];

  return (
    <nav className="navbar nabvar-light" aria-label={content.breadCrumb}>
      <ol className="breadcrumb">
        {props.breadCrumbs.map((breadCrumb, index) => (
          <li
            className={
              index === props.breadCrumbs.length
                ? "breadcrumb-item active"
                : "breadcrumb-item"
            }
            key={"breadcrumb_" + index}
          >
            {index === 0 ? (
              <svg
                aria-hidden="true"
                className="prh-icon prh-icon--xs p-0"
                style={{ marginTop: "0.1rem" }}
              >
                <use xlinkHref={`${Icons}#home`}></use>
              </svg>
            ) : (
              ""
            )}
            {index === props.breadCrumbs.length - 1 ? (
              content.navigation[breadCrumb]
            ) : (
              <Link
                to={breadCrumb === "mainpage"
                ? "/" + props.match.params.lang
                : "/" + props.match.params.lang + "/" + breadCrumb}
                
              >
                {content.navigation[breadCrumb]}
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default withRouter(BreadCrumb);
