const Page = {
  jumplink: "Jump to main content",
  title: "Industrial Property Gazettes",
  subtitle: "Finnish Patent and Registration Office",
  navigation: {
    home: "Home",
    navbar: [
      { name: "Patent Gazette", link: "/patentgazette", hidden: false },
      {
        name: "Utility Model Gazette",
        link: "/utilitymodelgazette",
        hidden: false,
      },
      { name: "Trademark Gazette", link: "/trademarkgazette", hidden: false },
      { name: "Design Gazette", link: "/designgazette", hidden: false },
    ],
    mainpage: "Home",
    patentgazette: "Patent Gazette",
    umgazette: "Utility Model Gazette",
    trademarkgazette: "Trademark Gazette",
    designgazette: "Design Gazette",
    archive: "Archive",
    hide: "Hide navigation",
    show: "Navigation",
  },
  footer: {
    title: "Finnish Patent and Registration Office",
    address: {
      streetTitle: "Street address",
      streetaddress: "Sörnäisten rantatie 13 C, Helsinki",
      postaddressTitle: "Mailing address",
      postaddress: " FI-00091 PRH, FINLAND",
    },
    customerService: {
      title: "Client Service",
      openHours: "Mon-Fri klo 9.00 - 15.00",
      telephone: "Tel. +358 29 509 5050",
    },

    trademark: {
      issn: "ISSN 1795-4932 (Web gazette)",
      author: "Editor-in-Chief: Jorma Lehtonen",
      privacyPolicyLink:
        "https://www.prh.fi/en/presentation_and_duties/processing_personaldata/how_we_process/privacystatement_trademarkregister.html",
      privacyPolicyLink2:
        "https://www.prh.fi/en/presentation_and_duties/processing_personaldata/how_we_process/privacystatement_listoftrademarks_reputation.html",
      privacyPolicyAlt2: "Show accessibility statement (TM with a reputation)",
      privacyPolicyTitle2: "Privacy statement (TM with a reputation)",
    },
    um: {
      issn: "ISSN 1796-7775 (Web gazette)",
      author: "Editor-in-Chief: Jorma Lehtonen",
      privacyPolicyLink:
        "https://www.prh.fi/en/presentation_and_duties/processing_personaldata/how_we_process/privacystatement_utilitymodelregister.html",
    },
    patent: {
      issn: "ISSN 1796-7775 (Web gazette)",
      author: "Editor-in-Chief: Jorma Lehtonen",
      privacyPolicyLink:
        "https://www.prh.fi/en/presentation_and_duties/processing_personaldata/how_we_process/privacystatement_patentregister.html",
    },
    design: {
      issn: "ISSN 1795-4940 (Web gazette)",
      author: "Editor-in-Chief: Jorma Lehtonen",
      privacyPolicyLink:
        "https://www.prh.fi/en/presentation_and_duties/processing_personaldata/how_we_process/privacystatement_designsregister.html",
    },
    infoTitle: "Information",
    privacyPolicyTitle: "Privacy statement",
    privacyPolicyLink: "#",
    privacyPolicyAlt: "Show privacy statement",
    privacyPolicy: [
      {
        gazette: "patent",
        title: "Privacy statement (Patent)",
        link: "https://www.prh.fi/en/presentation_and_duties/processing_personaldata/how_we_process/privacystatement_patentregister.html",
        show: true,
      },
      {
        gazette: "um",
        title: "Privacy statement (Utility Model)",
        link: "https://www.prh.fi/en/presentation_and_duties/processing_personaldata/how_we_process/privacystatement_utilitymodelregister.html",
        show: true,
      },
      {
        gazette: "trademark",
        title: "Privacy statement (Trademark)",
        link: "https://www.prh.fi/en/presentation_and_duties/processing_personaldata/how_we_process/privacystatement_trademarkregister.html",
        show: true,
      },
      {
        gazette: "ltm",
        title: "Privacy statement (TM with a reputation)",
        link: "https://www.prh.fi/en/presentation_and_duties/processing_personaldata/how_we_process/privacystatement_listoftrademarks_reputation.html",
        show: true,
      },
      {
        gazette: "design",
        title: "Privacy statement (Designs)",
        link: "https://www.prh.fi/en/presentation_and_duties/processing_personaldata/how_we_process/privacystatement_designsregister.html",
        show: true,
      },
    ],
    accessibilityLeafletTitle: "Accessibility statement",
    accessibilityLeafletLink:
      "https://www.prh.fi/accessibility/industrial_property_gazettes",
    accessibilityLeafletAlt: "Show accessibility statement",
  },
  datepicker: {
    placeholder: "dd.mm.yyyy",
  },
  pagination: {
    title: "Paging",
    toPage: "Go to page",
    toFirstPage: "Go to first page",
    toPreviousPage: "Go to previous page",
    toNextPage: "Go to next page",
    toLastPage: "Go to last page",
    pages: "Pages",
  },
  tooltip: "Guide text",
  breadcrumb: "Breadcrumbs",
  close: "Close",
  survey: {
    yearly_survey_question: "Please take our short survey to help us improve our services.",
    yearly_survey_info: "The survey takes only a few minutes to complete.",
    yearly_survey_open: "Go to the survey",
    yearly_survey_link: "https://link.webropolsurveys.com/S/2F29B674CB2003ED",
    yearly_survey_skip: "Close"
  }
};
export default Page;
