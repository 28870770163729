import React, { useContext } from "react";
import Table from "../../../common/Table";
import { baseUrl, formatDate } from "../../../../utils/helpers";
import { I18nContext } from "../../../../i18n/index";

const WellKnownTrademarks = (props) => {
  const gazette = props.gazettes;
  const details = props.details.types.ltm;
  const { langCode } = useContext(I18nContext);
  const langPosition = { fi: 0, sv: 1, en: 0 };
  return (
    <>
      <hr alt={props.details.common.alt_type.wellknowntrademark} />

      <Table
        id={`table-${gazette.applicationNumber}`}
        head={[details?.tableHeaders?.tableHead, ""]}
        headCols={[1]}
        rows={[
          gazette.publicationDate
            ? [
                details.tableHeaders.publicationDate,
                [formatDate(gazette.publicationDate)],
              ]
            : null,
          gazette.applicationNumber
            ? [
                details.tableHeaders.applicationNumber,
                [gazette.applicationNumber],
              ]
            : null,
          gazette.registrationDate
            ? [
                details.tableHeaders.registrationDate,
                [formatDate(gazette.registrationDate)],
              ]
            : null,
          gazette.expiryDate
            ? [
                details.tableHeaders.expirationDate,
                [formatDate(gazette.expiryDate)],
              ]
            : null,
          gazette.applicationDate
            ? [
                details.tableHeaders.applicationDate,
                [formatDate(gazette.applicationDate)],
              ]
            : null,
          gazette.markWord && gazette.markType !== "Combined"
            ? [details.tableHeaders.trademark, [gazette.markWord]]
            : null,
          gazette.markImage
            ? [
                details.tableHeaders.trademark,
                [
                  <img
                    width="100%"
                    src={baseUrl + gazette.markImage}
                    alt={gazette.markWord ? gazette.markWord : ""}
                  />,
                ],
              ]
            : null,
          gazette.descriptions?.length > 0
            ? [
                details.tableHeaders.markDescription,
                [
                  gazette.descriptions.filter((e) => e.language === langCode)
                    .length === 0
                    ? gazette.descriptions.map((e) => e.text).join(", ")
                    : gazette.descriptions
                        .filter((e) => e.language === langCode)
                        .map((e) => e.text)
                        .join(", "),
                ],
              ]
            : null,
          gazette.colourDescriptions?.length > 0
            ? [
                details.tableHeaders.colourDescriptions,
                [
                  gazette.colourDescriptions.filter(
                    (e) => e.language === langCode
                  ).length === 0
                    ? gazette.colourDescriptions.map((e) => e.text).join(", ")
                    : gazette.colourDescriptions
                        .filter((e) => e.language === langCode)
                        .map((e) => e.text)
                        .join(", "),
                ],
              ]
            : null,
          gazette.applicants?.length > 0
            ? [
                details.tableHeaders.applicant,
                [
                  gazette.applicants.map(
                    (e) =>
                      `${
                        e.firstName
                          ? `${e.firstName} ${e.lastName}`
                          : e.companyName
                      }${
                        e.domicile?.firstLanguage
                          ? ", " + e.domicile?.firstLanguage
                          : ""
                      }${
                        e.domicile?.secondLanguage
                          ? ", " + e.domicile?.secondLanguage
                          : ""
                      }, ${
                        Array.isArray(e.address.cityName)
                          ? e.address.cityName[langPosition[langCode]].text
                          : e.address.cityName.text
                          ? e.address.cityName.text
                          : e.address.cityName
                      }${e.address.country && ", " + e.address.country}`
                  ),
                ],
              ]
            : null,
          gazette.representatives?.length > 0
            ? [
                details.tableHeaders.representative,
                [
                  gazette.representatives.map(
                    (e) =>
                      `${
                        e.firstName
                          ? `${e.firstName} ${e.lastName}`
                          : e.companyName
                      }, ${e.address.cityName[langPosition[langCode]].text}${
                        e.address.country && ", " + e.address.country
                      }`
                  ),
                ],
              ]
            : null,
          gazette.useLimitationText
            ? [
                details.tableHeaders.targetGroup,
                [
                  gazette.useLimitationText === "Ei rajattu"
                    ? details.tableHeaders.targetGroupNo
                    : gazette.useLimitationText,
                ],
              ]
            : null,
          gazette.classifications.length > 0
            ? [
                details.tableHeaders.goodsAndServices,
                [
                  Object.entries(
                    gazette.classifications.reduce((p, c) => {
                      p[c.classNumber] = p[c.classNumber] || [];
                      return {
                        ...p,
                        [c.classNumber]: [...p[c.classNumber], c.term],
                      };
                    }, {})
                  ).map((e) => {
                    return `${details.tableHeaders.class} ${e[0]}: ${e[1].join(
                      ", "
                    )}`;
                  }),
                ],
              ]
            : null,
        ]}
      />
    </>
  );
};

export default WellKnownTrademarks;
