import React from "react";
import { withRouter } from "react-router-dom";
import "../../../scss/styles/app.scss";
import PublishedRegistrations from "./publications/RegistrationsTM";
import Corrections from "./publications/Corrections";
import Declarations from "./publications/Declarations";
import WellKnownTrademarks from "./publications/WellKnownTrademarks";

const TmDetails = (props) => {
  const details = props.content.details;
  return (
    <>
      {details.types[props.type]?.lines.map((line,index) => (
        <p key={index} dangerouslySetInnerHTML={{ __html: line }} />
      ))}
      {props.gazettes.map((e, i) => {
        switch (props.type.toUpperCase()) {
          case "JULK":
            return <PublishedRegistrations key={i} details={details} gazettes={e} />;
          case "OIK":
            return (
              <Corrections
                key={i}
                gazetteType={props.gazetteType}
                details={details}
                gazettes={e}
              />
            );
          case "TIED":
            return <Declarations key={i} details={details} gazettes={e} />;
          case "LTM":
            return <WellKnownTrademarks key={i} details={details} gazettes={e} />;
          default:
            return <PublishedRegistrations key={i} details={details} gazettes={e} />;
        }
      })}
    </>
  );
};

export default withRouter(TmDetails);
