const Page = {
  header: "Patenttidning",
  description: "",
  title: "Patenttidning - Tidningar för industriellt rättsskydd | PRH",
  common: {
    week: "Vecka",
  },
  message_box: {
    info_title: "Inga resultat hittades med dina sökvillkor.",
    danger_title: "Sökningen misslyckades. Ange sökvillkor.",
  },
  search: {
    date_warning: "Kontrollera datum",
    lines: [
      `Tidningen innehåller ett sammandrag av följande kungörelser och delgivningar:
      <ul>
<li>nationell patent</li>
<li>europeiska patent validerade i Finland</li>
<li>tilläggsskydd (SPC)</li>
</ul>`,
      "Vi uppdaterar uppgifterna varje dag.",
      "Du kan söka publikationer för en hel vecka genom att välja utgivningsår och tidningsnummer. Du kan också välja perioden fritt.",
      "Patenttidningar till nummer 2/2023 tillhandahålls endast av Patent- och registerstyrelsen. Kontakta vår kundtjänst för att få mer information och kopior av tidningarna.",
    ],
    gazette_number: {
      label: "Välj utgivningsår och tidningsnummer",
      year: "Välj år",
      number: "Välj nummer",
    },
    date_range: {
      label: "Eller sök uppgifter efter datum",
      begin: "Från och med",
      end: "Till och med",
      search: "Sök",
      description: "Skriv datumet enligt formatet dd.mm.åååå",
    },
    // archive: "Arkiv (Tidningar publicerade före xxx)",
    archive_url: "https://patent.prh.fi/lehdet/default.asp",
    defaultOption: "-Välj-",
    button_search: "Sök",
    button_clear: "Rensa",
  },
  index: {
    rangeTitle: "Kungörelser",
    issueTitle: "Kungörelser",
    types: {
      epkaan:
        "Europeiska patentansökningar i vilka en översättning av patentkraven ingivits (patentlagen 70 n §)",
      epkaanko:
        "Rättelser av översättningar av europeiska patent (patentlagen 70 q §) ",
      julkpat: "Patentansökningar som har blivit offentliga",
      jalvoep: "Återupprättade patent (patentlagen 71 a §)",
      spcjatk: "Beviljade förlängningar av giltighetstiden för tilläggsskydd",
      spc: "Ansökningar om tilläggsskydd",
      myonspc: "Beviljade tilläggsskydd",
      pat: "Patent meddelade med stöd av 20 § i patentlagen (243/97)",
      kaanep: "Översättningar av europeiska patent (patentlagen 70 h §)",
      kaanmmep:
        "Översättningar av europeiska patent som upprätthållits i ändrad avfattning (patentlagen 70 h §)",
      rajoepkaan:
        "Översättningar av begränsade europeiska patent (patentlagen 70 t §)",
      tiedan: "Delgivningar",
      jatsihyl:
        "Avskrivna eller avslagna patentansökningar som har blivit offentliga",
      rajoit: "Begränsade patent",
      lakan: "Upphörda patent",
      siirto: "Överlåtelser",
      rauen: "Förfallna patent",
      vaitpaat: "Beslut fattade på grund av invändningar ",
      vtptkumot:
        "Patent upphävda eller invändning avslagna (invändning beslut)",
      oikpal: "Återupprättade patent (patentlagen 71 a §)",
      expert: "Personer som skall upptagas i förteckningen över sakkunniga",
      julkpatdis: "Avskrivna ansökningar som blivit offentliga",
      julkpatref: "Avslagna ansökningar som blivit offentliga",
      julkpatwith: "Återtagna ansökningar som blivit offentliga",
      patclaimscorrected: "Korrigerade översättningar av patentkrav",
      patannul: "Patent som ogiltigförklarats genom en lagakraftvunnen dom",
      patexpired: "Upphörda patent",
      patlimited: "Begränsade patent",
      pattransferred: "Överlåtelser",
      patoppositionamended: "Patentet hålls i kraft i ändrad form",
      patspcwithdrawn: "Återtagna ansökningar om tilläggsskydd",
      patspcfiled: "Ansökningar om tilläggsskydd",
      patspcrefused: "Avslagna ansökningar om tilläggsskydd",
      patspcdismissed: "Avskrivna ansökningar om tilläggsskydd",
      patspccontinuationwithdrawn:
        "Återtagna förlängningar av giltighetstiden för tilläggsskydd",
      patspccontinuationgranted:
        "Beviljade förlängningar av giltighetstiden för tilläggsskydd",
      patspccontinuationfiled:
        "Ansökningar om förlängning av giltighetstiden för ett tilläggsskydd",
      patspccontinuationrefused:
        "Avslagna ansökningar om förlängning av giltighetstiden för tilläggsskydd",
      patspcgrantedcertificate: "Beviljade tilläggsskydd",
      patspcvaliditycorrected: "Rättelser av giltighetstider för tilläggsskydd",
      patspcannul: "Ogiltigförklarade tilläggsskydd",
      patspclapsed: "Förfallna tilläggsskydd",
      rajoepkaan5: "Korrigerade översättningar av europeiska patent",
      eprauen: "Upphörda europeiska patent",
      epkumot: "Upphävda europeiska patent",
      eptransc1: "Översättningar av patentkrav i europeiska patentansökningar",
      eptransc2: "Korrigerade översättningar av europeiska patent patentkrav",
      eptransc4: "Översättningar av i ändrad form beviljat europeiska Patent",
      eptransc3: "Validerade europeiska patent",
      eptransc7: "Översättning om europeiskt patent begränsat I Finland ",
      oppositionrefused: "Invändning avslagit - patent hålls i kraft",
      ptcorrectedpublications: "Korrigerade patentpublikationer",
      ptfilimitedt7: "I Finland begränsat europeiskt patent",
    },
    st: "st.",
    link_alt: "Hoppa till ett avsnitt på den här sidan",
  },
  details: {
    common: {
      yes: "Ja",
      no: "Ej",
      image: {
        alt: "Avaa kuva suuremmaksi",
      },
      number: "nro: ",
      jump_links: {
        index_title: "Gå tillbaka till kungörelser",
        page_title: "Gå tillbaka till början av sidan",
      },
      close: "Stäng",
      imageModal: {
        label: "Suuremman patenttikuvan esikatselu",
      },
    },
    table: {
      head: "Uppgifter om ansökan",
      rows: {
        classification: "Internationell patentklass",
        documentNumber: "Handlingsnummer",
        SPCdocumentNumber: "Ansökningsnummer",
        applicationNumber: "Ansökningsnummer",
        registrationNumber: "Registreringsnummer",
        documentPublicationDate: "Offentlighetsdatum",
        publicationDate: "Kungörelsedatum",
        applicationDate: "Ankomstdag",
        filingDate: "Ansökans ingivningsdag",
        grantDate: "Registreringsdatum",
        applicants: "Innehavare",
        inventors: "Uppfinnare",
        representatives: "Ombud",
        title: "Benämning",
        SPCtitle: "Produkt",
        check: "Se",
        epclassification: {
          date: "Datum av PCT-ansönings publicerings",
          number: "Ansökningsnummer av PCT-ansökning",
        },
      },
      patinfo: "Se mer information i Patentinformationstjänst",
    },
    types: {
      epkaan: {
        title:
          "Europeiska patentansökningar i vilka en översättning av patentkraven ingivits (patentlagen 70 n §)",
        lines: [""],
      },
      epkaanko: {
        title:
          "Rättelser av översättningar av europeiska patent (patentlagen 70 q §) ",
        lines: [""],
      },
      julkpat: {
        title: "Patentansökningar som har blivit offentliga",
        lines: [""],
      },
      julkpatref: {
        title: "Avslagna ansökningar som blivit offentliga",
        lines: [""],
      },
      jalvoep: {
        title: "Återupprättade europeiska patent (patentlagen 71 a §)",
        lines: [""],
      },
      spcjatk: {
        title: "Beviljade förlängningar av giltighetstiden för tilläggsskydd",
        lines: [""],
      },
      spc: {
        title: "Ansökningar om tilläggsskydd",
        lines: [""],
      },
      myonspc: {
        title: "Beviljade tilläggsskydd",
        lines: [""],
      },
      pat: {
        title: "Patent meddelade med stöd av 20 § i patentlagen (243/97)",
        lines: [
          "Följande patentansökningar har med stöd av 20 § i patentlagen (243/97) bifallits och patent har meddelats. En skriftlig invändning kan göras mot meddelade patent till Patent- och registerstyrelsen inom NIO (9) MÅNADER från den dag då patentet meddelades.",
        ],
      },
      kaanep: {
        title: "Översättningar av europeiska patent (patentlagen 70 h §)",
        lines: [""],
      },
      kaanmmep: {
        title:
          "Översättningar av europeiska patent som upprätthållits i ändrad avfattning (patentlagen 70 h §)",
        lines: [""],
      },
      rajoepkaan: {
        title:
          "Översättningar av begränsade europeiska patent (patentlagen 70 t §)",
        lines: [""],
      },
      tiedan: {
        title: "Delgivningar",
        lines: [
          "Om delgivningen gäller ett beslut som du är missnöjd med, kan du söka ändring i det hos marknadsdomstolen genom skriftliga besvär. Besvär ska anföras inom 60 dagar från delgivningens datum. Beslutet och besvärsanvisningen tillhandahålls av Patent- och registerstyrelsen.",
        ],
      },
      jatsihyl: {
        title:
          "Avskrivna eller avslagna patentansökningar som har blivit offentliga",
        lines: [""],
      },
      rajoit: {
        title: "Begränsade patent",
        lines: [""],
      },
      lakan: {
        title: "Upphörda patent",
        lines: [""],
      },
      siirto: {
        title: "Överlåtelser",
        lines: [""],
      },
      rauen: {
        title: "Förfallna patent",
        lines: [""],
      },
      vaitpaat: {
        title: "Beslut fattade på grund av invändningar",
        lines: [""],
      },
      vtptkumot: {
        title: "Patent upphävda eller invändning avslagna (invändning beslut)",

        lines: [""],
      },
      oikpal: {
        title: "Återupprättade patent (patentlagen 71 a §)",
        lines: [""],
      },

      expert: {
        title: "Personer som skall upptagas i förteckningen över sakkunniga",
        lines: [""],
      },

      julkpatdis: {
        title: "Avskrivna ansökningar som blivit offentliga",
        lines: [""],
      },

      julkpathref: {
        title: "Avslagna ansökningar som blivit offentliga",
        lines: [""],
      },

      julkpatwith: {
        title: "Återtagna ansökningar som blivit offentliga",
        lines: [""],
      },
      patclaimscorrected: {
        title: "Korrigerade översättningar av patentkrav",
        lines: [""],
      },
      patannul: {
        title: "Patent som ogiltigförklarats genom en lagakraftvunnen dom",
        lines: [""],
      },
      patexpired: {
        title: "Upphörda patent",
        lines: [""],
      },
      patlimited: {
        title: "Begränsade patent",
        lines: [""],
      },
      pattransferred: {
        title: "Överlåtelser",
        lines: [""],
      },
      patoppositionamended: {
        title: "Patentet hålls i kraft i ändrad form",
        lines: [""],
      },
      patspcwithdrawn: {
        title: "Återtagna ansökningar om tilläggsskydd",
        lines: [""],
      },
      patspcfiled: {
        title: "Ansökningar om tilläggsskydd",
        lines: [""],
      },
      patspcrefused: {
        title: "Avslagna ansökningar om tilläggsskydd",
        lines: [""],
      },
      patspcdismissed: {
        title: "Avskrivna ansökningar om tilläggsskydd",
        lines: [""],
      },
      patspccontinuationwithdrawn: {
        title: "Återtagna förlängningar av giltighetstiden för tilläggsskydd",
        lines: [""],
      },
      patspccontinuationgranted: {
        title: "Beviljade förlängningar av giltighetstiden för tilläggsskydd",
        lines: [""],
      },
      patspccontinuationfiled: {
        title:
          "Ansökningar om förlängning av giltighetstiden för tilläggsskydd",
        lines: [""],
      },
      patspccontinuationrefused: {
        title:
          "Avslagna ansökningar om förlängning av giltighetstiden för tilläggsskydd",
        lines: [""],
      },
      patspcgrantedcertificate: {
        title: "Beviljade tilläggsskydd",
        lines: [""],
      },
      patspcvaliditycorrected: {
        title: "Rättelser av giltighetstider för tilläggsskydd",
        lines: [""],
      },
      patspcannul: {
        title: "Ogiltigförklarade tilläggsskydd",
        lines: [""],
      },
      patspclapsed: {
        title: "Förfallna tilläggsskydd",
        lines: [""],
      },
      rajoepkaan5: {
        title: "Korrigerade översättningar av europeiska patent",
        lines: [""],
      },
      eprauen: {
        title: "Upphörda europeiska patent",
        lines: [""],
      },
      epkumot: {
        title: "Upphävda europeiska patent",
        lines: [""],
      },
      eptransc1: {
        title: "Översättningar av patentkrav i europeiska patentansökningar",
        lines: [""],
      },
      eptransc2: {
        title: "Korrigerade översättningar av europeiska patent patentkrav",
        lines: [""],
      },
      eptransc4: {
        title: "Översättningar av i ändrad form beviljat europeiska Patent",
        lines: [""],
      },
      eptransc3: {
        title: "Validerade europeiska patent",
        lines: [""],
      },
      eptransc7: {
        title: "Översättning om europeiskt patent begränsat I Finland ",
        lines: [""],
      },
      oppositionrefused: {
        title: "Invändning avslagit - patent hålls i kraft",
        lines: [""],
      },
      ptcorrectedpublications: {
        title: "Korrigerade patentpublikationer",
        lines: [""],
      },
      ptfilimitedt7: {
        title: "I Finland begränsat europeiskt patent",
        lines: [""],
      },
    },
  },
};

export default Page;
