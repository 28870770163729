import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import { Col, Row, Container } from "react-bootstrap";
import Icons from "../../scss/prh-icon-sprite.svg";
import UseWindowSize from "../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../constants/constants";
import CommonFi from "../../i18n/Common/fi";
import CommonEn from "../../i18n/Common/en";
import CommonSv from "../../i18n/Common/sv";

const Footer = (props) => {
  const { langCode } = useContext(I18nContext);
  const contentLangs = { fi: CommonFi, en: CommonEn, sv: CommonSv };
  const content = contentLangs[langCode];
  const size = UseWindowSize();
  const type = props.type || null;

  return (
    <footer className="prh-site-footer d-print-none">
      <Container>
        {size.width > bootStrapGrid.sm && (
          <Row className="align-items-start justify-content-between pt-4">
            <Row className="col-sm-auto align-items-start justify-content-start mb-3">
              <Col className="col-auto pr-2">
                <div className="prh-site-footer__logo"></div>
              </Col>
              <Col className="col-auto">
                <h2 style={{ fontSize: "1.21rem", color: "#ffffff" }}>
                  {content.footer.title}
                </h2>
                <p>
                  {content.footer.address.streetTitle}:
                  {content.footer.address.streetaddress}
                </p>
                <p>
                  {content.footer.address.postaddressTitle}:
                  {content.footer.address.postaddress}
                </p>
              </Col>
            </Row>
            <Col className="col-sm-auto mb-3">
              <h2 style={{ fontSize: "1.21rem", color: "#ffffff" }}>
                {content.footer.customerService.title}
              </h2>
              <p>{content.footer.customerService.openHours}</p>
              <p>{content.footer.customerService.telephone}</p>
            </Col>
            <Col className="col-sm-auto mb-3">
              <h2 style={{ fontSize: "1.21rem", color: "#ffffff" }}>
                {content.footer.infoTitle}
              </h2>

              {type && content.footer[type] && (
                <p>
                  <a href={content.footer[type].privacyPolicyLink}>
                    <span
                      className="menu-text ml-1"
                      style={{ fontSize: "1.1rem" }}
                    >
                      {content.footer.privacyPolicyTitle}
                    </span>
                    <svg
                      role="img"
                      alt={content.footer.privacyPolicyAlt}
                      aria-label={content.footer.privacyPolicyAlt}
                      className="prh-icon prh-icon--xs"
                      style={{ stroke: "#ffffff" }}
                    >
                      <use xlinkHref={`${Icons}#external-link`}></use>
                    </svg>
                  </a>
                </p>
              )}
              {type &&
                content.footer[type] &&
                content.footer[type].privacyPolicyTitle2 && (
                  <p>
                    <a href={content.footer[type].privacyPolicyLink2}>
                      <span
                        className="menu-text ml-1"
                        style={{ fontSize: "1.1rem" }}
                      >
                        {content.footer[type].privacyPolicyTitle2}
                      </span>
                      <svg
                        role="img"
                        alt={content.footer[type].privacyPolicyAlt2}
                        aria-label={content.footer[type].privacyPolicyAlt2}
                        className="prh-icon prh-icon--xs"
                        style={{ stroke: "#ffffff" }}
                      >
                        <use xlinkHref={`${Icons}#external-link`}></use>
                      </svg>
                    </a>
                  </p>
                )}
              {!type &&
                content.footer.privacyPolicy.map((item, index) =>
                  item.show ? (
                    <p key={index}>
                      <a href={item.link}>
                        <span
                          className="menu-text ml-1"
                          style={{ fontSize: "1.1rem" }}
                        >
                          {item.title}
                        </span>
                        <svg
                          role="img"
                          alt={content.footer.privacyPolicyAlt}
                          aria-label={content.footer.privacyPolicyAlt}
                          className="prh-icon prh-icon--xs"
                          style={{ stroke: "#ffffff" }}
                        >
                          <use xlinkHref={`${Icons}#external-link`}></use>
                        </svg>
                      </a>
                    </p>
                  ) : (
                    ""
                  )
                )}
              <p>
                <a href={content.footer.accessibilityLeafletLink}>
                  <span
                    className="menu-text ml-1"
                    style={{ fontSize: "1.1rem" }}
                  >
                    {content.footer.accessibilityLeafletTitle}
                  </span>
                  <svg
                    role="img"
                    alt={content.footer.accessibilityLeafletAlt}
                    aria-label={content.footer.accessibilityLeafletAlt}
                    className="prh-icon prh-icon--xs"
                    style={{ stroke: "#ffffff" }}
                  >
                    <use xlinkHref={`${Icons}#external-link`}></use>
                  </svg>
                </a>
              </p>
              {type && content.footer[type] && (
                <div className="pt-4">
                  <p>{content.footer[type].issn}</p>
                  <p>{content.footer[type].author}</p>
                </div>
              )}
            </Col>
          </Row>
        )}
        <hr />
        <Row className="align-items-center justify-content-between pb-3">
          <Row className="col-sm-auto align-items-center"></Row>
          <Col className="col-sm-auto align-items-center">
            <small>© {content.footer.title}</small>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
