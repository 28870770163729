const Page = {
  header: "Trademark Gazette",
  description: "",
  title: "Trademark Gazette - Industrial Property Gazettes | PRH",
  common: {
    week: "Week",
  },
  message_box: {
    info_title: "The search criteria you entered gave no results.",
    danger_title: "The search failed. Please enter your search criteria.",
  },
  search: {
    date_warning: "Check the date",
    lines: [
      `The Trademark Gazette includes a summary of the following documents and notifications
<ul>
<li>National trademarks</li>
<li>International trademark registrations valid in Finland </li>
<li>Trademarks with a reputation.</li>
</ul>`,
      "We update the details every day.",
      "To search documents from a specific week, select the year of publication and the number of the gazette. You can also select the period freely.",
    ],
    gazette_number: {
      label: "Select the year of publication and the number of the gazette",
      year: "Select year",
      number: "Select number",
    },
    date_range: {
      label: "Or search details by date",
      begin: "From",
      end: "To",
      search: "Search",
      description: "Enter date in format dd.mm.yyyy",
    },
    archive: "Archive (documents given before 16 August 2022)",
    defaultOption: "-Select-",
    button_search: "Search",
    button_clear: "Clear",
  },
  // archive_section: {
  //   title: "Archive",
  //   description:
  //     "In the archive, you will find the documents given before 16 August 2022.",
  //   link_label: "Go to issues 02/2016 – 15/2022",
  // },
  index: {
    rangeTitle: "Documents ",
    issueTitle: "Documents ",
    types: {
      julk: "Published registrations",
      oik: "Corrections",
      tied: "Notifications",
      ltm: "Trademarks admitted to the List of Trademarks with a Reputation",
    },
    st: "st",
    link_alt: "Jump to a section on this page",
  },
  details: {
    common: {
      yes: "Yes",
      no: "No",
      image: {
        alt: "Click on image to enlarge",
      },
      number: "no.: ",
      jump_links: {
        index_title: "Go back to documents",
        page_title: "Go back to the top of the page",
      },
      alt_type: {
        registration: "Published registration",
        correction: "Correction",
        notification: "Notification",
        wellknowntrademark: "Trademark with a reputation",
      },
      inid: {
        registrationNumber: "(111) Registration number",
        registrationDate: "(151) Registration date",
        applicationNumber: "(210) Application number",
        applicationDate: "(220) Application date",
        priorities: {
          applicationNumber: "(310) Priority: Application number",
          filingDate: "(320) Priority: Application date",
          countryCode: "(330) Priority: Country",
          partial: "(340) Partial priority",
        },
        markImage: "(540) Trademark",
        markSound: "(556) Sound Mark",
        markMultimedia: "(554) Three-dimensional mark",
        markTypeColour: "(558) Colour mark",
        description: "(571) Description of mark",
        disclaimers: "(526) Disclaimer",
        colourDescriptions: "(591) Description of colour",
        applicants: "(730) Holder",
        representatives: "(740) Representative",
        classifications: "(511) Class of goods and/or services ",
        publicationDate: "(450) Publication date",
        publicationDateWithoutInid: "Publication date",
        oppositionPeriodStartDate: "Opposition period begins",
        oppositionPeriodEndDate: "Opposition period ends",
        markKind: "(551) Collective or certification mark",
        markKindMark: {
          Collective: "Collective mark",
          Guarantee: "Certification mark",
        },
      },
      close: "Close",

      imageModal: {
        label: "Preview of larger trademark image",
      },
      videoModal: {
        label: "View video of the trademark",
      },
      playSound: {
        label: "Play sound of the trademark ",
      },
      trademarkImage: "Image of the trademark",
    },
    types: {
      julk: {
        check: "",
        trademarkDB: "Read more in the Trademark Information Service",
        title: "Published registrations",
        lines: [
          "In accordance with the Finnish Trademarks Act, the Finnish Patent and Registration Office has registered the following trademarks and will publish the following international registrations.",

          "If you want to oppose a trademark registration, submit your opposition in writing to the PRH within two months of the publication date of the registration. Read more on <a href='https://www.prh.fi/trademarks_opposition' class='external' target='_blank'>prh.fi</a> about filing an opposition.",
        ],
      },
      oik: {
        title: "Corrections",
        lines: [""],
      },
      tied: {
        title: "Notifications",
        lines: [""],
      },
      ltm: {
        title:
          "Trademarks admitted to the List of Trademarks with a Reputation",
        lines: [
          "The Finnish Patent and Registration Office will publish the following trademarks admitted to the List of Trademarks with a Reputation.",

          "It is not possible to lodge an opposition against a trademark admitted to the list. Anyone can request the removal of a trademark from the list. Read more on <a href='https://www.prh.fi/trademarks_with_a_reputation' target='_blank'>prh.fi</a> about the removal from the list.",
        ],
        tableHeaders: {
          class: "Class",
          tableHead: "Trademark details",
          applicationNumber: "Mark number",
          registrationDate: "Validation date",
          expirationDate: "Expiry date",
          applicationDate: "Application date",
          trademark: "Trademark",
          markDescription: "Description of mark",
          colourDescriptions: "Description of colour",
          applicant: "Applicant",
          representative: "Representative",
          targetGroup: "Target group",
          targetGroupNo: "Not limited",
          goodsAndServices: "Goods/services",
          publicationDate: "Publication Date",
        },
      },
    },
  },
};

export default Page;
