import React, { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import "../../scss/styles/app.scss";
import { formatDate } from "../../utils/helpers";
import MessageBox from "../common/MessageBox";

const TableOfContent = (props) => {
  const contentRef = useRef();
  useEffect(() => {
    contentRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [contentRef, props.gazettes]);
  return (
    <section id={props.id} className="prh-section-borders" ref={contentRef}>
      <h2>
        {props.rangeSearch
          ? props.content.index.rangeTitle +
            formatDate(props.startDate) +
            " - " +
            formatDate(props.endDate)
          : props.content.index.issueTitle +
            props.content.common.week.toLowerCase() +
            " " +
            props.issue +
            ", " +
            props.year}
      </h2>
      <div className="d-flex flex-column">
        {props.searchResults.length === 0 && !props.loading && (
          <MessageBox
            className="alert-info"
            title={props.content.message_box.info_title}
            danger={false}
          />
        )}
        {props.searchResults?.map((e,i) => (
          <a
            key={i}
            href={
              process.env.PUBLIC_URL +
              props.location.pathname +
              `#${e.gazetteType.toLowerCase()}`
            }
            alt={props.content.index.link_alt}
          >
            {props.content.index.types[e.gazetteType.toLowerCase()]} (
            {e.gazettes.length} {props.content.index.st})
          </a>
        ))}
      </div>
    </section>
  );
};

export default withRouter(TableOfContent);
