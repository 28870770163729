import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { I18nContext } from "../i18n";
import "../scss/styles/app.scss";
import PageFi from "../i18n/DesignGazette/fi";
import PageEn from "../i18n/DesignGazette/en";
import PageSv from "../i18n/DesignGazette/sv";
import GazettePage from "./GazettePage";

const DesignGazette = (props) => {
  const { dispatch } = useContext(I18nContext);

  const breadCrumbs = ["mainpage", "designgazette"];
  const contentLangs = { fi: PageFi, en: PageEn, sv: PageSv };
  const content = contentLangs[props.match.params.lang];

  useEffect(() => {
    if (
      props.match.params.lang !== "fi" &&
      document.documentElement.lang !== props.match.params.lang
    ) {
      document.documentElement.lang = props.match.params.lang;
      dispatch({ type: "setLanguage", payload: props.match.params.lang });
    }
    props.setActiveLink("Home");
  }, [ dispatch, props]);

  return (
    <GazettePage breadCrumbs={breadCrumbs} type="design" content={content}  closeSurvey={props.closeSurvey} showSurvey={props.showSurvey}/>
  );
};

export default withRouter(DesignGazette);
